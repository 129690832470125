import { useEffect, useState } from "react";
import { Box, Button, Container, Grid, makeStyles } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import React from "react";
import Carousel from "react-elastic-carousel";
import ContactUsForm from "./forms/ContactUsForm";
import WebApi from "api/ApiConstants";
import { BASE_URL_IMAGE_VIEW } from "api/ApiConstants";

const useStyles = makeStyles((theme) => ({
  root: {
    // padding: "3rem 0rem",
    position: "relative",
    "& .kXteup": {
      backgroundColor: theme.palette.secondary.main,
    },
    "& .rec.rec-arrow": {
      // "& .rec-arrow":{
      //   "& :hover":{
      //     backgroundColor:`${theme.palette.secondary.main} !important`,
      //   }
      // }
      "& :hover": {
        backgroundColor: theme.palette.secondary.main,
      }
    },
    "& .cVXxbE": {
      backgroundColor: theme.palette.secondary.main,
      //boxShadow:theme.palette.secondary.main ,
    },
    "& .rec-dot_active": {
      backgroundColor: theme.palette.secondary.main,
    },


    [theme.breakpoints.down(1200)]: {
      "& .rec-carousel": {
        position: "relative"
      },
      "& .rec-arrow": {
        position: "absolute",
        zIndex: 1,
        // backgroundColor:theme.palette.secondary.main,
        "&.rec-arrow-left": {
          left: 0
        },
        "&.rec-arrow-right": {
          right: 0
        }
      },

    },
    [theme.breakpoints.down(450)]: {
      "& .rec-arrow": {
        display: "none !important"
      },
    }
  },
  headSection: {
    // margin: "3rem 0rem",
    marginBottom: "4.3rem",
    marginTop: "2rem",
    textAlign: "center",
    "& h2": {
      color: theme.palette.primary.defaultText,
      fontWeight: 500,
      marginBottom: "1rem",
      textTransform: "none",
      [theme.breakpoints.up("md")]: {
        fontSize: "3.5rem",
      },
    },
    "& p": {
      color: theme.palette.primary.gainsboro,
    },
  },
  highText: {
    position: "relative",
    "&:before": {
      content: '""',
      display: "flex",
      width: 74,
      height: 16,
      position: "absolute",
      bottom: "-10px",
      left: "30%",
      //background: `url(${HIGHLIGHT_IMG}) no-repeat`,
    },
  },
  carouselItem: {
    margin: "0px 5px",
    width: 276,
    [theme.breakpoints.down(512)]: {
      width: "100%",
    },
    // height:'50vh',
    "& .carousel-content": {
      width: '100%',
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center"
    },
    "& .img-div": {
      // width:'100%',
      // height:"197px",
      "& img": {
        borderRadius: "0.5rem 0.5rem 0 0",
        marginBottom: 10,
        width: 300,
        height: "200px !important",
        // [theme.breakpoints.down("sm")]: {
        //   width: "100%"
        // },
      },
    },
    "& .query-action-wrap": {
      width: "100%"
    },
    "& p": {
      color: theme.palette.primary.defaultText,
      fontSize: 16,
      fontWeight: 500,
      margin: 5,
      height: "21px",
      textAlign: "left",
      width: "100%",
      [theme.breakpoints.down(512)]: {
        paddingLeft: "10px !important"
      }

    },
    "& .desc": {
      margin: "16px 0",
      textAlign: 'left',
      height: "43px",
      [theme.breakpoints.down(512)]: {
        margin: "0 0 16px",
        padding: "0 15px"
      }
    },
  },
  countryText: {
    verticalAlign: "super",
    margin: "0 5px",
    fontSize: "0.8rem",
    color: theme.palette.primary.doveGray,
  },
  viewButton: {
    color: theme.palette.primary.lightText,
    // minWidth: 75,
    // maxWidth: 150,
    width: "100%",
    minHeight: 50,
    borderRadius: "0.5rem",
    border: "none",
    transition: "all 0.2s",
    position: "relative",
    overflow: "hidden",
    zIndex: 1,
    background: theme.palette.primary.main,
    "&:hover": {
      background: theme.palette.primary.main,
    },
  },

  modal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "fixed",
    zIndex: 99999,
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    margin: "0 auto",
    "& .modalContainer": {
      // backgroundColor: "#dddddd",
      boxShadow: "0 0 0 50vmax rgba(0,0,0,.5)",
      padding: 10,
      paddingTop: 10,
      // scrollPadding: "30px 0 0 0",
      // marginTop: 50,

      width: "40%",
      height: "auto",
      borderRadius: 13,
      backgroundColor: "rgba(0, 0, 0, 0.9)",
      position: "relative",
      [theme.breakpoints.down(960)]: {
        width: "100%",
      }
    },
    "& .contentContainer": {
      height: "100%",
      // overflowY: "scroll",
      // scrollPaddingTop: "20px",
      // "&::-webkit-scrollbar": {
      //   width: "0.4em",
      //   display: "none",
      // },
      // "&::-webkit-scrollbar-track": {
      //   boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      //   webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      //   borderRadius: 10,
      // },
      // "&::-webkit-scrollbar-thumb": {
      //   backgroundColor: "rgba(0,0,0,.1)",
      //   outline: "1px solid slategrey",
      //   borderRadius: 10,
      // },
      // paddingTop: 50,
    },
    "& .modalHeader": {
      fontSize: 20,
      textAlign: "center",
      fontWeight: 600,
      marginBottom: 10,
      "& .closeIcon": {
        zIndex: 999,
        cursor: "pointer",
        position: "absolute",
        right: 27,
        top: 15,
        color: "#fff",
      },
    },
    "& .dataContainer": {
      marginBottom: 15,
      marginLeft: 10,
    },
    "& .headerContainer": {
      display: "flex",
      alignItems: "center",
      position: "relative",
      padding: "10px 15px 20px",
      borderBottom: "1px solid #616060",
      justifyContent: "center",
      marginBottom: "10px",
      // alignItems: "felx-",
    },
    "& .dataHeader": {
      textAlign: "center",
      fontWeight: 600,
      // textDecoration: "underline",
      textTransform: "uppercase",
      color: theme.palette.primary.lightText,
    },
  },
}));


const SpecialOffers = (props) => {
  const classes = useStyles();
  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 512, itemsToShow: 1, itemsToScroll: 1 },
    { width: 550, itemsToShow: 2, itemsToScroll: 2 },
    { width: 768, itemsToShow: 3 },
    { width: 1200, itemsToShow: 4 },
  ];

  const [showModal, setShowModal] = useState(false);
  const [queryTegMsg, SetQueryTegMsg] = useState();
  const [LeadListData, SetLeadListData] = useState([]);

  let d = new Date();
  const date =
    ("0" + (d.getDate() + 0)).slice(-2) +
    "-" +
    ("0" + (d.getMonth() + 1)).slice(-2) +
    "-" +
    d.getFullYear();

  useEffect(() => {
    WebApi.getFlightLeadList(
      {
        agentId: 0,
        dateFrom: date,
        dateTo: date,
        status: 1,
      },
      (resp) => {
        // console.log("res",resp)
        SetLeadListData(resp.data);
      },
      (error) => console.log("error", error)
    );
  }, []);

  // console.log("res",date)

  return (
    <>
      <Box className={classes.root}>
        {showModal && (
          <Box className={classes.modal}>
            <Box className="modalContainer">
              <Box className="contentContainer">
                <Box className="modalHeader">
                  <CloseIcon
                    onClick={() => setShowModal(false)}
                    className="closeIcon"
                    width={30}
                    height={30}
                  />
                </Box>
                <Box className="headerContainer">
                  <Box className="dataHeader">
                    <h3>
                      Drop us your query
                      <br />
                      we'll contact you soon
                    </h3>
                  </Box>
                </Box>

                <Box>
                  <ContactUsForm queryTegMsg={queryTegMsg} userId={props.userId} />
                </Box>
              </Box>
            </Box>
          </Box>
        )}

        {LeadListData.length > 0 && (

          <Container maxWidth="lg">
            <Grid container>
              <Grid item xs={12} style={{ marginBottom: "2rem" }}>
                <div className={classes.headSection}>
                  <h2>
                    {/* Special <span className={classes.highText}>Offers</span> */}
                    Special  Offers
                  </h2>
                  <p>
                    Discuss your goals determines success metrics identity
                    solution
                    <br /> Discuss your goals determines success metrics identity
                    solution Discuss your goals
                  </p>
                </div>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} className="carousel-grid">
                <Carousel
                  breakPoints={breakPoints}
                  enableSwipe
                  enableMouseSwipe
                // enableAutoPlay
                >
                  {LeadListData &&
                    LeadListData?.map((item) => (
                      <div className={classes.carouselItem} key={item.id}>
                        {/* <div>
                        <span>
                          <LocationOn />
                        </span>
                        <span className={classes.countryText}>
                          {item.country}
                        </span>
                      </div> */}
                        <div className="carousel-content">
                          <p>{item.title}</p>
                          <div className="img-div">
                            <img
                              src={
                                BASE_URL_IMAGE_VIEW +
                                `agency/view-file?fileName=${item.imagePath}&type=PROMO_IMAGES`
                              }
                            />

                          </div>

                          <div className="desc">{item.description}</div>
                          <div className="query-action-wrap">
                            {/* <div>
                          <p>
                            <i className="fas fa-rupee-sign" /> {item.price}
                          </p>
                          <div>
                            <StarRating rating={item.starRating} />
                          </div>
                        </div> */}

                            <div>
                              <Button
                                className={classes.viewButton}
                                onClick={() => {
                                  setShowModal(true);
                                  SetQueryTegMsg(item.location);
                                }}
                              >
                                Get Query
                              </Button>
                            </div>
                          </div>

                        </div>
                      </div>
                    ))}
                </Carousel>
              </Grid>
            </Grid>
          </Container>
        )}
      </Box>
    </>
  );
};

export default SpecialOffers;

import React from "react";
import WebApi from "api/ApiConstants";
import ReactTable from "react-table";
import dateFnsFormat from 'date-fns/format';
import { makeStyles } from "@material-ui/core";

const ODHistory = (props) => {
    const classes = useStyles();

    const [tabledata, setTabledata] = React.useState([]);
    const [rowCount, setRowCount] = React.useState(4);
    const [noDataText, setNoDataText] = React.useState(" ");
    const [loading, setLoading] = React.useState(true);
    const [finishedLoading, setFinishedLoading] = React.useState(false);
    
    const filterMethod = (filter, row, column) => {
        const id = filter.pivotId || filter.id;
        return row[id] !== undefined
            ? String(String(row[id]).toLowerCase()).startsWith(
                filter.value.toLowerCase()
            )
            : true;
    };

    const tableLoader = () => {
        WebApi.odHistoryGet({
            userId: props.agentId,
            odId: props.odId
        }, response => {
            setTabledata(response.data);
            setRowCount(response.data.length === 0 ? 4 : response.data.length);
            setNoDataText(response.data.length === 0 ? "No results" : response.data.length);
        }, error => {
            setNoDataText("ERROR");
        });
        setLoading(false);
        setFinishedLoading(true);
    };

    const isValidDate = (dateStr) => {
        return !isNaN(Date.parse(dateStr))
    };

    const columns = [
        {
            Header: "UID",
            accessor: "uid",
            filterable: true
        },
        {
            Header: "Agent Name",
            accessor: "agencyName",
            width: 140,
            filterable: false,
            Cell: rowProp => {
                return rowProp.original.agencyName ?
                    <span>{rowProp.original.agencyName}</span>
                    : ""
            }
        },
        {
            Header: "Valid From",
            accessor: "validFrom",
            filterable: false,
            Cell: rowProp => {
                return isValidDate(rowProp.original.validFrom) ?
                    <span>{dateFnsFormat(new Date(rowProp.original.validFrom), "dd-MMM-yyyy h:mm:ss a")}</span> :
                    rowProp.original.validFrom
            }
        },
        {
            Header: "Valid To",
            accessor: "validTo",
            filterable: false,
            Cell: rowProp => {
                return (
                    isValidDate(rowProp.original.validTo) ?
                        <span>
                            {dateFnsFormat(new Date(rowProp.original.validTo), "dd-MMM-yyyy h:mm:ss a")}
                        </span>
                        : rowProp.original.validTo
                )
            }
        },
        {
            Header: "Base Credit Days",
            accessor: "days",
            filterable: false
        },
        {
            Header: "OD Active",
            accessor: "activeFlag",
            filterable: false
        },
        {
            Header: "Credit Type",
            accessor: "requestType",
            filterable: false
        },
        {
            Header: "Credit Amount (₹)",
            accessor: "creditAmount",
            filterable: false,
            Cell: rowProp => {
                return (
                    <span className="pricealignment"> 
                        {rowProp.original.creditAmount}
                    </span>
                        
                )
            }
        },
        {
            Header: "Utilized Amount (₹)",
            accessor: "creditUtilize",
            filterable: false,
            Cell: rowProp => {
                return (
                    <span className="pricealignment"> 
                        {rowProp.original.creditUtilize}
                    </span>
                        
                )
            }
        },
        {
            Header: "Deposits (₹)",
            accessor: "totalDepositAmount",
            filterable: false,
            Cell: rowProp => {
                return (
                    <span className="pricealignment"> 
                        {rowProp.original.totalDepositAmount}
                    </span>
                        
                )
            }
        },
        {
            Header: "Refunds (₹)",
            accessor: "totalRefundAmount",
            filterable: false,
            Cell: rowProp => {
                return (
                    <span className="pricealignment"> 
                        {rowProp.original.totalRefundAmount}
                    </span>
                        
                )
            }
        },
        {
            Header: "Wallet Topups (₹)",
            accessor: "totalTopupAmount",
            filterable: false,
            Cell: rowProp => {
                return (
                    <span className="pricealignment"> 
                        {rowProp.original.totalTopupAmount}
                    </span>
                        
                )
            }
        },
        {
            Header: "Closing Balance (₹)",
            accessor: "closingBalance",
            filterable: false,
            Cell: rowProp => {
                return (
                    <span className="pricealignment"> 
                        {rowProp.original.closingBalance}
                    </span>
                        
                )
            }
        },
        // {
        //     Header: "Balance Deposit Amount (₹)",
        //     accessor: "balanceDeposit",
        //     filterable: false,
        //     Cell: rowProp => {
        //         return (
        //             <span className="pricealignment"> 
        //                 {rowProp.original.closingBalance}
        //             </span>
                        
        //         )
        //     }
        // },
        // {
        //     Header: "Created By",
        //     accessor: "createdBy",
        //     filterable: false
        // },
        // {
        //     Header: "Created At",
        //     accessor: "createdDate",
        //     filterable: false,
        //     Cell: rowProp => {
        //         return isValidDate(rowProp.original.createdDate) ?
        //             <span>{dateFnsFormat(new Date(rowProp.original.createdDate), "dd-MMM-yyyy h:mm:ss a")}</span> :
        //             rowProp.original.createdDate
        //     }
        // },
        {
            Header: "Remarks",
            accessor: "remarks",
            filterable: false
        }
    ];

    React.useEffect(() => {
        tableLoader();
    }, []);

    return(
        <div className={classes.root}>
            <ReactTable
                columns={columns}
                data={tabledata}
                defaultFilterMethod={filterMethod}
                filterable={true}
                showPagination={false}
                minRows={rowCount}
                loading={loading}
                pageSize={rowCount}
                noDataText={noDataText}
                className="-striped -highlight"
            />
        </div>
    );
};

export default ODHistory;

const useStyles = makeStyles(theme => ({
    root: {
        "& .table-action-btn": {
            cursor: "pointer",
            background: theme.palette.buttons.primary,
            border: `solid 0.5px ${theme.palette.buttons.primary}`,
            "& .MuiButton-label": {
                color: theme.palette.buttons.primaryContrastText,
            },
            "&:hover": {
                background: theme.palette.buttons.primaryContrastText,
                "& .MuiButton-label": {
                    color: theme.palette.buttons.primary,
                },
            }
        },
        "& .pricealignment": {
            textAlign: "right",
            display: "flow",
        }
    },
    dialogPaper: {
        fontFamily: theme.palette.font.secondary,
        "& .MuiDialogTitle-root": {
            position: "relative",
            "& .close-btn-edged": {
                position: "absolute",
                top: 5,
                right: 5,
                height: 20,
                width: 20,
                background: theme.palette.buttons.primary,
                borderRadius: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: 2,
                cursor: "pointer",
                "& .MuiSvgIcon-root": {
                    color: theme.palette.buttons.primaryContrastText
                },
                "&:hover": {
                    background: "red",
                    "& .MuiSvgIcon-root": {
                        color: "#fff"
                    }
                }
            },
        },
        "& .revoke-submit-btn": {
            cursor: "pointer",
            background: theme.palette.buttons.secondary,
            "& .MuiButton-label": {
                color: theme.palette.buttons.secondaryContrastText
            }
        },
        "& .form-group": {
            margin: "5px 0",
            "&.action-group": {
                display: "flex",
                justifyContent: "center"
            }
        },
        "& .od-root": {
            width: "90vw"
        }
    }
}));

import React from "react";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import OaFormDateField from 'oahoc/OaFormDateField';
import dateFnsFormat from 'date-fns/format';

import { Formik, Form, ErrorMessage } from 'formik';
import Collapse from '@material-ui/core/Collapse';
import { FormControl, MenuItem, Select, TextField, makeStyles } from '@material-ui/core';
import WebApi from 'api/ApiConstants';

const ODListSearchForm = (props) => {
    const classes = useStyles();
    const formikRef = React.useRef(null);
    const [collapsed, setCollapsed] = React.useState(true);
    const [startDateDisplay, setStartDateDisplay] = React.useState(new Date());
    const [endDateDisplay, setEndDateDisplay] = React.useState(new Date());

    const collapse = () => {
        collapsed ? setCollapsed(false) : setCollapsed(true);
    };

    const onFormSubmit = (searchParams, { setSubmitting, resetForm }) => {
        //console.log("searchParams",searchParams);
        props.target(searchParams);
    };

    const handleSubmitForm = (e) => {
        e.preventDefault();
        if (formikRef.current) {
            formikRef.current.handleSubmit();
        }
    };

    const onChangeStartDate = date => {
        if (formikRef.current) {
            formikRef.current.setFieldValue('startDate', dateFnsFormat(date, 'dd-MM-yyyy'));
            setStartDateDisplay(date);
            if (formikRef.current.values.endDate === "") {
                formikRef.current.setFieldValue('endDate', dateFnsFormat(endDateDisplay, 'dd-MM-yyyy'));
            }
        }
    };

    const onChangeEndDate = date => {
        if (formikRef.current) {
            formikRef.current.setFieldValue('endDate', dateFnsFormat(date, 'dd-MM-yyyy'));
            setEndDateDisplay(date);
        }
    };

    return (
        <div className={classes.root}>
            <h4 className={"oa-form-header"} onClick={collapse}>
                <span>Search OD</span>
            </h4>
            <Collapse in={collapsed}>
                <Formik
                    initialValues={props.searchParams}
                    onSubmit={onFormSubmit}
                    innerRef={formikRef}
                >
                    {({ values, isSubmitting, setFieldValue }) => (
                        <Form>
                            <GridContainer>
                                <GridItem md={3} sm={6} xs={12}>
                                    <label>Status</label>
                                    <FormControl fullWidth={true}>
                                        <Select
                                            fullWidth
                                            variant="outlined"
                                            name="activeFlag"
                                            value={values.activeFlag}
                                            onChange={e => setFieldValue('activeFlag', e.target.value)}
                                        >
                                            <MenuItem value="Y">Active</MenuItem>
                                            <MenuItem value="N">Inactive</MenuItem>
                                        </Select>
                                    </FormControl>
                                </GridItem>

                                <GridItem md={3} sm={6} xs={12}>
                                    <label>Start Date</label>
                                    <FormControl fullWidth={true}>
                                        <OaFormDateField
                                            fullWidth
                                            value={startDateDisplay}
                                            selected={startDateDisplay}
                                            onChange={onChangeStartDate}
                                            label="From"
                                            name="dateFromDisplay"
                                        />
                                    </FormControl>
                                </GridItem>

                                <GridItem md={3} sm={6} xs={12}>
                                    <label>End Date</label>
                                    <FormControl fullWidth={true}>
                                        <OaFormDateField
                                            fullWidth
                                            value={endDateDisplay}
                                            selected={endDateDisplay}
                                            onChange={onChangeEndDate}
                                            label="From"
                                            name="dateToDisplay"
                                        />
                                    </FormControl>
                                </GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem md={12} sm={12} xs={12} className="action-group">
                                    <Button className="btn-primary" onClick={handleSubmitForm}>Search</Button>
                                </GridItem>
                            </GridContainer>
                        </Form>
                    )}
                </Formik>
            </Collapse>
        </div>
    );
};

export default ODListSearchForm;

const useStyles = makeStyles(theme => ({
    root: {
        "& .MuiFormControl-root": {
            marginTop: 0
        },
        "& .oa-form-header": {
            display: "flex",
            justifyContent: "space-between",
            fontSize: 16,
            "& b": {
                textTransform: "uppercase"
            }
        },
        "& .action-group": {
            display: "flex",
            alignItems: "center",
            gap: 6,
            "& .btn-primary": {
                background: theme.palette.buttons.primary,
                "& .MuiButton-label": {
                    color: theme.palette.buttons.primaryContrastText,
                }
            },
            "& .btn-secondary": {
                background: theme.palette.buttons.secondary,
                "& .MuiButton-label": {
                    color: theme.palette.buttons.secondaryContrastText,
                }
            },
            "& .btn-tertiary": {
                background: theme.palette.buttons.tertiary,
                "& .MuiButton-label": {
                    color: theme.palette.buttons.tertiaryContrastText,
                }
            }
        }
    },
    dialogPaper: {
        fontFamily: theme.palette.font.secondary,
        "& .MuiDialogTitle-root": {
            position: "relative",
            "& .close-btn-edged": {
                position: "absolute",
                top: 5,
                right: 5,
                height: 20,
                width: 20,
                background: theme.palette.buttons.primary,
                borderRadius: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: 2,
                cursor: "pointer",
                "& .MuiSvgIcon-root": {
                    color: theme.palette.buttons.primaryContrastText
                },
                "&:hover": {
                    background: "red",
                    "& .MuiSvgIcon-root": {
                        color: "#fff"
                    }
                }
            }
        }
    }
}));

import React, { useEffect, useState } from "react";
import {
  Grid,
  Divider,
  Link,
  Button,
  TextField,
  Typography,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  Checkbox,
  IconButton,
  Collapse,
} from "@material-ui/core";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import * as Yup from "yup";
import WebApi from "api/ApiConstants";
import { apiCall } from "oautils/oaDataUtils";
import { localforageGetItem } from "oautils/oaForageUtils";
import _ from "lodash";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useMediaQuery } from "@material-ui/core";
import OaFormSelectField from "oahoc/OaFormSelectField";
import "assets/css/bus-main.css";
import "assets/css/bus-passenger-details.css";
import { AppStateContext } from "layouts/AppStateProvider";
import EventSeatIcon from "@material-ui/icons/EventSeat";
import moment from "moment";
import { AppConstant } from "appConstant";
import BusPaymentOptions from "./BusPaymentOptions";
import { Close } from "@material-ui/icons";
import OaFormAlerts from "pages/components/OaFormAlerts";
import OaFormikErrorAlert from "oahoc/OaFormikErrorAlert";
import { useHistory } from "react-router-dom";
import OaAlert from "oahoc/OaAlert";
import OaDiscountCouponSearch from "oahoc/OaDiscountCouponSearch";
import Coupons from "pages/components/Coupons";

//import BusPaymentOptions from "pages/module/flight/compact/checkinReview/PaymentDetails";

const BusPassengerDetails = (props) => {
  const [ticketFare, setTicketFare] = useState(0.0);
  const [isLoading, setIsLoading] = useState(true);
  const [fare, setFare] = useState(true);
  const classes = useStyles();
  const [data, setData] = useState({});
  const formikRef = React.useRef();
  const [userType, setUserType] = useState(null);
  const [fareBreakup, setFareBreakup] = useState({});
  const { SetMessage, SetError } = React.useContext(AppStateContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [discountCoupons, setDiscountCoupons] = React.useState(null);
  const [couponObj, setCouponObj] = React.useState(null);
  const [addDisCoup, setAddDisCoup] = React.useState(null);
  const [couponStatus, setCouponStatus] = React.useState({
    error: false,
    success: false,
    message: ""
  });
  const [openPromo, setOpenPromo] = React.useState(true);
  const [totalPayable, setTotalPayable] = React.useState(0);

  const [paymentModes, setPaymentModes] = useState([]);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = React.useState(
    null
  );
  const [selectedPaymentMode, setSelectedPaymentMode] = React.useState(null);

  const [payModeTabIndex, setPayModeTabIndex] = React.useState(0);
  const [openTNC, setOpenTNC] = React.useState(false);
  const [openFareRules, setOpenFareRules] = React.useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [infoText, setInfoText] = useState("");
  const [isErrorOpen, setIsErrorOpen] = React.useState(true);
  const [isPayable, setIsPayable] = React.useState(false);
  const history = useHistory();

  const [walletCriteria, setWalletCriteria] = React.useState(false);
  const [odExpired, setOdExpired] = React.useState(false);

  const [balanceStr, setBalanceStr] = React.useState(false);
  const [isAlertPrompt, setIsAlertPrompt] = React.useState(false);
  const [alertMsg, setAlertMsg] = React.useState("");
  const [primaryButtonText, setPrimaryButtonText] = React.useState("");
  const [secondaryButtonText, setSecondaryButtonText] = React.useState("");

  const [alertTitle, setAlertTitle] = React.useState("");

  const getPassengerArray = () => {
    getUnUsedSeat();
    // console.log(props.location.state.selectedSeatIndex);
    let passengers = [];
    props.location.state.selectedSeatIndex.map((value, index) => {
      if (index === 0) {
        passengers.push(new PassengerObj(value, "", "", "", "", true));
      } else {
        passengers.push(new PassengerObj(value, "", "", "", "", false));
      }
    });

    return passengers;
  };

  const getUnUsedSeat = () => {
    let notfound = [];
    props.location.state.selectedSeatIndex.map((value, index) => {
      let pos =
        formikRef.current &&
        formikRef.current.values.Passenger.map(function(e) {
          return e.Seat;
        }).indexOf(value);
      if (pos === -1) {
        notfound.push(value);
      }
    });

    return notfound[0];
  };

  const initialValues = {
    acceptTerms: false,
    Passenger: getPassengerArray(),
    Phoneno: "",
    Address: "",
    Email: "",
    gstNo: "",
    gstName: "",
    gstMobile: "",
    gstAddress: "",
    gstEmail: "",
    ResultIndex: props.location.state.ResultIndex,
    TraceId: props.location.state.TraceId,
    BoardingPointId: props.location.state.BoardingPointId,
    DroppingPointId: props.location.state.DroppingPointId,
    TravelName: props.location.state.TravelName,
    BusType: props.location.state.BusType,
    AvailableSeats: props.location.state.AvailableSeats,
    DepartureTime: props.location.state.DepartureTime,
    BoardingPointsCity: props.location.state.BoardingPointsCity,
    DroppingPointsCity: props.location.state.DroppingPointsCity,
    ArrivalTime: props.location.state.ArrivalTime,
    selectedSeatIndex: props.location.state.selectedSeatIndex,
    durationInMinute: props.location.state.durationInMinute,
    IdType: "",
    IdNumber: "",
    RouteId: props.location.state.RouteId,
    SourceCityId: props.location.state.searchParams.departureCityId,
    SourceCityName: props.location.state.searchParams.departureCityName,
    DestinationCityId: props.location.state.searchParams.destinationCityId,
    DestinationCityName: props.location.state.searchParams.destinationCityName,
    TravelDate: props.location.state.searchParams.selectedDate,
    ArrivalTime: props.location.state.ArrivalTime,
    BusType: props.location.state.BusType,
    DepartureTime: props.location.state.DepartureTime,
    DroppingPointsCity: props.location.state.DroppingPointsCity,
    BoardingPointsCity: props.location.state.BoardingPointsCity,
    TravelName: props.location.state.TravelName,
    durationInMinute: props.location.state.durationInMinute,
    BoardingPointNumber: props.location.state.boardingPoint
      ?.CityPointContactNumber
      ? props.location.state.boardingPoint.CityPointContactNumber
      : "",
    DroppingPointNumber: props.location.state.droppingPoint
      ?.CityPointContactNumber
      ? props.location.state.droppingPoint.CityPointContactNumber
      : "",
  };

  const validationSchema = Yup.object().shape({
    isGST: Yup.boolean().default(false),
    acceptTerms: Yup.bool().oneOf(
      [true],
      "Accept Terms & Conditions is required"
    ),
    Phoneno: Yup.string()
      .typeError("You must specify a mobileNo")
      .required("Mobile no is required"),
    Email: Yup.string()
      .typeError("You must specify a email")
      .required("Email is required")
      .email("Enter a valid email"),
    Address: Yup.string()
      .typeError("You must specify address")
      .required("Address is required"),
    // gstNo: Yup.string().when('isGST', {
    //     is: true,
    //     then: Yup.string().typeError('You must specify a GST No').required('GST No is required')
    // }),
    // gstName: Yup.string().when('isGST', {
    //     is: true,
    //     then: Yup.string().typeError('You must specify a name').required('name is required')
    // }),
    // gstMobile: Yup.string().when('isGST', {
    //     is: true,
    //     then: Yup.string().typeError('You must specify a mobileNo').required('mobileNo is required'),
    // }),
    // gstAddress: Yup.string().when('isGST', {
    //     is: true,
    //     then: Yup.string().typeError('You must specify a address').required('address is required'),
    // }),
    // gstEmail: Yup.string().when('isGST', {
    //     is: true,
    //     then: Yup.string().typeError('You must specify a Email').required('Email is required')
    // }),
    Passenger: Yup.array()
      .of(
        Yup.object().shape({
          Title: Yup.string()
            .typeError("You must specify a title")
            .required("Title is required"),
          FirstName: Yup.string()
            .typeError("You must specify a first name")
            .required("First name is required"),
          LastName: Yup.string()
            .typeError("You must specify a last name")
            .required("Last name is required"),
          Age: Yup.number()
            .typeError("Age is required")
            .required("Age is required")
            .moreThan(0, "Age is required"),
        })
      )
      .min(1, "Need at least one passenger")
      .max(
        props.location.state.selectedSeatIndex.length,
        "Count of passengers cannot exceed the number of seats selected"
      ),
  });

  const validatePaxDetails = (values) => {
    let noErrorFound = true;
    //values.Passenger.length
    //    console.log("values",values);
    //console.log("values" , values.Passenger[0]);

    for (let k = 0; k < values.Passenger.length; k++) {
      //    console.log("values.isDomestic" , (values.Passenger[k]).FirstName);
      let paxTitle = values.Passenger[k].Title;
      let paxFname = values.Passenger[k].FirstName;
      let paxLname = values.Passenger[k].LastName;
      let age = values.Passenger[k].Age;

      if (paxTitle === "") {
        setAlertMsg("Please select the title of the Pax " + (k + 1));
        noErrorFound = false;
        // adultPax[k]
        break;
      }
      if (paxFname === "") {
        setAlertMsg("Please Enter the firstname of the Pax  " + (k + 1));
        noErrorFound = false;
        // adultPax[k]
        break;
      }
      if (!/^[A-Za-z ]*$/.test(paxFname)) {
        setAlertMsg(
          "Firstname should contains Alphabates and space only of the Pax " +
            (k + 1)
        );
        noErrorFound = false;
        break;
      }
      if (!/^[^\s][A-Za-z ]*$/.test(paxFname)) {
        setAlertMsg("Firstname cant be start with space of the Pax " + (k + 1));
        noErrorFound = false;
        break;
      }

      if (paxFname.length < 2) {
        setAlertMsg(
          "Firstname should not be less than 2 char of the Pax " + (k + 1)
        );
        noErrorFound = false;
        // adultPax[k]
        break;
      }
      if (paxLname === "") {
        setAlertMsg("Please select the lastname of the Pax " + (k + 1));
        noErrorFound = false;
        // adultPax[k]
        break;
      }
      if (!/^[A-Za-z ]*$/.test(paxLname)) {
        setAlertMsg(
          "Lastname should contains Alphabates and space only of the Pax " +
            (k + 1)
        );
        noErrorFound = false;
        break;
      }
      if (!/^[^\s][A-Za-z ]*$/.test(paxLname)) {
        setAlertMsg("Lastname cant be start with space of the Pax " + (k + 1));
        noErrorFound = false;
        break;
      }

      if (age === "") {
        setAlertMsg("Age should not be empty of the Pax " + (k + 1));
        noErrorFound = false;
        break;
      }

      if (!/^[0-9]*$/.test(age)) {
        setAlertMsg("Age should contains Numbers only of the Pax " + (k + 1));
        noErrorFound = false;
        break;
      }
      if (age === "0") {
        setAlertMsg("Age should not be 0 of the Pax " + (k + 1));
        noErrorFound = false;
        break;
      }
    }
    if (noErrorFound) {
      if (values.Phoneno === "" || values.Phoneno === null) {
        setAlertMsg("Mobile Number should not be empty ");
        noErrorFound = false;
      }
      if (noErrorFound) {
        if (!/^[0-9]*$/.test(values.Phoneno)) {
          setAlertMsg("Mobile Number should contains Numbers only ");
          noErrorFound = false;
        }
      }
      if (noErrorFound) {
        if (values.Phoneno.length !== 10) {
          setAlertMsg("Mobile Number should be 10 digits Numbers only ");
          noErrorFound = false;
        }
      }

      if (noErrorFound) {
        if (values.Email === "" || values.Email === null) {
          setAlertMsg("Email Id should not be empty ");
          noErrorFound = false;
        }
      }
      if (noErrorFound) {
        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.Email)) {
          setAlertMsg("Invalid Email Id ");
          noErrorFound = false;
        }
      }
      if (noErrorFound) {
        if (values.Address === "") {
          setAlertMsg("Address should not be empty ");
          noErrorFound = false;
        }
      }
      let hasGst = values.isGST;
      //  console.log(values.isGST );

      // setAlertMsg(noErrorFound);
      if (hasGst && noErrorFound) {
        //
        let gstNumber = values.gstNo;
        let gstCompany = values.gstName;
        let gstCompanyNumber = values.gstMobile;
        let gstCompanyAddress = values.gstAddress;
        let gstEmail = values.gstEmail;
        // setAlertMsg(noErrorFound);

        //console.log("gstNumber " + gstNumber );
        //console.log(gstCompany )
        //console.log(gstCompanyNumber )
        //console.log(gstCompanyAddress )
        //console.log(gstEmail)

        let reg = /^([0-9]{2}[a-zA-Z]{4}([a-zA-Z]{1}|[0-9]{1})[0-9]{4}[a-zA-Z]{1}([a-zA-Z]|[0-9]){3}){0,15}$/;

        if (gstNumber === "" || gstNumber === null) {
          setAlertMsg("GST Should not be blank ");
          noErrorFound = false;
        } else if (!reg.test(gstNumber)) {
          gstNumber = gstNumber.toUpperCase();

          setAlertMsg("Invalid GST number ");
          noErrorFound = false;
        }

        if (noErrorFound) {
          if (gstCompany === "" || gstCompany === null) {
            setAlertMsg("GST Company Should not be blank ");
            noErrorFound = false;
          }
        }
        if (noErrorFound) {
          if (gstCompanyNumber === "" || gstCompanyNumber === null) {
            setAlertMsg("GST Company Contact Number Should not be blank ");
            noErrorFound = false;
          } else if (!/^[0-9]*$/.test(gstCompanyNumber)) {
            setAlertMsg("Mobile Contact Number should contains Numbers only ");
            noErrorFound = false;
          }
        }
        if (noErrorFound) {
          if (gstCompanyAddress === "" || gstCompanyAddress === null) {
            setAlertMsg("GST Company Address Should not be blank ");
            noErrorFound = false;
          }
        }
        if (noErrorFound) {
          if (gstEmail === "" || gstEmail === null) {
            setAlertMsg("GST Company GST Email Should not be blank ");
            noErrorFound = false;
          }
          if (noErrorFound) {
            if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(gstEmail)) {
              setAlertMsg("Invalid Company GST Email Id ");
              noErrorFound = false;
            }
          }
        }

        //console.log(hasGst +"," + gstNumber);
      }
      if (noErrorFound) {
        if (!values.acceptTerms) {
          setAlertMsg("Please accept Terms and Conditions");
          noErrorFound = false;
        }
      }
    }

    return noErrorFound;
  };

  const handleTncOpen = () => {
    setOpenTNC(true);
  };

  const handleCloseTNC = () => {
    setOpenTNC(false);
  };

  const handleOpenFareRules = () => {
    setOpenFareRules(true);
  };

  const handleCloseFareRules = () => {
    setOpenFareRules(false);
  };

  const TitleComp = (props) => {
    return (
      <div className="section-title-wrapper">
        <span className="heading-icon">
          <i className={props.fa} />
        </span>
        <Typography className="heading-text">{props.title}</Typography>
      </div>
    );
  };

  const getDuration = (diffTime) => {
    const diffHrs = Math.ceil(diffTime / 60);
    const diffMin = Math.ceil(diffTime % 60);
    return diffHrs + "h " + diffMin + "m";
  };

  const handleSubmit = (values) => {
    if (validatePaxDetails(values)) {
      paymentModes.map((payMode, index) => {
        if (index === payModeTabIndex) {
          if (payMode.method == "cash") {
            values.pgOption = {
              method: "cashPayment",
              selected: payMode.selected,
            };
            values.payMode = "cash";
          } else {
            payMode.gateway.map((gatew) => {
              if (gatew.method === selectedPaymentMethod) {
                values.pgOption = gatew;
                values.payMode = payMode.method;
              }
            });
          }
        }
      });
      const discountApplied = addDisCoup;
      history.push({
        pathname: "/bus/passenger-review",
        state: {
          ...values,
          couponObj,
          totalPayable,
          discountApplied,
          fareBreakup
        }
      });
    } else {
      setAlertTitle("Fields Mandatory");
      setIsError(true);
      setPrimaryButtonText("OK");
      setIsAlertPrompt(true);
    }
  };

  const getDiscountCoupons = (userId) => {
    WebApi.getCoupons(
      {
        allVoucher: false,
        loggedInUserId: userId,
        voucherCode: "",
        onwardFareType: "",
        returnFareType: "",
        voucherUID: 0,
        service: "Bus"
      },
      (resp) => {
        if (resp && resp.success) {
          setDiscountCoupons(resp.data);
        }
      },
      (error) => {
        console.error("hotel discount coupon error", error);
      }
    );
  };

  const applyCoupon = (val) => {
    if (val) {
      WebApi.validateDiscountVoucherUsage({ voucherCode: val.voucher_code }, response => {
        if (response.success) {
          if (response.data.usageLimitExceeded) {
            console.error(`Discount coupon ${val.voucher_code} usage limit exceeded! Please try another.`);
            setCouponStatus({
              ...couponStatus,
              error: true,
              message: `Discount coupon ${val.voucher_code} usage limit exceeded! Please try another.`
            });
          } else {
            setCouponObj(val);
            setAddDisCoup(true);
            // console.log("discount coupon applied successfully");
            setCouponStatus({
              ...couponStatus,
              success: true,
              message: "discount coupon applied successfully"
            });
          }
        }
      }, error => {
        console.error("error validating discount coupon usage", error);
      });
    }
  };

  const removeCoupon = () => {
    setAddDisCoup(false);
    setTotalPayable(parseFloat(fareBreakup?.TotalSeatPrice + 
      fareBreakup?.TotalSupplierCharge + 
      fareBreakup?.TotalGst).toFixed(2));
    setFareBreakup(prevState => ({
      ...prevState,
      discount: 0
    }));
  };

  useEffect(() => {
    if (isLoading) {
      localforageGetItem("user-details", function(err, value) {
        if (value) {
          setUserType(value.userType ? value.userType : "b2c_user");
          // console.log(" ### value.userType ##  " + value.userType);

          // console.log("#### user-details", value);
          // console.log(value.partnerId);

          apiCall(
            WebApi.getCompanyPGOptions,
            {
              partnerId: value.partnerId,
              serviceId: 4,
            },
            (response) => {
              // console.log("##### response.data ###")
              response.data.map((value) => {
                value.gateway &&
                  value.gateway.map((data) => {
                    if (data.selected === true) {
                      setSelectedPaymentMethod(data.method);
                    }
                  });
              });
              setPaymentModes(response.data);
            }
          );
          getDiscountCoupons(value.userId);
        }
      });

      setTicketFare(
        props.location.state.selectedSeatIndex.reduce(function(acc, obj) {
          return (
            (typeof acc === "object"
              ? acc.Price.CommissionCharge.grossProductPrice
              : acc) + obj.Price.CommissionCharge.grossProductPrice
          );
        }, 0)
      );

      localforageGetItem("user-id", function(err, value) {
        apiCall(
          WebApi.getAgentExtendedDetails,
          { agentId: value },
          (response) => {
            setIsLoading(false);

            if (response.success === true) {
              setData(response.data);
              console.log("passenger", response.data);

              console.log("response.", response.data?.isBaseCreditSet);

              if (
                response.data?.isBaseCreditSet &&
                new Date() < new Date(response.data?.creditValidity)
              ) {
                if (response.data.availableCreditAmount >= ticketFare) {
                  setWalletCriteria(true);
                  setBalanceStr(
                    "Current Balance is : " +
                      response.data.balance +
                      ", Available OD : " +
                      response.data.availableCreditAmount
                  );
                } else {
                  setWalletCriteria(response.data.balance >= ticketFare);
                  setBalanceStr(
                    "Current Balance is : " + response.data.balance
                  );
                }
              } else {
                setWalletCriteria(response.data.balance >= ticketFare);
                setBalanceStr("Current Balance is : " + response.data.balance);
              }
              setOdExpired(
                new Date() > new Date(response.data?.creditValidity)
              );

              formikRef.current.setFieldValue(
                "Phoneno",
                response.data.mobilenumber
              );
              formikRef.current.setFieldValue("Email", response.data.email);
              formikRef.current.setFieldValue("gstNo", response.data.gstNumber);
              formikRef.current.setFieldValue("gstName", response.data.gstName);
              formikRef.current.setFieldValue(
                "gstMobile",
                response.data.gstMobile
              );
              formikRef.current.setFieldValue(
                "gstAddress",
                response.data.gstAddress
              );
              formikRef.current.setFieldValue(
                "gstEmail",
                response.data.gstEmail
              );
            } else {
              setIsError(true);
            }
          }
        );
      });
    }
  }, [isLoading]);

  useEffect(() => {
    if (isLoading) {
      let TotalSeatPrice = 0;
      let TotalGst = 0;
      let TotalSupplierCharge = 0;

      props.location.state.selectedSeatIndex.map((passenger, index) => {
        // console.log(passenger);
        let publishedPriceAfterCommissions =
          passenger.Price.CommissionCharge.grossProductPrice;
        let publishedPrice = passenger.Price.PublishedPrice;
        let gst = 0;

        if (passenger.Price.GST.TaxableAmount > 0) {
          gst =
            gst +
            passenger.Price.GST.CGSTAmount +
            passenger.Price.GST.SGSTAmount +
            passenger.Price.GST.IGSTAmount +
            passenger.Price.GST.CessAmount;
        }

        TotalSeatPrice = TotalSeatPrice + publishedPrice;
        TotalSupplierCharge =
          TotalSupplierCharge + publishedPriceAfterCommissions - publishedPrice;
        TotalGst = TotalGst + gst;
      });

      setFareBreakup({
        TotalSeatPrice: TotalSeatPrice,
        TotalSupplierCharge: TotalSupplierCharge,
        TotalGst: TotalGst,
        passengerCount: props.location.state.selectedSeatIndex.length,
        discount: 0
      });
      // setGrossTotal(TotalSeatPrice+TotalSupplierCharge+TotalGst);
      setTotalPayable(parseFloat(TotalSeatPrice + TotalSupplierCharge + TotalGst).toFixed(2));
      setIsLoading(false);
    }
  }, [isLoading, fare]);

  React.useEffect(() => {
    if(couponStatus?.success && addDisCoup && fareBreakup) {
      setTotalPayable((parseFloat(totalPayable) - parseFloat(couponObj.voucher_value)).toFixed(2));
      setFareBreakup(prevState => ({
        ...prevState,
        discount: couponObj.voucher_value
      }));
    }
  }, [couponStatus, couponObj, addDisCoup]);

  React.useEffect(() => {
    console.log("ticketFare", ticketFare)
    console.log("totalPayable", totalPayable)
  }, [ticketFare, totalPayable]);

  return (
    <>
      <div
        style={{
          paddingLeft: "4%",
          paddingRight: "4%",
          // paddingTop: "15px",
          // marginTop: 50,
          maxWidth: "1236px",
        }}
        className={classes.root}
      >
        {/* <OaFormAlerts
          isSaving={isSaving}
          isSuccess={isSuccess}
          isError={isError}
          infoText={infoText}
          setIsError={setIsError}
          setIsSuccess={setIsSuccess}
        /> */}
        {isError && (
          <Grid item md={12} xs={12}>
            <OaAlert
              isPrompt={isAlertPrompt}
              msg={alertMsg}
              title={alertTitle}
              isError={isError}
              primaryButtonText={primaryButtonText}
              secondaryButtonText={secondaryButtonText}
              togglePrompt={() => {
                setIsAlertPrompt(!isAlertPrompt);
              }}
            ></OaAlert>
          </Grid>
        )}
        <Formik
          innerRef={formikRef}
          initialValues={initialValues}
          //validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, errors, status, touched }) => (
            <Form style={{ background: "#eee", padding: 10 }}>
              <Grid container spacing={2}>
                <Grid
                  item
                  md={8}
                  xs={12}
                  className="left-side-passenger-detail"
                >
                  <Grid className="section-container">
                    <Box className="secton-header">
                      <TitleComp fa="fa fa-bus" title="Bus Details" />
                    </Box>
                    <Grid container className="section-contentContainer">
                      {/* <Grid item md={8} xs={6} align="left">
                        <Typography className={classes.coachName}>
                          
                        </Typography>
                      </Grid> */}
                      <Grid item md={8} xs={8} style={{ marginBottom: 10 }}>
                        <Typography
                          className={classes.busName}
                          style={{ fontWeight: 500 }}
                        >
                          Coach Name : {props.location.state.BusType}
                        </Typography>
                      </Grid>
                      <Grid item md={5} xs={5} className="travellingPoint">
                        <Typography className={classes.Heading}>
                          {props.location.state.origin}
                        </Typography>
                        <Typography className={classes.boardingHeading}>
                          Boarding Point:{" "}
                          {props.location.state.BoardingPointsCity}
                        </Typography>
                        <Typography
                          style={{
                            fontSize: isMobile ? 16 : 20,
                            color: theme.palette.primary.blackPearl,
                            marginTop: 5,
                          }}
                        >
                          {moment(props.location.state.DepartureTime).format(
                            "LT"
                          )}
                        </Typography>
                        <Typography style={{ fontSize: isMobile ? 12 : 16 }}>
                          {moment(props.location.state.DepartureTime).format(
                            "MMMM Do YYYY"
                          )}
                        </Typography>
                      </Grid>
                      <Grid
                        container
                        item
                        md={2}
                        alignItems="center"
                        className="clockContainer"
                        xs={2}
                      >
                        <AccessTimeIcon />

                        <Grid
                          item
                          md={12}
                          xs={12}
                          className="clockTextContainer"
                        >
                          <span className="clockText">
                            {getDuration(props.location.state.durationInMinute)}
                          </span>
                        </Grid>
                      </Grid>
                      <Grid item md={5} xs={5} className="travellingPoint">
                        <Typography className={classes.Heading} align="right">
                          {props.location.state.destination}
                        </Typography>
                        <Typography
                          className={classes.boardingHeading}
                          align="right"
                        >
                          Droping Point:{" "}
                          {props.location.state.DroppingPointsCity}
                        </Typography>
                        <Typography
                          align="right"
                          className={classes.time}
                          style={{
                            fontSize: isMobile ? 16 : 20,
                            color: theme.palette.primary.blackPearl,
                            marginTop: 5,
                          }}
                        >
                          {moment(props.location.state.ArrivalTime).format(
                            "LT"
                          )}
                        </Typography>
                        <Typography
                          align="right"
                          style={{ fontSize: isMobile ? 12 : 16 }}
                        >
                          {moment(props.location.state.ArrivalTime).format(
                            "MMMM Do YYYY"
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    md={12}
                    // style={{ marginTop: 20 }}
                    className="section-container"
                  >
                    <Grid item md={12}>
                      <Grid
                        container
                        justifyContent="center"
                        className="secton-header"
                      >
                        <Grid item md={6} xs={9}>
                          <TitleComp
                            fa="fa fa-users"
                            title="Passenger Details"
                          />
                        </Grid>
                        <Grid item md={6} xs={3} align="right">
                          <Button
                            variant="contained"
                            className={classes.modifyButton}
                            align="right"
                            type="submit"
                            onClick={(e) => {
                              e.preventDefault();
                              history.goBack();
                            }}
                          >
                            {isMobile ? "Modify" : "Modify Selection"}
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid container className="section-contentContainer">
                      <Grid item md={12} className="section-contentContainer2">
                        <FieldArray
                          name="Passenger"
                          render={(arrayHelpers) => (
                            <div>
                              {values.Passenger &&
                              values.Passenger.length > 0 ? (
                                values.Passenger.map((Passenger, index) => (
                                  <div key={index}>
                                    <Grid
                                      container
                                      spacing={2}
                                      style={{
                                        paddingTop: 5,
                                        paddingBottom: 10,
                                      }}
                                    >
                                      <Grid item md={12} sm={12} xs={12}>
                                        <span className="title-text">
                                          PASSENGER {index + 1}
                                        </span>
                                      </Grid>
                                      <Grid item md={2} sm={12} xs={12}>
                                        <OaFormSelectField
                                          label="Title"
                                          name={`Passenger[${index}].Title`}
                                          style={{
                                            marginTop: 0,
                                            lineHeight: 17,
                                          }}
                                          options={[
                                            { value: "Mr", label: "Mr" },
                                            { value: "Mrs", label: "Mrs" },
                                            { value: "Ms", label: "Ms" },
                                            {
                                              value: "Transgender",
                                              label: "Transgender",
                                            },
                                          ]}
                                        />
                                        <br />
                                        <ErrorMessage
                                          name={`Passenger[${index}].Title`}
                                          className="error"
                                          component="div"
                                        />
                                        <OaFormikErrorAlert
                                          name={`Passenger[${index}].Title`}
                                          error={props.error}
                                          touched={props.touched}
                                        />
                                      </Grid>
                                      <Grid item md={3} sm={12} xs={12}>
                                        <Field
                                          as={TextField}
                                          fullWidth
                                          // placeholder={"FIRST NAME"}
                                          label="FIRST NAME"
                                          variant="outlined"
                                          // className="passenger-field"
                                          name={`Passenger[${index}].FirstName`}
                                        />
                                        <ErrorMessage
                                          name={`Passenger[${index}].FirstName`}
                                          className="error"
                                          component="div"
                                        />
                                        <OaFormikErrorAlert
                                          name={`Passenger[${index}].FirstName`}
                                          error={props.error}
                                          touched={props.touched}
                                        />
                                      </Grid>

                                      <Grid item md={3} sm={12} xs={12}>
                                        <Field
                                          as={TextField}
                                          fullWidth
                                          // placeholder={"LAST NAME"}
                                          label="LAST NAME"
                                          variant="outlined"
                                          // className="passenger-field"
                                          name={`Passenger[${index}].LastName`}
                                        />
                                        <ErrorMessage
                                          name={`Passenger[${index}].LastName`}
                                          className="error"
                                          component="div"
                                        />
                                        <OaFormikErrorAlert
                                          name={`Passenger[${index}].LastName`}
                                          error={props.error}
                                          touched={props.touched}
                                        />
                                      </Grid>
                                      <Grid item md={2} sm={12} xs={12}>
                                        <Field
                                          as={TextField}
                                          fullWidth
                                          // placeholder={"AGE"}
                                          label="AGE"
                                          //type="number"
                                          inputProps={{ min: 0 }}
                                          min={0}
                                          variant="outlined"
                                          // className="passenger-field"
                                          name={`Passenger[${index}].Age`}
                                        />
                                        <br />
                                        <ErrorMessage
                                          name={`Passenger[${index}].Age`}
                                          className="error"
                                          component="div"
                                        />
                                        <OaFormikErrorAlert
                                          name={`Passenger[${index}].Age`}
                                          error={props.error}
                                          touched={props.touched}
                                        />
                                      </Grid>

                                      <Grid item md={1} sm={12} xs={12}>
                                        <EventSeatIcon
                                          style={{
                                            color: theme.palette.primary.main,
                                            height: "1.3em",
                                          }}
                                        />
                                        <Typography
                                          style={{
                                            marginTop: -10,
                                            marginLeft: 8,
                                            fontSize: 13,
                                          }}
                                        >
                                          {" "}
                                          {Passenger.Seat.SeatName}
                                        </Typography>
                                        {/* </span> */}
                                      </Grid>
                                    </Grid>
                                  </div>
                                ))
                              ) : (
                                <>
                                  <Button
                                    className="result-item-select-button"
                                    onClick={() =>
                                      arrayHelpers.push(
                                        new PassengerObj(
                                          {},
                                          "",
                                          "",
                                          "",
                                          "",
                                          false
                                        )
                                      )
                                    }
                                  >
                                    {/* show this when user has removed all friends from the list */}
                                    Add a Passenger
                                  </Button>
                                  <ErrorMessage
                                    name={`Passenger`}
                                    className="error"
                                    component="div"
                                  />
                                  <OaFormikErrorAlert
                                    name={`Passenger`}
                                    error={props.error}
                                    touched={props.touched}
                                  />
                                </>
                              )}
                            </div>
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid
                    md={12}
                    xs={12}
                    // style={{ margin: "13px 0" }}
                    className="section-container"
                  >
                    <Grid item>
                      <Typography className="secton-header">
                        <TitleComp fa="fa fa-phone" title="Contact Details" />
                      </Typography>
                    </Grid>
                    <Grid
                      // item
                      md={12}
                      xs={12}
                      className="section-contentContainer"
                    >
                      <Grid
                        container
                        spacing={1}
                        className="section-contentContainer2"
                      >
                        <Grid item md={6} xs={12}>
                          <Field
                            as={TextField}
                            fullWidth
                            // placeholder="10 Digit Mobile Number "
                            label="Mobile Number"
                            variant="outlined"
                            // className="passenger-field"
                            type="mobile"
                            name={`Phoneno`}
                          />
                          <ErrorMessage
                            name={`Phoneno`}
                            className="error"
                            component="div"
                          />
                          <OaFormikErrorAlert
                            name={`Phoneno`}
                            error={props.error}
                            touched={props.touched}
                          />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <Field
                            as={TextField}
                            fullWidth
                            label="Email"
                            // placeholder="abc@xyz.com "
                            variant="outlined"
                            // className="passenger-field"
                            type="email"
                            name={`Email`}
                          />
                          <ErrorMessage
                            name={`Email`}
                            className="error"
                            component="div"
                          />
                          <OaFormikErrorAlert
                            name={`Email`}
                            error={props.error}
                            touched={props.touched}
                          />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <OaFormSelectField
                            label="Select ID Type"
                            name="IdType"
                            style={{ marginTop: 0 }}
                            options={[
                              { value: "Aadhar no", label: "AADHAAR" },
                              { value: "Pan no", label: "PAN" },
                              { value: "Voterid card", label: "VOTER ID" },
                              { value: "Passport", label: "PASSPORT" },
                              {
                                value: "Driving licence",
                                label: "DRIVING LICENSE",
                              },
                            ]}
                          />
                          <ErrorMessage
                            name="IdType"
                            className="error"
                            component="div"
                          />
                          <OaFormikErrorAlert
                            name="IdType"
                            error={props.error}
                            touched={props.touched}
                          />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <Field
                            as={TextField}
                            fullWidth
                            // placeholder="ID Number"
                            label="ID Number"
                            variant="outlined"
                            // className="passenger-field"
                            name="IdNumber"
                          />
                          <ErrorMessage
                            name="IdNumber"
                            className="error"
                            component="div"
                          />
                          <OaFormikErrorAlert
                            name="IdNumber"
                            error={props.error}
                            touched={props.touched}
                          />
                        </Grid>
                        <Grid item md={12} xs={12}>
                          <Field
                            as="textarea"
                            // as={TextField}
                            rows="7"
                            fullwidth="true"
                            style={{
                              width: "100%",
                              border: "1px solid gray",
                              padding: "5px",
                              marginTop: 5,
                            }}
                            placeholder="Address"
                            // label="Address"
                            variant="outlined"
                            // component={TextField}

                            // className="passenger-field"
                            name={`Address`}
                          />
                          <ErrorMessage
                            name={`Address`}
                            className="error"
                            component="div"
                          />
                          <OaFormikErrorAlert
                            name={`Address`}
                            error={props.error}
                            touched={props.touched}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                {/* // right hand side */}

                <Grid item md={4} xs={12}>
                  {/* <Grid className="section-container">
                    <Grid item md={12}>
                      <Typography className="secton-header">
                        <TitleComp fa="fa fa-bus" title="Bus Details" />
                      </Typography>
                    </Grid>
                    <Grid container className="section-contentContainer">
                 
                      <Grid item md={4}></Grid>
                      <Grid item md={6} style={{ marginTop: 10 }} xs={6}>
                        <Typography className={classes.coachName}>
                          Departure Time
                        </Typography>
                        <Typography>
                          {moment(props.location.state.DepartureTime).format(
                            "LT"
                          )}
                        </Typography>
                      </Grid>
                      <Grid item md={6} style={{ marginTop: 10 }} xs={6}>
                        <Typography className={classes.coachName} align="right">
                          Arrival Time
                        </Typography>
                        <Typography align="right">
                          {moment(props.location.state.ArrivalTime).format(
                            "LT"
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid> */}

                  {/* <Grid item md={12} style={{ marginTop: 15 }}>
                    <Divider />
                  </Grid> */}
                  {/* <Grid className="section-container">
                    <Grid item md={12} xs={6}>
                      <Typography className="secton-header">
                        <TitleComp fa="fa fa-bus" title="Seat Details" />
                      </Typography>
                    </Grid>
                    <Grid container className="section-contentContainer">
                      <Grid item md={8} xs={6}>
                        <Typography
                          className={classes.busName}
                          style={{ fontWeight: 400 }}
                        >
                          {props.location.state.selectedSeatIndex.length} Seat
                          Selected
                        </Typography>
                        <Grid container>
                          {values.Passenger &&
                            values.Passenger.length > 0 &&
                            values.Passenger.map((Passenger, index) => (
                              <Grid
                                key={index}
                                item
                                style={{ paddingLeft: 10, paddingRight: 10 }}
                              >
                                <EventSeatIcon
                                  style={{
                                    color: theme.palette.primary.main,
                                    height: "1.3em",
                                  }}
                                />
                                <Typography
                                  align="center"
                                  style={{
                                    marginTop: -10,
                                    // marginLeft: 8,
                                    fontSize: 13,
                                    textTransform: "uppercase",
                                  }}
                                >
                                  {" "}
                                  {Passenger.Seat.SeatName}
                                </Typography>
                              </Grid>
                            ))}
                        </Grid>
                      </Grid>
                      <Grid item md={4} xs={6}></Grid>
                    </Grid>
                  </Grid> */}

                  <Grid className="section-container">
                    <Grid item md={12}>
                      <Typography className="secton-header">
                        <TitleComp fa="fa fa-bus" title="Fare Summary" />
                      </Typography>
                    </Grid>
                    {!isLoading && fareBreakup && (
                      <Grid
                        container
                        className="section-contentContainer"
                        style={{ padding: 0 }}
                      >
                        <Grid style={{ padding: 15, width: "100%" }}>
                          <Grid
                            style={{
                              display: "flex",
                              flexDirection: "row",
                            }}
                          >
                            <Grid item md={6} xs={6}>
                              <Typography
                                style={{
                                  color: theme.palette.primary.blackPearl,
                                  fontWeight: 500,
                                  fontSize: 14,
                                }}
                              >
                                Base Fare
                              </Typography>
                            </Grid>
                            <Grid item md={6} xs={6}>
                              <Grid container>
                                <Grid item md={7} xs={8}>
                                  <Typography
                                    align="right"
                                    style={{
                                      fontFamily: "Roboto",
                                      color: theme.palette.primary.blackPearl,
                                      fontWeight: 500,
                                      fontSize: 14,
                                    }}
                                  >
                                    {fareBreakup.passengerCount} X{" "}
                                    {fareBreakup.TotalSeatPrice /
                                      fareBreakup.passengerCount}{" "}
                                    =
                                  </Typography>
                                </Grid>

                                <Grid item md={5} xs={4}>
                                  <Typography
                                    align="right"
                                    style={{
                                      fontFamily: "Roboto",
                                      color: theme.palette.primary.blackPearl,
                                      fontWeight: 500,
                                      fontSize: 14,
                                    }}
                                  >
                                    {fareBreakup.TotalSeatPrice.toFixed(2)}
                                  </Typography>
                                </Grid>
                              </Grid>
                              {/* <Typography align="right">&#x20B9; {fareBreakup.passengerCount} X{" "}
                        {fareBreakup.TotalSeatPrice /
                          fareBreakup.passengerCount} = {fareBreakup.TotalSeatPrice.toFixed(2)}</Typography> */}
                            </Grid>
                          </Grid>

                          <Grid
                            style={{
                              display: "flex",
                              flexDirection: "row",
                            }}
                          >
                            <Grid item md={6} xs={6}>
                              <Typography
                                style={{
                                  color: theme.palette.primary.blackPearl,
                                  fontWeight: 500,
                                  fontSize: 14,
                                }}
                              >
                                Other Fare
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              md={6}
                              xs={6}
                              // style={{ marginRight: 10 }}
                            >
                              <Grid container>
                                <Grid item md={7} xs={8}>
                                  <Typography
                                    align="right"
                                    style={{
                                      fontFamily: "Roboto",
                                      color: theme.palette.primary.blackPearl,
                                      fontWeight: 500,
                                      fontSize: 14,
                                    }}
                                  >
                                    {fareBreakup.passengerCount} X{" "}
                                    {fareBreakup.TotalSupplierCharge.toFixed(
                                      2
                                    ) / fareBreakup.passengerCount}{" "}
                                    =
                                  </Typography>
                                </Grid>

                                <Grid item md={5} xs={4}>
                                  <Typography
                                    align="right"
                                    style={{
                                      fontFamily: "Roboto",
                                      color: theme.palette.primary.blackPearl,
                                      fontWeight: 500,
                                      fontSize: 14,
                                    }}
                                  >
                                    {fareBreakup.TotalSupplierCharge.toFixed(2)}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>

                          {addDisCoup && 
                            <Grid
                              style={{
                                display: "flex",
                                flexDirection: "row",
                              }}
                            >
                              <Grid item md={12}>
                                <Typography style={{ color: theme.palette.primary.blackPearl }}>
                                  {" "}
                                  Discount:
                                </Typography>
                              </Grid>
                              <Grid item style={{ display: "flex", alignItems: "center" }}>
                                <Typography style={{ color: theme.palette.primary.blackPearl }}>
                                  {parseFloat(couponObj?.voucher_value).toFixed(2)}
                                </Typography>
                                <span style={{ cursor: "pointer", color: "red", marginLeft: 5, paddingBottom: 3.5 }}
                                  onClick={removeCoupon}
                                  title="remove discount"
                                >
                                  x
                                </span>
                              </Grid>
                            </Grid>
                          }
                        </Grid>
                        {/* <Grid
                          item
                          md={12}
                          xs={12}
                          style={{
                            marginTop: 20,
                            marginBottom: 10,
                          }}
                          // className="total-payable"
                        >
                          <Divider />
                        </Grid> */}
                        <Box
                          style={{
                            background: theme.palette.background.disabled,
                            padding: "10px 15px",
                            borderBottom: `none`,
                            flexDirection: "row",
                            display: "flex",
                            width: "100%",
                          }}
                        >
                          <Grid item md={6} xs={6}>
                            <Typography style={{ fontWeight: 500 }}>
                              Total
                            </Typography>
                          </Grid>
                          <Grid item md={6} xs={6}>
                            <Typography
                              align="right"
                              style={{ fontFamily: "Roboto", fontWeight: 500 }}
                            >
                              &#x20B9;{" "}
                              {totalPayable}
                            </Typography>
                          </Grid>
                        </Box>
                      </Grid>
                    )}
                  </Grid>

                  {discountCoupons && discountCoupons.length > 0 &&
                    <Grid className="section-container">
                      <Grid item md={12} sm={12} xs={12}>
                        <div
                          className="secton-header"
                        >
                          <TitleComp fa="fa fa-gift" title={`Offers`} />
                        </div>
                      </Grid>
                      <Grid item md={12} sm={12} xs={12} className="offer-coupons-search">
                        <OaDiscountCouponSearch
                          applyDiscountCoupon={applyCoupon}
                          couponStatus={couponStatus}
                          setCouponStatus={setCouponStatus}
                          service="Bus"
                        />
                      </Grid>
                      <Grid item md={12} sm={12} xs={12} className="offer-coupons-section" style={{ marginBottom: openPromo ? 0 : 5 }}>
                        <div
                          className={classes.promoCollapseToggle}
                          onClick={() => setOpenPromo(!openPromo)}
                        >
                          <Typography
                            style={{ fontSize: 15, marginRight: 10 }}
                          >
                            PROMO CODE
                          </Typography>
                          <span>
                            <i
                              className={
                                openPromo
                                  ? "fas fa-chevron-up"
                                  : "fas fa-chevron-down"
                              }
                              style={{ marginTop: 6, marginLeft: 10 }}
                            ></i>
                          </span>
                        </div>
                      </Grid>
                      <Grid item md={12} sm={12} xs={12} className="offer-coupons-section">
                        <Collapse in={openPromo}>
                          {discountCoupons?.map((item, index) => (item.couponDisplay === "auto" &&
                            <Coupons
                              item={item}
                              key={index}
                              applycoupon={(val) => applyCoupon(val)}
                              selectedcoupon={couponObj}
                              {...props}
                            />
                          ))}
                        </Collapse>
                      </Grid>
                    </Grid>
                  }
                </Grid>
              </Grid>

              <Grid
                container
                className="bus-payment-details"
                style={{ marginTop: isMobile && 20 }}
              >
                {/* <Grid item md={12}>
                  <Divider style={{ marginTop: 20, marginBottom: 10 }} />
                </Grid> */}
                {/* <Grid item md={12} style={{ paddingBottom: 5 }}>
                  <span className="heading-text">PAYMENT DETAILS</span>
                </Grid> */}

                <Grid
                  className="section-container"
                  style={{ padding: 15, width: "100%" }}
                >
                  <Grid
                    item
                    md={12}
                    style={{
                      margin: isMobile && "10px 0px 23px 0",
                      padding: 15,
                    }}
                    className="section-contentContainer2"
                  >
                    <Field
                      type="checkbox"
                      name="isGST"
                      id="isGST"
                      className={
                        "form-check-input " +
                        (errors.acceptTerms && touched.acceptTerms
                          ? " is-invalid"
                          : "")
                      }
                    />
                    <label
                      htmlFor="isGST"
                      className="form-check-label payment-info-title"
                      style={{ marginLeft: isMobile && 13 }}
                    >
                      GST Details(Note. Please fill GST details only for
                      corparate customer)
                    </label>
                    <ErrorMessage
                      name="isGST"
                      component="div"
                      className="error"
                    />
                    <OaFormikErrorAlert
                      name="isGST"
                      error={props.error}
                      touched={props.touched}
                    />
                  </Grid>

                  {values.isGST && (
                    <Grid
                      item
                      md={12}
                      className="section-contentContainer2"
                      style={{
                        borderTopLeftRadius: 0,
                        borderTopRightRadius: 0,
                      }}
                    >
                      <Grid
                        container
                        style={{
                          paddingTop: 5,
                          paddingBottom: 10,
                          paddingLeft: 10,
                          margin: "13px 0",
                          display: "flex",
                          gap: "13px",
                        }}
                        className="form-group form-check"
                      >
                        <Grid item md={2} xs={12}>
                          <Field
                            // placeholder="GST No"
                            className="passenger-field"
                            as={TextField}
                            fullWidth
                            // placeholder="ID Number"
                            label=" GST NUMBER"
                            variant="outlined"
                            // className="passenger-field"
                            name="gstNo"
                          />
                          <ErrorMessage
                            name="gstNo"
                            className="error"
                            component="div"
                          />
                          <OaFormikErrorAlert
                            name="gstNo"
                            error={props.error}
                            touched={props.touched}
                          />
                        </Grid>
                        <Grid item md={3} xs={12}>
                          <Field
                            // placeholder="Name"
                            variant="outlined"
                            label="COMPANY NAME"
                            as={TextField}
                            fullWidth
                            className="passenger-field"
                            name="gstName"
                          />
                          <ErrorMessage
                            name="gstName"
                            className="error"
                            component="div"
                          />
                          <OaFormikErrorAlert
                            name="gstName"
                            error={props.error}
                            touched={props.touched}
                          />
                        </Grid>
                        <Grid item md={3} xs={12}>
                          <Field
                            // placeholder="Address"
                            label="COMPANY ADDRESS"
                            as={TextField}
                            fullWidth
                            variant="outlined"
                            className="passenger-field"
                            name="gstAddress"
                          />
                          <ErrorMessage
                            name="gstAddress"
                            className="error"
                            component="div"
                          />
                          <OaFormikErrorAlert
                            name="gstAddress"
                            error={props.error}
                            touched={props.touched}
                          />
                        </Grid>
                        <Grid item md={2} xs={12}>
                          <Field
                            // placeholder="Contact"
                            label="CONTACT NUMBER"
                            as={TextField}
                            fullWidth
                            variant="outlined"
                            className="passenger-field"
                            name="gstMobile"
                          />
                          <ErrorMessage
                            name="gstMobile"
                            className="error"
                            component="div"
                          />
                          <OaFormikErrorAlert
                            name="gstMobile"
                            error={props.error}
                            touched={props.touched}
                          />
                        </Grid>
                        <Grid item md={2} xs={12}>
                          <Field
                            // placeholder="Email@compny.com"
                            // placeholder="Contact"
                            as={TextField}
                            fullWidth
                            label="COMPANY EMAIL"
                            variant="outlined"
                            // className="passenger-field"
                            name="gstEmail"
                          />
                          <ErrorMessage
                            name="gstEmail"
                            className="error"
                            component="div"
                          />
                          <OaFormikErrorAlert
                            name="gstEmail"
                            error={props.error}
                            touched={props.touched}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  )}

                  {/* <Grid item md={12}>
                  <Divider style={{ marginTop: 20, marginBottom: 10 }} />
                </Grid> */}
                </Grid>
                <Grid
                  className="section-container"
                  style={{
                    padding: "20px 5px 10px 10px",
                    paddingBottom: 15,
                    width: "100%",
                    marginTop: 0,
                  }}
                >
                  <BusPaymentOptions
                    paymentmodes={paymentModes}
                    setselectedpaymentmode={setSelectedPaymentMode}
                    isloading={isLoading}
                    data={data}
                    ticketfare={totalPayable}
                    errors={errors}
                    touched={touched}
                    setisloading={setIsLoading}
                    selectedpaymentmethod={selectedPaymentMethod}
                    setselectedpaymentmethod={setSelectedPaymentMethod}
                    paymodetabindex={payModeTabIndex}
                    setpaymodetabindex={setPayModeTabIndex}
                    setIsPayable={setIsPayable}
                  />
                </Grid>

                <Grid item md={12} style={{ display: "none" }}>
                  <Divider style={{ marginTop: 20, marginBottom: 10 }} />
                </Grid>
                <Grid item md={12} style={{ marginTop: 20, display: "none" }}>
                  <span className="text-danger">Note</span>
                </Grid>

                <Grid item md={12} style={{ display: "none" }}>
                  <Divider style={{ marginTop: 10, marginBottom: 10 }} />
                </Grid>

                <Grid item md={12} style={{ display: "none" }}>
                  <span className="payment-info-title">
                    Buses may change their prices any minute. This fare is not
                    guaranteed until the Bus PNR is generated. Generation of a
                    Transaction ID (TID) is the confirmation of booking request
                    and payment received by {AppConstant.domainName} and under
                    no circumstances, is it equivalent to{" "}
                    {AppConstant.domainName}' final purchase price of respective
                    Bus ticket. There might be situations, where the fare
                    displayed may increase by the time purchase is made with the
                    respective Bus. In this event, {AppConstant.domainName} will
                    revert to you with the new fare or alternate options
                    regarding your bus booking.
                  </span>
                </Grid>
                <Grid item md={12} style={{ display: "none" }}>
                  <span
                    style={{
                      color: theme.palette.primary.redAlert,
                      fontSize: "1rem",
                      fontWeight: 400,
                    }}
                  >
                    *There will be a TDS on the discounts / commissions on every
                    transaction @ 5% for Permanent Account Number (PAN) holders
                    and 20% if the agent is not having a PAN. Kindly update your
                    PAN to save 15% on TDS.
                  </span>
                </Grid>

                {/* <Grid item md={12}>
                  <Divider style={{ marginTop: 10, marginBottom: 10 }} />
                </Grid> */}

                <Grid
                  className="section-container"
                  style={{ padding: 8, width: "100%" }}
                >
                  <Grid item md={12} className="form-group form-check">
                    <div className="checkbox-group">
                      <Checkbox
                        style={{ color: theme.palette.background.carulean }}
                        value={values.acceptTNC}
                        name="acceptTerms"
                        onChange={() =>
                          formikRef.current.setFieldValue(
                            "acceptTerms",
                            !values.acceptTerms
                          )
                        }
                      />
                      <label className="form-check-label payment-info-title">
                        I have read and accepted{" "}
                        <Link
                          style={{ color: theme.palette.primary.redAlert }}
                          onClick={handleTncOpen}
                        >
                          terms and conditions
                        </Link>{" "}
                        and{" "}
                        <Link
                          style={{ color: theme.palette.primary.redAlert }}
                          onClick={handleOpenFareRules}
                        >
                          Fare Rules
                        </Link>
                      </label>
                    </div>
                    <ErrorMessage
                      name="acceptTerms"
                      component="div"
                      className="error"
                    />
                  </Grid>
                </Grid>

                {/* <Grid item md={12}>
                  <Divider style={{ marginTop: 10, marginBottom: 10 }} />
                </Grid> */}

                <Grid item md={12} align="center" xs={12}>
                  {!isLoading &&
                    /*data.balance >= ticketFare*/ (walletCriteria ||
                      userType === "b2c_user") && (
                      <Button
                        variant="contained"
                        className={classes.reviewButton}
                        align="center"
                        type="submit"
                      >
                        Review
                      </Button>
                    )}
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
        <Dialog
          fullWidth
          maxWidth={"lg"}
          open={openTNC}
          onClose={handleCloseTNC}
        >
          <DialogTitle>
            <div className="modal-close">
              <span className="icon-holder" onClick={handleCloseTNC}>
                <Close />
              </span>
            </div>
          </DialogTitle>
          <DialogContent>
            <div className="content-container-inner">
              <props.tnc />
            </div>
          </DialogContent>
        </Dialog>

        <Dialog
          fullWidth
          maxWidth={"lg"}
          open={openFareRules}
          onClose={handleCloseFareRules}
        >
          <DialogTitle>
            <div className="modal-close">
              <span className="icon-holder" onClick={handleCloseFareRules}>
                <Close />
              </span>
            </div>
          </DialogTitle>
          <DialogContent>
            <div className="content-container-inner">
              <props.ppc />
            </div>
          </DialogContent>
        </Dialog>
      </div>
      <style>{`
      .modal-close {
        display: flex;
        justify-content: flex-end;
        align-items: flex-start;
      }
      .modal-close .icon-holder {
        cursor: pointer;
      }
      `}</style>
    </>
  );
};

export default BusPassengerDetails;

export class PassengerObj {
  constructor(seat, title, firstName, lastName, age, isLead) {
    this.Seat = seat;
    this.Title = seat.IsLadiesSeat ? "Mrs" : seat.IsMalesSeat ? "Mr" : title;
    this.FirstName = firstName;
    this.LastName = lastName;
    this.Age = age;
    this.LeadPassenger = isLead;
  }
}

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiInputBase-root": {
      lineHeight: "0.1876em",
    },
    "& .MuiInputBase-input": {
      backgroundColor: theme.palette.background.light,
    },
    "& .MuiSelect-outlined.MuiSelect-outlined": {
      lineHeight: "17px",
    },
    "& .MuiBox-root": {
      padding: 0,
    },
    "& .left-side-passenger-detail": {
      // padding: "10px 35px",
      // marginTop: 10,
      [theme.breakpoints.down(760)]: {
        padding: "15px 0 0",
      },
    },
    "& .bus-payment-details": {
      width: "66%",
      [theme.breakpoints.down(960)]: {
        width: "100%",
      },
    },
    "& .section-container": {
      background: theme.palette.background.light,
      borderRadius: "0.25rem",
      border: "1px solid rgba(0,0,0,0.125)",
      boxShadow: "0 .125rem .25rem rgba(0,0,0,0.075)!important",
      marginBottom: 10,
      "& .offer-coupons-search": {
        padding: "10px 10px 0"
      },
      "& .offer-coupons-section": {
        padding: "5px 20px 0"
      }
    },
    "& .section-contentContainer": {
      padding: 15,
      // margin: 15,
      // backgroundColor: theme.palette.background.rgba_primary,
      // borderTop: "1px solid rgba(0,0,0,0.125)",
      "& .clockContainer": {
        justifyContent: "center",
        [theme.breakpoints.down(400)]: {
          justifyContent: "center",
        },
      },
      "& .clockTextContainer": {
        display: "flex",
        justifyContent: "center",
      },
      "& .clockText": {
        marginLeft: -10,
        // textAlign: "center",
        [theme.breakpoints.down(400)]: {
          marginLeft: 0,
        },
      },
      "& .travellingPoint": {
        [theme.breakpoints.down(360)]: {
          width: "44%",
        },
      },
    },
    "& .section-contentContainer2": {
      backgroundColor: theme.palette.background.lightGrayDefault,
      padding: 7,
      borderRadius: 5,
    },
    "& .secton-header": {
      padding: "5px 15px",
      borderBottom: "1px solid rgba(0,0,0,0.125)",
    },
    "& .add-remove-button": {
      [theme.breakpoints.down(760)]: {
        visibility: "hidden",
      },
    },
    "& .section-title-wrapper": {
      display: "flex",
      alignItems: "center",
    },
    "& .heading-icon": {
      borderRadius: "50%",
      background: theme.palette.background.disabled,
      width: 30,
      height: 30,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginRight: 10,
      "& i": {
        fontSize: 16,
        color: theme.palette.text.secondary,
      },
    },
    "& .heading-text": {
      textTransform: "uppercase",
      fontWeight: 500,
      color: theme.palette.text.secondary,
    },
    "& .title-text": {
      margin: 0,
    },
    "& .MuiPopover-paper": {
      top: "366px !imported",
    },
    "& .form-check-input ": {
      cursor: "pointer",
    },
    "& .MuiLink-root": {
      cursor: "pointer",
    },
    "& .modal-close": {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "flex-start",
    },
    "& .checkbox-group": {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
    },

    "& .error-msgBox": {
      width: "60%",
      // height:'100vh',
      bottom: 0,
      position: "fixed",
      // position:'absolute',
      top: "10%",
      left: "20%",
      padding: "6px 13px",
      zIndex: 999,
      margin: "10px 0",
      "& .error-container": {
        position: "relative",
        width: "100%",
        padding: "6px 13px",
        margin: "10px 0",
      },
    },
  },

  Heading: {
    fontSize: 20,
    color: theme.palette.primary.blackPearl,
    lineHeight: "36px",

    fontWeight: 500,
    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
    },
  },
  boardingHeading: {
    lineHeight: "20px",
    color: theme.palette.primary.blackPearl,
    fontSize: 15,
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
    },
    [theme.breakpoints.down(420)]: {
      fontSize: 9,
    },
  },
  passengerDetail: {
    fontSize: 16,
    fontWeight: 500,
    color: theme.palette.primary.blackPearl,
  },
  busDetails: {
    fontWeight: 500,
    fontSize: 16,
    color: theme.palette.primary.blackPearl,
    lineHeight: "30px",
  },
  coachName: {
    fontWeight: 300,
    fontSize: 12,
    lineHeight: "30px",
    color: theme.palette.primary.lightGreen,
    // textAlign: "center"
  },
  busName: {
    fontSize: 14,
    color: theme.palette.primary.blackPearl,
    lineHeight: "16px",
  },
  paymentTitle: {
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "17px",
    display: "flex",
    alignItems: "center",
    background: theme.palette.secondary.mustardYellow,
    width: "150px",
    color: theme.palette.secondary.black,
    padding: "10px",
    borderTopRightRadius: "10px",
  },
  reviewButton: {
    fontWeight: "500 !important",
    fontSize: "18px !important",
    lineHeight: "20px !important" /* identical to box height */,
    letterSpacing: "1px !important",
    color: `${theme.palette.primary.lightText} !important`,
    background: `${theme.palette.buttons.tertiary}!important`,
    borderRadius: "4px !important",
    textTransform: "capitalize !important",
    cursor: "pointer",
    minWidth: "240px !important",
    height: "47px",
    padding: "6px 15px",
    [theme.breakpoints.down("sm")]: {
      marginTop: 20,
    },
  },
  modifyButton: {
    letterSpacing: "1px !important",
    color: `${theme.palette.primary.lightText} !important`,
    background: `${theme.palette.buttons.tertiary}!important`,
    borderRadius: "4px !important",
    textTransform: "capitalize !important",
    cursor: "pointer",
    minWidth: "130px",
    [theme.breakpoints.down(760)]: {
      minWidth: 0,
    },
    height: "30px",
    // padding: "6px 15px",
  },
  tabDiv: {
    "& .MuiTab-textColorInherit": {
      background: `${theme.palette.primary.disabled} !important`,
      color: `${theme.palette.primary.darkText} !important`,
      borderRadius: "8px 8px 0 0",
      "&.Mui-selected": {
        background: `${theme.palette.background.primary} !important`,
        color: `${theme.palette.primary.lightText} !important`,
      },
    },
  },
  tabContDiv: {},
  "& .section-title-wrapper": {
    display: "flex",
    alignItems: "center",
  },
  "& .heading-icon": {
    borderRadius: "50%",
    background: theme.palette.background.disabled,
    width: 30,
    height: 30,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: 10,
    "& i": {
      fontSize: 16,
      color: theme.palette.text.secondary,
    },
  },
  "& .heading-text": {
    textTransform: "uppercase",
    fontWeight: 500,
    color: theme.palette.text.secondary,
  },
  promoCollapseToggle: { 
    display: "flex", 
    flexWrap: "nowrap", 
    width: "100%", 
    justifyContent: "space-between", 
    cursor: "pointer" 
  }
}));

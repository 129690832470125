import React, { useEffect, useState, useRef, useContext } from "react";
import {
  Box,
  makeStyles,
  Grid,
  TextField,
  Button,
  Fade,
  Backdrop,
  Modal,
  withWidth,
  Typography
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import RoomModal from "./RoomModal";

import OaFormAlerts from "pages/components/OaFormAlerts";
import WebApi from "api/ApiConstants";
import { apiCall } from "oautils/oaDataUtils";

import * as Yup from "yup";
import { Formik, Form, ErrorMessage, FieldArray } from "formik";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setHotelCountry, setHotelCity, setHotelSearch } from "redux/action";
import _ from "lodash";
import differenceInDays from "date-fns/differenceInDays";
import dateFnsFormat from "date-fns/format";
import { AppStateContext } from "layouts/AppStateProvider";
import { localforageGetItem } from "oautils/oaForageUtils";

export class RoomObj {
  constructor() {
    this.noOfAdults = 1;
    this.noOfChild = 0;
    this.childAge = [];
  }
}

function HotelSection(props) {
  const classes = useStyles();

  const locationAutocompleteRef = useRef(null);
  const [departureDateDialogOpen, setDepartureDateDialogOpen] = useState(false);
  const [arrivalDateDialogOpen, setArrivalDateDialogOpen] = useState(false);

  const [isSaving, setIsSaving] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [infoText, setInfoText] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [roomModalOpen, setRoomModalOpen] = useState(false);
  const formikRef = React.useRef();
  const { setSecLogo } = useContext(AppStateContext);
  const [refreshLocation, setRefreshLocation] = useState(false);
  const [userType, setUserType] = useState(null);
  const [corporateType, setCorporateType] = useState(null);

  let baseUrl = "/";

  let optionsHotel = [
    {
      name: "searchHotel",
      label: <Typography className={classes.typo1}>Search Hotel</Typography>,
      url: baseUrl + "hotels",
      icon: <i className="fas fa-search"></i>,
    },
    {
      name: "hotelReport",
      label: <Typography className={classes.typo1}>Hotel Report</Typography>,
      url: "/admin/booking-report/hotel",
      icon: <i className="fas fa-dollar-sign"></i>,
    },
  ];

  const popularCities = [
    { "cityName": "Delhi", "countryCode": "IN", "countryName": "India", "destinationId": "130443", "hotelName": null, "stateProvince": "DL", "type": null },
    { "cityName": "Navi Mumbai", "countryCode": "IN", "countryName": "India", "destinationId": "128734", "hotelName": null, "stateProvince": "MH", "type": null },
    { "cityName": "Bengaluru", "countryCode": "IN", "countryName": "India", "destinationId": "111124", "hotelName": null, "stateProvince": "KA", "type": null },
    { "cityName": "Goa", "countryCode": "IN", "countryName": "India", "destinationId": "119805", "hotelName": null, "stateProvince": "GA", "type": null },
    { "cityName": "Chennai", "countryCode": "IN", "countryName": "India", "destinationId": "127343", "hotelName": null, "stateProvince": "TN", "type": null },
    { "cityName": "Jaipur", "countryCode": "IN", "countryName": "India", "destinationId": "122175", "hotelName": null, "stateProvince": "RJ", "type": null }
  ];

  useEffect(() => {
    localforageGetItem("user-details", function (err, details) {
      if (details != null) {
        setUserType(details.userType);
        setCorporateType(details.corporateType);
      }
    });
    props.setHotelCity(popularCities);
  }, []);

  useEffect(() => {
    if (isLoading) {
      // console.log(0);
      const length = props.hotelCountry
        ? Object.keys(props.hotelCountry).length
        : 0;

      const lengthCity = props.hotelCity
        ? Object.keys(props.hotelCity).length
        : 0;

      if (length > 0 && lengthCity > 0) {
        const countryCodeObj = {
          name: props.hotelCountry?.filter(
            (country) => country.code == props.hotelCity[0].countryCode
          )[0].name,
          code: props.hotelCity[0].countryCode,
        };

        formikRef.current.setFieldValue("countryCode", countryCodeObj.code);
        formikRef.current.setFieldValue("countryCodeObj", countryCodeObj);
        formikRef.current.setFieldValue("guestNationality", "IN");
        formikRef.current.setFieldValue("guestNationalityObj", countryCodeObj);

        setIsLoading(false);
      } else {
        // console.log(1);
        apiCall(WebApi.getHotelCountry, {}, (response) => {
          if (response.success === true) {
            setIsLoading(false);
            props.setHotelCountry(response.data.countryList.country);
            // setHotelCountry(response.data.countryList.country);
            // formikRef.current.setFieldValue("countryCode", {
            //   name: "India",
            //   code: "IN",
            // });
            // formikRef.current.setFieldValue("guestNationality", {
            //   name: "India",
            //   code: "IN",
            // });
            cityHotelChangeHandler(1, {
              name: "India",
              code: "IN",
            });
          } else {
            setIsError(!response.success);
            setInfoText(response.message);
          }
        });
      }
    }
  }, [isLoading]);

  const cityHotelChangeHandler = (searchType, obj) => {
    let countryCode = "";
    if (obj != null) {
      countryCode = obj.code;
    } else {
      const countryCodeObj = formikRef.current.values.countryCode;
      countryCode = countryCodeObj?.code;
    }

    // console.log(
    //   "################################ cityHotelChangeHandler ##################################"
    // );

    apiCall(
      WebApi.getHotelCity,
      {
        searchType: searchType,
        countryCode: countryCode || "IN",
      },
      (response) => {
        if (response.success === true) {
          // props.setHotelCity(response.data.destinations);
          //   setHotelCity(response.data.destinations);
          // setRefreshLocation(true);
          // console.log(
          //   "################################ countryCode change response ##################################",
          //   response.data.destinations
          // );
        } else {
          setIsError(!response.success);
          setInfoText(response.message);
        }
      }
    );
  };

  const fetchMatchingCities = (searchType, searchParam, countryCode) => {

    if (searchParam.length < 3) { return; }

    apiCall(
      WebApi.getMatchingHotelCity, {
      searchType: searchType,
      countryCode: countryCode.code || "IN",
      hotelCity: searchParam,
    }, (response) => {
      if (response.success === true) {
        // console.log("hotel city search", searchParam);
        // console.log("matching cities response", response);
        props.setHotelCity(response.data.destinations);
      } else {
        setIsError(!response.success);
        setInfoText(response.message);
      }
    }
    );
  };

  const openQuickLink = (url) => {
    // console.log("quicklink", url);
    props.history.push({
      pathname: url,
    });
  }

  return (
    <Box
      className={classes.root}
      style={{ overflow: roomModalOpen && "hidden" }}
    >
      <OaFormAlerts
        isSaving={isSaving}
        isSuccess={isSuccess}
        isError={isError}
        infoText={infoText}
        setIsError={setIsError}
        setIsSuccess={setIsSuccess}
      />
      <Formik
        innerRef={formikRef}
        initialValues={{
          countryCodeObj: {
            name: "India",
            code: "IN",
          },
          countryCode: "IN",
          cityObj: popularCities[0],
          cityId: popularCities[0].destinationId,
          checkInDate: dateFnsFormat(new Date(), "dd/MM/yyyy"),
          checkInDateDisplay: new Date(),
          checkOutDate: dateFnsFormat(new Date(new Date().setDate(new Date().getDate() + 1)), "dd/MM/yyyy"),
          checkOutDateDisplay: new Date(new Date().setDate(new Date().getDate() + 1)),

          preferredCurrency: "INR",
          roomGuests: [new RoomObj()],
          guestNationality: "IN",
          guestNationalityObj: {
            name: "India",
            code: "IN",
          },
        }}
        validationSchema={Yup.object().shape({
          countryCode: Yup.string().required("country Field is Required"),
          cityId: Yup.string().required("Location Field is Required"),
          checkInDate: Yup.string().required("Check In Date Field is Required"),
          checkOutDate: Yup.string().required(
            "Check Out Date Field is Required"
          ),
          // preferredCurrency: Yup.string().required(
          //   "Preferred Currency Field is Required"
          // ),
          guestNationality: Yup.string().required(
            "Guest Nationality Field is Required"
          ),
        })}
        onSubmit={(values) => {
          // values.countryCode = values.countryCode.code;
          // const cityObj = values.cityId;
          // values.cityId = cityObj.destinationId;
          values.city = values.cityObj.cityName;
          // values.guestNationality = values.guestNationality.code;

          values.noOfNights = differenceInDays(
            values.checkOutDate,
            values.checkInDate
          );
          values.noOfNights = values.noOfNights > 0 ? values.noOfNights : 1;
          // values.checkInDate = dateFnsFormat(values.checkInDate, "dd/MM/yyyy");
          // values.checkOutDate = dateFnsFormat(
          //   values.checkOutDate,
          //   "dd/MM/yyyy"
          // );
          values.noOfRooms = values.roomGuests.length;

          //  searchParams.resultCount = 10;
          values.maxRating = 5;
          values.minRating = 0;
          values.isTBOMapped = true;
          //  searchParams.isTBOMapped = 1;
          //  searchParams.isCompactdata = 1;

          props.setHotelSearch(values);
          setSecLogo(true);
          props.history.push("/hotel/search");
        }}
      >
        {({ values, setFieldValue, errors, touched }) => (
          <Form className={classes.root1}>
            <Grid
              container
              direction="column"
              justifyContent="flex-start"
              alignItems="stretch"
            >
              <Box className="hotel-section-root-box">
                <Box className={classes.HotelSection_itinDet}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-start"
                    spacing={2}
                  >
                    <Grid item xs={10} sm={5} md={3} className="location-input">
                      <Autocomplete
                        name={`countryCodeObj`}
                        options={props.hotelCountry || []}
                        getOptionLabel={(a) => a.name || ""}
                        value={values.countryCodeObj}
                        getOptionSelected={(option, value) =>
                          option.value == value.code
                        }
                        onChange={
                          (_, newValue) => {
                            setFieldValue("countryCodeObj", newValue);
                            setFieldValue("countryCode", newValue.code);
                            cityHotelChangeHandler(1, newValue);
                            props.setHotelCity([]);
                            setFieldValue("cityId", "");
                            setFieldValue("guestNationalityObj", newValue);
                            //setFieldValue("guestNationality", newValue.code);
                          }
                          // setData((prevState) => ({
                          //   ...prevState,
                          //   country: newValue,
                          // }))
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            color="secondary"
                            label="Country"
                          />
                        )}
                        disablePortal={true}
                      />

                      <ErrorMessage
                        name={`countryCode`}
                        component="div"
                        className="error"
                      />
                    </Grid>
                    {/* <Grid item xs={0} sm={2} style={{ textAlign: "center" }}>
                .
              </Grid> */}
                    <Grid item xs={10} sm={5} md={3} className="location-input">
                      <Autocomplete
                        name={`cityObj`}
                        options={props.hotelCity || []}
                        getOptionLabel={(a) =>
                          a &&
                          a.cityName +
                          ", " +
                          a.stateProvince +
                          ", " +
                          a.countryCode

                        }
                        value={values.cityObj}
                        getOptionSelected={(option, value) =>
                          option.value == value.destinationId
                        }
                        onKeyUp={event => fetchMatchingCities(1, event.target.value, values.countryCode)}
                        onChange={(_, newValue) => {
                          setFieldValue("cityObj", newValue);
                          setFieldValue("cityId", newValue.destinationId);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            color="secondary"
                            label="Location"
                            inputRef={locationAutocompleteRef}
                            inputProps={{
                              ...params.inputProps,
                              autocomplete: "new-password",
                            }}
                          />
                        )}
                        openOnFocus
                        disablePortal={true}
                      />

                      <ErrorMessage
                        name={`cityId`}
                        component="div"
                        className="error"
                      />
                    </Grid>

                    <Grid item xs={10} sm={5} md={3} className="date-input">
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          name="checkInDateDisplay"
                          className={classes.checkinDatePicker}
                          fullWidth
                          disablePast
                          color="secondary"
                          disableToolbar
                          variant="inline"
                          format="dd-MM-yyyy"
                          margin="normal"
                          label="Check In Date"
                          value={values.checkInDateDisplay}
                          onChange={(e, v) => {
                            setFieldValue("checkInDateDisplay", e);
                            setFieldValue("checkInDate", dateFnsFormat(e, "dd/MM/yyyy"));
                            setFieldValue("checkOutDateDisplay", new Date(e.getTime() + 86400000));
                            setFieldValue("checkOutDate", dateFnsFormat(new Date(e.getTime() + 86400000), "dd/MM/yyyy"));
                            setDepartureDateDialogOpen(false);
                            setArrivalDateDialogOpen(true);
                          }}
                          KeyboardButtonProps={{
                            onFocus: (e) => {
                              setDepartureDateDialogOpen(true);
                            },
                          }}
                          PopoverProps={{
                            disableRestoreFocus: true,
                            onClose: () => {
                              setDepartureDateDialogOpen(false);
                            },
                          }}
                          InputProps={{
                            onFocus: () => {
                              setDepartureDateDialogOpen(true);
                            },
                          }}
                          open={departureDateDialogOpen}
                          onClose={() => setDepartureDateDialogOpen(false)}
                          onOpen={() => setDepartureDateDialogOpen(true)}
                        />
                      </MuiPickersUtilsProvider>

                      <ErrorMessage
                        name={`checkInDate`}
                        component="div"
                        className="error"
                      />
                    </Grid>

                    <Grid item xs={10} sm={5} md={3} className="date-input">
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          name="checkOutDateDisplay"
                          className={classes.checkoutDatePicker}
                          fullWidth
                          minDate={
                            new Date(
                              new Date(values.checkInDateDisplay).getTime() + 86400000
                            )
                          }
                          color="secondary"
                          disableToolbar
                          variant="inline"
                          format="dd-MM-yyyy"
                          margin="normal"
                          label="Check Out Date"
                          value={values.checkOutDateDisplay}
                          onChange={(e, v) => {
                            setFieldValue("checkOutDateDisplay", e);
                            setFieldValue("checkOutDate", dateFnsFormat(e, "dd/MM/yyyy"));
                            setArrivalDateDialogOpen(false);
                          }}
                          KeyboardButtonProps={{
                            onFocus: (e) => {
                              setArrivalDateDialogOpen(true);
                            },
                          }}
                          PopoverProps={{
                            disableRestoreFocus: true,
                            onClose: () => {
                              setArrivalDateDialogOpen(false);
                            },
                          }}
                          InputProps={{
                            onFocus: () => {
                              setArrivalDateDialogOpen(true);
                            },
                          }}
                          open={arrivalDateDialogOpen}
                          onClose={() => setArrivalDateDialogOpen(false)}
                          onOpen={() => setArrivalDateDialogOpen(true)}
                        />
                      </MuiPickersUtilsProvider>

                      <ErrorMessage
                        name={`checkOutDate`}
                        component="div"
                        className="error"
                      />
                    </Grid>
                  </Grid>
                </Box>

                <Box className={classes.HotelSection_idenDet}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-start"
                    spacing={2}
                  >
                    <Grid item xs={10} sm={5} md={3} className="location-input">
                      <Autocomplete
                        name="guestNationalityObj"
                        options={props.hotelCountry || []}
                        getOptionLabel={(a) => a.name || ""}
                        value={values.guestNationalityObj}
                        getOptionSelected={(option, value) =>
                          option.value == value.code
                        }
                        onChange={(_, newValue) => {
                          // setData((prevState) => ({
                          //   ...prevState,
                          //   nationality: newValue,
                          // }))

                          setFieldValue("guestNationalityObj", newValue);
                          //     setFieldValue("guestNationality", newValue.code);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            color="secondary"
                            label="Nationality"
                          />
                        )}
                        disablePortal={true}
                      />

                      <ErrorMessage
                        name={`guestNationality`}
                        component="div"
                        className="error"
                      />
                    </Grid>
                    {/* <Grid item xs={2} sm={0}>
                .
              </Grid> */}
                    <Grid item xs={10} sm={5} md={3} className="location-input">
                      <TextField
                        label="Rooms"
                        color="secondary"
                        value={`${values.roomGuests.length} ${values.roomGuests.length == 1 ? "Room" : "Rooms"
                          },  ${values.roomGuests.reduce(function (a, b) {
                            return (
                              Number(a) +
                              Number(b.noOfAdults) +
                              Number(b.noOfChild)
                            );
                          }, 0)} Guests`}
                        fullWidth
                        onClick={() => setRoomModalOpen(true)}
                      />
                    </Grid>

                    <Grid item xs={10} sm={5} md={3}></Grid>

                    <Grid item xs={10} sm={5} md={3}>
                      <Box className={classes.HotelSection_actions} my={2} mx={4}>
                        <Grid
                          container
                          direction="row"
                          // justifyContent="center"
                          justifyContent={props.width === "xs" ? "center" : "flex-end"}
                          alignItems="center"
                        >
                          <Box className={classes.searchButtonBox}>
                            <Button
                              size="large"
                              variant="contained"
                              color="primary"
                              type="submit"
                            >
                              Search
                            </Button>
                          </Box>
                        </Grid>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>

                {userType && userType == "agent" && corporateType && corporateType != "corporate" &&
                  <Box className={classes.HotelSection_quicklinks}>
                    {optionsHotel && optionsHotel.map((val, ind) => (
                      <div className="quick-link-item" key={ind} onClick={() => openQuickLink(val.url)}>
                        <div className="qlink-icon-wrapper">
                          {val.icon}
                        </div>
                        <div className="qlink-label-wrapper">{val.label}</div>
                      </div>
                    ))}
                  </Box>}
              </Box>

            </Grid>

            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              className={classes.roomModal}
              open={roomModalOpen}
              onClose={() => setRoomModalOpen(false)}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <Fade in={roomModalOpen}>
                <div className={classes.paper}>
                  <RoomModal
                    values={values}
                    setFieldValue={setFieldValue}
                    rooms={values.roomGuests}
                    closeModal={() => setRoomModalOpen(false)}
                  />
                </div>
              </Fade>
            </Modal>

            {/* {console.log("values: ", values)}
            {errors && _.isString(errors) && touched && _.isArray(touched) && (
              <div className="field-error">{errors}</div>
            )}
            <div
              className={"row"}
              style={{ background: "white", zIndex: "200" }}
            >
              <div className={"col-12"}>
                <code>
                  <pre>Values: {JSON.stringify(values, null, 2)}</pre>
                </code>
              </div>
              <div className={"col-12"}>
                <pre>Errors: {JSON.stringify(errors, null, 2)}</pre>
              </div>
              <div className={"col-12"}>
                <pre>Touched: {JSON.stringify(touched, null, 2)}</pre>
              </div>
            </div> */}
          </Form>
        )}
      </Formik>
    </Box>
  );
}

function mapStateToProps(state, props) {
  return {
    hotelCountry: state.hotel.hotelCountry,
    hotelCity: state.hotel.hotelCity,
    hotelSearch: state.hotel.hotelSearch,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setHotelCountry: (hotelCountry) => dispatch(setHotelCountry(hotelCountry)),
    setHotelCity: (hotelCity) => dispatch(setHotelCity(hotelCity)),
    setHotelSearch: (hotelSearch) => dispatch(setHotelSearch(hotelSearch)),
  };
}
export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withWidth()(HotelSection))
);

const countryList = [
  { title: "India", value: "india" },
  { title: "Sweden", value: "sweden" },
  { title: "Japan", value: "japan" },
  { title: "Indonesia", value: "indonesia" },
];
const locationList = [
  { title: "Mumbai", value: "mumbai" },
  { title: "Chennai", value: "chennai" },
  { title: "Kolkata", value: "kolkata" },
  { title: "Delhi", value: "delhi" },
];
const nationalityList = [
  { title: "Indian", value: "indian" },
  { title: "American", value: "american" },
  { title: "European", value: "european" },
  { title: "African", value: "african" },
];
const DefChildCount = 6;

const useStyles = makeStyles(theme => ({
  root: {
    margin: "0",
    padding: "5px 15px",
    "& .MuiFormLabel-root": {
      color: theme.palette.primary.defaultText,
    },
    "& .MuiInputBase-input": {
      color: theme.palette.primary.defaultText,
    },
    "& .MuiSvgIcon-root": {
      color: theme.palette.primary.defaultText,
    },

    "& .MuiGrid-item.MuiGrid-grid-xs-10": {
      margin: "auto",
    },
    "& .MuiInput-underline:before, .MuiInput-underline:after, .MuiInput-underline:hover, .MuiInput-underline:focus": {
      borderBottom: "none",
      [theme.breakpoints.down(600)]: {
        borderBottom: `solid 1px ${theme.palette.primary.darkText} !important`,
      },
    },
    "& .MuiAutocomplete-popupIndicator": {
      display: "none"
    },
    "& .hotel-section-root-box": {
      minWidth: 860,
      [theme.breakpoints.down(960)]: {
        minWidth: "100%",
      }
    },
    "& .MuiGrid-item.MuiGrid-grid-xs-10": {
      [theme.breakpoints.down(440)]: {
        margin: 0,
        maxWidth: "100%",
        flexBasis: "100%",
      },
    },
  },

  roomModal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.light,
    borderRadius: "8px",
  },

  searchButtonBox: {
    width: 80,
    height: 30,
    "& .MuiButton-root": {
      width: "100%",
      height: "100%",
      background: theme.palette.secondary.main,
      "& .MuiButton-label": {
        color: theme.palette.primary.darkText,
      },
    },
  },
  HotelSection_itinDet: {
    "& .location-input": {
      paddingRight: 15,
      paddingLeft: 15,
    },
    "& .date-input": {
      paddingTop: "14px",
      paddingRight: 15,
      paddingLeft: 15,
      "& .MuiSvgIcon-root": {
        color: theme.palette.secondary.main,
      }
    },
  },
  HotelSection_actions: {},
  HotelSection_idenDet: {
    borderTop: `solid 0.5px ${theme.palette.primary.disabled}`,
    marginTop: 15,
    "& .location-input": {
      paddingRight: 15,
      paddingLeft: 15,
    },
  },
  HotelSection_quicklinks: {
    borderTop: `solid 0.5px ${theme.palette.primary.disabled}`,
    padding: "10px 25px 0",
    marginTop: 10,
    display: "flex",
    justifyContent: "flex-start",
    [theme.breakpoints.down(600)]: {
      flexWrap: "wrap",
      justifyContent: "center",
    },
    "& .quick-link-item": {
      margin: 5,
      border: `solid 1px ${theme.palette.primary.main}`,
      padding: "3px 10px",
      color: theme.palette.primary.main,
      borderRadius: 4,
      cursor: "pointer",
      "&:hover": {
        border: `solid 1px ${theme.palette.secondary.main}`,
        backgroundColor: theme.palette.secondary.main,
      },
      "& .qlink-icon-wrapper": {
        textAlign: "center",
        "& i": {
          fontSize: 15,
        },
      },
      "& .qlink-label-wrapper p": {
        fontSize: 10,
      },
    }
  },
}));

import React, { useState, useEffect } from "react";
import { AppStateContext } from "layouts/AppStateProvider";
import {
  localforageSetItem,
  localforageGetItem,
  localforageClear,
} from "oautils/oaForageUtils";
import { apiCall } from "oautils/oaDataUtils";
import WebApi from "api/ApiConstants";
import { currencyFormatter } from "oautils/oaCommonUtils";
import { makeStyles, useTheme, useMediaQuery, Menu } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import PhoneInTalk from "@material-ui/icons/PhoneInTalk";
import LoginIcon from "@material-ui/icons/ExitToApp";
import { AppConstant } from "appConstant";
import FacebookIcon from "assets/triumphTravel/images/icons/facebook_icon.svg";
import GoogleplusIcon from "assets/triumphTravel/images/icons/google_plus.svg";
import MailIcon from "assets/triumphTravel/images/icons/mail_icon.svg";
import PhoneIcon from "assets/triumphTravel/images/icons/phone_icon.svg";
import TwitterIcon from "assets/triumphTravel/images/icons/twitter_icon.svg";
import { ArrowDropDown, RefreshRounded } from "@material-ui/icons";
import dateFnsFormat from "date-fns/format";

const TopSection = (props) => {
  const classes = useStyles();

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [corpName, setCorpName] = useState("");
  const [corpIdentity, setCorpIdentity] = useState("");
  const [serviceOptions, setServiceOptions] = useState([]);
  const [userType, setUserType] = useState(null);
  const { setUserTypeb2c } = React.useContext(AppStateContext);
  const [corporateType, setCorporateType] = useState(null);
  const [accountBalance, setAccountBalance] = useState("");
  const [availableOD, setAvailableOD] = useState("");
  
  const [odParams, setOdParams] = useState(null);
  const isMounted = React.useRef(false);

  const getValuesFromLocalStorage = () => {
    localforageGetItem("user-details", function(err, value) {
      if (value) {//console.log("value", value);
        if (value.userTypeAbv == "R") {
          setIsLoggedIn(true);
        }

        setCorpName(value.corpname);
        setCorpIdentity(value.userIdentity);
        setAccountBalance(value.cashBalance);
        setAvailableOD(value.availableCreditAmount);
        setServiceOptions(value.serviceOption);

        setUserType(value.userType);
        setUserTypeb2c(value.userType);
        // setCorporateType(value.corporateType);
        setOdParams({
          isBaseCreditSet: value.isBaseCreditSet,
          creditAmount: value.creditAmount,
          availableCreditAmount: value.availableCreditAmount,
          creditValidity: value.creditValidity
        });
    
      }
    });
  };

  const refreshBalance = (event) => {
    setAccountBalance(0);
    setAvailableOD(0);
    apiCall(WebApi.getDashboardData, {}, function(response) {
      let mainAccountBalance = response.data.mainAccountBalance;
      let availableCreditAmount = response.data.availableCreditAmount;
      
      //console.log("response.data ",response.data);
      setOdParams({
        isBaseCreditSet: response.data.isBaseCreditSet,
        creditAmount: response.data.creditAmount,
        availableCreditAmount: response.data.availableCreditAmount,
        creditValidity: response.data.creditValidity
      });

      // console.log("response.data",response.data)
      setAccountBalance(mainAccountBalance);
      setAvailableOD(response.data.availableCreditAmount);
      
      localforageGetItem("user-details", function(err, value) {
        localforageSetItem("user-details", {
          ...value,
          cashBalance: mainAccountBalance,
          availableOD: availableCreditAmount,
          
        });
      });
    });
  };

  const logOut = (event) => {
    localforageClear(() => {
      WebApi.getAgentInfo({ userType: "G" }, (response) => {
        if (response != null && response.success === true) {
          localforageClear(() => {
            localforageSetItem("user-id", 0);

            const loginPagePath =
              corporateType && corporateType != "corporate"
                ? "/auth/login-page"
                : "/corporate_login";

            localforageSetItem("user-details", response.data, () => {
              localforageSetItem("access-key", response.accesskey, () => {
                props.history.push("/flights");
                window.location.reload();
              });
            });
          });
        }
      });
    });
  };

  const logOutB2C = (event) => {
    localforageClear(() => {
      WebApi.getAgentInfo({ userType: "G" }, (response) => {
        if (response != null && response.success === true) {
          localforageClear(() => {
            localforageSetItem("user-id", 0);

            localforageSetItem("user-details", response.data, () => {
              localforageSetItem("access-key", response.accesskey, () => {
                props.history.push("/flights");
                window.location.reload();
              });
            });
          });
        }
      });
    });
  };

  const openDashboard = (event) => {
    props.history.push("/admin/dashboard");
  };

  const openDashboardB2c = (event) => {
    props.history.push("/b2c/mybookings");
  };

  useEffect(() => {
    isMounted.current = true;
    
    return () => {
      isMounted.current = false;
    }
  }, []);

  React.useEffect(() => {
    if(isMounted.current) {
      getValuesFromLocalStorage();
    }
  }, [isMounted.current]);

  return (
    <section className={classes.topSection}>
      <div className="top-section-container">
        <div className="column-left">
          {props.isDashboard &&
          <div className="dashboard-contact-menu-wrapper">
            <span>Support</span> <ArrowDropDown/>
          </div>}
          <div className={
            props.isDashboard ? 
            "top-section-link-wrapper dropdown-links" : 
            "top-section-link-wrapper"
          }>
            <ul>
              <li>
                <a href="">
                  <img src={PhoneIcon} /> {AppConstant.countryDialCode}{" "}
                  {AppConstant.supportContact}
                </a>
              </li>
              <li>
                <a href="">
                  <img src={MailIcon} /> {AppConstant.supportEmail}
                </a>
              </li>
            </ul>
          </div>
          {!userType && (
            <div className="top-section-link-wrapper social-link-wrapper">
              <ul>
                <li>
                  <a href="">
                    <img src={FacebookIcon} />
                  </a>
                </li>
                <li>
                  <a href="">
                    <img src={TwitterIcon} />
                  </a>
                </li>
                <li>
                  <a href="">
                    <img src={GoogleplusIcon} />
                  </a>
                </li>
              </ul>
            </div>
          )}
        </div>
        <div className="column-right">
          <div className="top-section-link-wrapper">
            {!userType && (
              <ul>
                {/* <li>
                                    <a onClick={() => props.history.push("/auth/login-page")}>Agents</a>
                                </li> */}
                <li>
                  <a onClick={() => props.history.push("/")}>
                    Sign in
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => props.history.push("/prelogin/registration")}
                  >
                    New User?
                  </a>
                </li>
                <li>
                  <a href="https://partner.triumphhtravel.com/">Old Website</a>
                </li>
              </ul>
            )}
            {userType === "b2c_user" && (
              <ul>
                <li>
                  <a onClick={(event) => openDashboardB2c(event)}>Dashboard</a>
                </li>
                <li>
                  <a>
                    {corpName}({corpIdentity})
                  </a>
                </li>
                <li>
                  <a onClick={(event) => logOutB2C(event)}>Sign out</a>
                </li>
              </ul>
            )}
            {(userType === "agent" || userType === "direct_agent") && (
              <ul>
                <li>
                  <a onClick={(event) => openDashboard(event)}>Dashboard</a>
                </li>
                <li>
                  <a
                    onClick={(event) => refreshBalance(event)}
                    className="balance-link"
                  >
                    <RefreshRounded />
                    {/* {console.log("odParams 260", odParams)} */}
                    &nbsp;<span>Cash:&nbsp;&nbsp;<b>{currencyFormatter(accountBalance)}</b></span>&nbsp;&nbsp;
                    {odParams?.isBaseCreditSet && new Date() < new Date(odParams?.creditValidity) &&
                    <>
                    <span>OD :&nbsp;&nbsp;<b>{currencyFormatter(availableOD)}</b></span>&nbsp;&nbsp;
                    <span>(Expiry: <b>{dateFnsFormat(new Date(odParams?.creditValidity), 'dd MMM yyyy')}</b>)</span>
                    </>}
                  </a>
                </li>
                <li>
                  <a>
                    {corpName}({corpIdentity})
                  </a>
                </li>
                <li>
                  <a onClick={(event) => logOut(event)}>Sign out</a>
                </li>
              </ul>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default withRouter(TopSection);

const useStyles = makeStyles((theme) => ({
  topSection: {
    backgroundColor: theme.palette.primary.main,
    transition:
      "background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s",
    marginTop: 0,
    marginBottom: 0,
    padding: 0,
    position: "relative",
    boxSizing: "border-box",
    "& *::before, *::after": {
      boxSizing: "inherit",
      WebkitFontSmoothing: "inherit",
      wordBreak: "break-word",
      wordWrap: "break-word",
    },
    "& .top-section-container": {
      display: "-webkit-box",
      display: "-ms-flexbox",
      display: "flex",
      marginRight: "auto",
      marginLeft: "auto",
      position: "relative",
      WebkitBoxSizing: "border-box",
      boxSizing: "border-box",
      justifyContent: "flex-end",
      padding: "0 60px 0 30px",
      "& *::before, *::after": {
        boxSizing: "inherit",
        WebkitFontSmoothing: "inherit",
        wordBreak: "break-word",
        wordWrap: "break-word",
      },
      [theme.breakpoints.down(960)]: {
        padding: "0 10px",
      },
    },
    "& .column-left": {
      marginRight: "auto",
      display: "-webkit-box",
      display: "-ms-flexbox",
      display: "flex",
      position: "relative"
    },
    "& .column-right": {
      marginLeft: "auto",
      display: "-webkit-box",
      display: "-ms-flexbox",
      display: "flex",
    },
    "& .top-section-link-wrapper": {
      "& ul": {
        listStyle: "none",
        display: "flex",
        flexDirection: "row",
        [theme.breakpoints.down(960)]: {
          display: "none",
        },
      },
      "& li": {
        margin: "0 10px",
        color: theme.palette.primary.contrastText,
      },
      "& a": {
        color: theme.palette.primary.contrastText,
        cursor: "pointer",
        "&.balance-link": {
          color: theme.palette.primary.contrastText,
          "& .MuiSvgIcon-root": {
            height: 15,
          },
        },
        [theme.breakpoints.down(1441)]: {
          fontSize: 12,
        },
      },
    },
    [theme.breakpoints.down(960)]: {
      backgroundColor: "none",
      position: "absolute",
    },
    "& .dropdown-links": {
      display: "none",
      position: "absolute",
      zIndex: 10,
      padding: "28px 0 0 0",
      "&:hover": {
        display: "block"
      },
      "& ul": {
        display: "flex !important",
        flexDirection: "column !important",
        background: theme.palette.primary.main,
        width: "max-content",
        boxShadow: "0px 0px 5px 0px rgba(102,102,102,1)",
        borderRadius: 3,
        paddingInlineStart: 0,
        "& li": {
          padding: "10px 15px 5px",
          margin: 0,
          borderBottom: "solid 1px gray",
          "&:last-child": {
            padding: "5px 15px 10px",
            borderBottom: "none",
          },
          "& a": {
            display: "block"
          }
        }
      }
    },
    "& .dashboard-contact-menu-wrapper": {
      color: theme.palette.primary.contrastText,
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
      "&:hover + .dropdown-links": {
        display: "block"
      }
    }
  },
}));

import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { MenuItem, Popper, Select, TextField, Button, Grid, FormControlLabel, Checkbox } from "@material-ui/core";
import { COLORS, FONTS } from "assets/css/CssConstants";
import { DestCountry } from "./Countries";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import OaFormAlerts from "pages/components/OaFormAlerts";
import { Autocomplete } from "@material-ui/lab";
import WebApi from "api/ApiConstants";
import { apiCall } from 'oautils/oaDataUtils';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import dateFnsFormat from "date-fns/format";

const useStyles = makeStyles(theme => ({

    root: {
        minWidth: 860,
        padding: "20px 32px",
        fontFamily: theme.palette.font.primary,
        "& .header-wrapper": {
            textAlign: "center",
            borderBottom: `solid 0.5px ${theme.palette.primary.disabled}`,
        },
        "& .content-wrapper": {
            borderTop: `solid 0.5px ${theme.palette.primary.disabled}`,
            paddingTop: 15,
        },
        "& .form-row": {
            margin: "15px 0",
            padding: "5px 15px",
        },
        "& .form-item": {
            width: "100%",
            padding: "0 15px",
            [theme.breakpoints.down(960)]: {
                marginBottom: 20,
            },
        },
        "& .select-col": {
            "& .MuiInput-root": {
                marginTop: 3,
            },
        },
        "& label": {
            color: theme.palette.primary.defaultText,
        },
        "& .action-wrapper": {
            justifyContent: "center",
            "& .MuiButton-containedPrimary": {
                color: theme.palette.primary.lightText,
            },
        },
    },
}));

const TourSection = (props) => {

    const classes = useStyles();

    const [isSaving, setIsSaving] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isError, setIsError] = useState(false);
    const [infoText, setInfoText] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [consulates, setConsulates] = useState(null);
    const [fromDateDialogOpen, setFromDateDialogOpen] = useState(false);
    const [toDateDialogOpen, setToDateDialogOpen] = useState(false);

    const customPopper = function (props) {
        // console.log("props", props);
        return (
            <Popper
                {...props}
                // anchorEl={departAutocompleteEl.current.children[0]}
                placement="bottom-start"
                style={{}}
            />
        );
    };

    const phoneRegex = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/

    return (
        <div className={classes.root}>
            <div className="header-wrapper">
                <h3>Holiday Packages</h3>
                <p>Request a Holiday Package Now!</p>
            </div>

            <div className="content-wrapper">
                <div className="form-wrapper">
                    <OaFormAlerts
                        isSaving={isSaving}
                        isSuccess={isSuccess}
                        isError={isError}
                        infoText={infoText}
                        setIsError={setIsError}
                        setIsSuccess={setIsSuccess}
                    />
                    <Formik
                        initialValues={{
                            country: "",
                            city: "",
                            travellers: 0,
                            mealInclude: false,
                            activityInclude: false,
                            sightseeingInclude: false,
                            transferInclude: false,
                            accomodationInclude: false,
                            fromDateDisplay: new Date(),
                            toDateDisplay: new Date(new Date().setDate(new Date().getDate() + 1)),
                            email: "",
                            phone: "",
                            fullname: "",
                        }}
                        validationSchema={
                            Yup.object().shape({
                                country: Yup.string().required("Country is required"),
                                city: Yup.string().required("City is required"),
                                travellers: Yup.number().required("Number of travellers is required")
                                .moreThan(0, 'number of travellers should not be zero or less than zero'),
                                email: Yup.string().email().required("Email id is required"),
                                phone: Yup.string().required("Mobile Number is required").matches(phoneRegex, 'Invalid mobile number'),
                                fullname: Yup.string().required("Contact person's name is required"),
                            })
                        }
                        onSubmit={(values, { setSubmitting }) => {
                            values.fromDate = dateFnsFormat(values.fromDateDisplay, "do MMM yyyy");
                            values.toDate = dateFnsFormat(values.toDateDisplay, "do MMM yyyy");
                            // console.log("form values", values);
                            setIsError(false);
                            setIsSuccess(false);
                            setSubmitting(true);
                            setIsSaving(true);

                            apiCall(WebApi.addTourEnquiry, values, (response) => {
                                setIsError(!response.success);
                                setIsSuccess(response.success);
                                setSubmitting(false);
                                setIsSaving(false);
                                setInfoText(response.message);
                            });
                        }}
                    >
                        {({ values, errors, touched, handleChange, setFieldValue }) =>
                            <Form>
                                <div className="form-contents">
                                    <Grid container className="form-row">
                                        <Grid item xs={12} sm={12} md={4} className="form-item">
                                            <label>Destination Country</label>
                                            <Autocomplete
                                                fullWidth
                                                PopperComponent={customPopper}
                                                name="country"
                                                options={DestCountry || []}
                                                getOptionLabel={(option) => option.name}
                                                // value={values.country}
                                                getOptionSelected={(option, value) => {
                                                    return option.name == value.name;
                                                }}
                                                onChange={(event, newValue) => {
                                                    setFieldValue("country", newValue.name);
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        fullWidth
                                                        color="secondary"
                                                        placeholder="search country"
                                                    />
                                                )}
                                                disablePortal={true}
                                            />
                                            <ErrorMessage
                                                name="country"
                                                component="div"
                                                className="error"
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={4} className="form-item select-col">
                                            <label>Destination City</label>
                                            <TextField
                                                fullWidth
                                                name="city"
                                                value={values.city}
                                                onChange={event => setFieldValue("city", event.target.value)}
                                                placeholder="Enter City Name"
                                            />
                                            <ErrorMessage
                                                name="city"
                                                component="div"
                                                className="error"
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={4} className="form-item select-col">
                                            <label>Travellers</label>
                                            <TextField
                                                fullWidth
                                                name="travellers"
                                                value={values.travellers}
                                                type="number"
                                                onChange={event => setFieldValue("travellers", event.target.value)}
                                                placeholder="number of travellers"
                                            />
                                            <ErrorMessage
                                                name="travellers"
                                                component="div"
                                                className="error"
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid container className="form-row">
                                        <Grid item xs={12} sm={12} md={4} className="form-item">
                                            <label>From</label>
                                            <MuiPickersUtilsProvider
                                                utils={DateFnsUtils}
                                            >
                                                <KeyboardDatePicker
                                                    KeyboardButtonProps={{
                                                        onFocus: (e) => {
                                                            setFromDateDialogOpen(true);
                                                        },
                                                    }}
                                                    PopoverProps={{
                                                        disableRestoreFocus: true,
                                                        onClose: () => {
                                                            setFromDateDialogOpen(false);
                                                        },
                                                    }}
                                                    InputProps={{
                                                        onFocus: () => {
                                                            setFromDateDialogOpen(true);
                                                        },
                                                    }}
                                                    name="fromDateDisplay"
                                                    open={fromDateDialogOpen}
                                                    onClose={() =>
                                                        setFromDateDialogOpen(false)
                                                    }
                                                    onOpen={() =>
                                                        setFromDateDialogOpen(true)
                                                    }
                                                    disablePast
                                                    id="fromDateEl"
                                                    fullWidth
                                                    color="secondary"
                                                    disableToolbar
                                                    variant="inline"
                                                    format="dd-MM-yyyy"
                                                    margin="normal"
                                                    // label="From"
                                                    // value={data.from}
                                                    value={
                                                        values.fromDateDisplay
                                                    }
                                                    onChange={(e, v) => {
                                                        setFromDateDialogOpen(false);
                                                        setToDateDialogOpen(true);
                                                        setFieldValue(`fromDateDisplay`, e);
                                                    }}
                                                />
                                            </MuiPickersUtilsProvider>
                                            <ErrorMessage
                                                name="fromDateDisplay"
                                                component="div"
                                                className="error"
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={4} className="form-item">
                                            <label>To</label>
                                            <MuiPickersUtilsProvider
                                                utils={DateFnsUtils}
                                            >
                                                <KeyboardDatePicker
                                                    KeyboardButtonProps={{
                                                        onFocus: (e) => {
                                                            setToDateDialogOpen(true);
                                                        },
                                                    }}
                                                    PopoverProps={{
                                                        disableRestoreFocus: true,
                                                        onClose: () => {
                                                            setToDateDialogOpen(false);
                                                        },
                                                    }}
                                                    InputProps={{
                                                        onFocus: () => {
                                                            setToDateDialogOpen(true);
                                                        },
                                                    }}
                                                    name="toDateDisplay"
                                                    open={toDateDialogOpen}
                                                    onClose={() =>
                                                        setToDateDialogOpen(false)
                                                    }
                                                    onOpen={() =>
                                                        setToDateDialogOpen(true)
                                                    }
                                                    disablePast
                                                    id="toDateEl"
                                                    fullWidth
                                                    color="secondary"
                                                    disableToolbar
                                                    variant="inline"
                                                    format="dd-MM-yyyy"
                                                    margin="normal"
                                                    // label="From"
                                                    // value={data.from}
                                                    value={
                                                        values.toDateDisplay
                                                    }
                                                    onChange={(e, v) => {
                                                        setToDateDialogOpen(false);
                                                        setFieldValue(`toDateDisplay`, e);
                                                    }}
                                                />
                                            </MuiPickersUtilsProvider>
                                            <ErrorMessage
                                                name="toDateDisplay"
                                                component="div"
                                                className="error"
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={4}>
                                            <label>Contact Person</label>
                                            <TextField
                                                fullWidth
                                                name="fullname"
                                                value={values.fullname}
                                                onChange={event => setFieldValue("fullname", event.target.value)}
                                                placeholder="Full Name of contact person"
                                            />
                                            <ErrorMessage
                                                name="fullname"
                                                component="div"
                                                className="error"
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid container className="form-row">
                                        <Grid item xs={12} sm ={12} md={12}>
                                            <label>Includes</label>
                                            <div className="checkbox-div">
                                                <FormControlLabel 
                                                    control={<Checkbox defaultChecked />}
                                                    label="Meals"
                                                    name="mealInclude"
                                                    checked={values.mealInclude}
                                                    onChange={(ev) => {
                                                        setFieldValue("mealInclude", ev.target.checked);
                                                    }}
                                                />

                                                <FormControlLabel
                                                    control={<Checkbox />}
                                                    label="Sightseeing"
                                                    name="sightseeingInclude"
                                                    checked={values.sightseeingInclude}
                                                    onChange={(ev) => {
                                                        setFieldValue("sightseeingInclude", ev.target.checked);
                                                    }}
                                                />

                                                <FormControlLabel
                                                    control={<Checkbox />}
                                                    label="Activity"
                                                    name="activityInclude"
                                                    checked={values.activityInclude}
                                                    onChange={(ev) => {
                                                        setFieldValue("activityInclude", ev.target.checked);
                                                    }}
                                                />

                                                <FormControlLabel
                                                    control={<Checkbox />}
                                                    label="Transfer"
                                                    name="transferInclude"
                                                    checked={values.transferInclude}
                                                    onChange={(ev) => {
                                                        setFieldValue("transferInclude", ev.target.checked);
                                                    }}
                                                />

                                                <FormControlLabel
                                                    control={<Checkbox />}
                                                    label="Accomodation"
                                                    name="accomodationInclude"
                                                    checked={values.accomodationInclude}
                                                    onChange={(ev) => {
                                                        setFieldValue("accomodationInclude", ev.target.checked);
                                                    }}
                                                />
                                            </div>
                                        </Grid>
                                    </Grid>

                                    <Grid container className="form-row">
                                        <Grid item xs={12} sm={12} md={6} className="form-item">
                                            <label>Email id</label>
                                            <TextField
                                                fullWidth
                                                name="email"
                                                value={values.email}
                                                onChange={event => setFieldValue("email", event.target.value)}
                                                placeholder="Enter e-mail id"
                                            />
                                            <ErrorMessage
                                                name="email"
                                                component="div"
                                                className="error"
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={6} className="form-item">
                                            <label>Mobile Number</label>
                                            <TextField
                                                fullWidth
                                                name="phone"
                                                value={values.phone}
                                                onChange={event => setFieldValue("phone", event.target.value)}
                                                placeholder="Enter Mobile Number"
                                            />
                                            <ErrorMessage
                                                name="phone"
                                                component="div"
                                                className="error"
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid container className="form-row action-wrapper">
                                        <Button
                                            type="submit"
                                            color="primary"
                                            variant="contained"
                                        >
                                            Submit
                                        </Button>
                                    </Grid>
                                </div>
                            </Form>}
                    </Formik>
                </div>
            </div>
        </div>
    );
}

export default TourSection;
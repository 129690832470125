import React, { useEffect, useState } from "react";


import {
    Box,
    Grid,
    makeStyles,
    AppBar,
    Tabs,
    Tab,
    useTheme,
    useMediaQuery
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { getQueryVar, getCurrentTab } from "oautils/oaCommonUtils.js";
import WebApi from "api/ApiConstants";
import {
    localforageSetItem,
    localforageClear,
    localforageGetItemAsync,
} from "oautils/oaForageUtils";
import FlightRoundedIcon from "@material-ui/icons/FlightRounded";
import DirectionsBusRoundedIcon from "@material-ui/icons/DirectionsBusRounded";
import ApartmentRoundedIcon from "@material-ui/icons/ApartmentRounded";
import { COLORS } from "assets/css/CssConstants";
import FlightSection from "./forms/FlightSection";
import BusSection from "./forms/BusSection";
import HotelSection from "./forms/HotelSection";
import VisaSection from "./forms/VisaSection";
import VisaRoundedIcon from "@material-ui/icons/DescriptionRounded";
import {
    Build,
    CardTravelOutlined,
    MobileFriendly,
    MonetizationOnRounded,
    PermDataSetting,
    PermDataSettingSharp,
    SettingsApplications,
    SettingsBackupRestore,
    SettingsCell,
    SettingsEthernetRounded,
    SettingsInputAntennaRounded,
    SettingsOutlined,
    SettingsOverscan,
    SettingsRemote,
    SettingsSystemDaydream
} from "@material-ui/icons";
import AttachMoneyOutlinedIcon from "@material-ui/icons/AttachMoneyOutlined";
import DMTSection from "./forms/DMTSection"
import TourSection from "./forms/TourSection";
import { withRouter } from "react-router-dom";
import RechargeSection from "./forms/RechargeSection";
import UtilitySection from "./forms/UtilitySection";

const useStyles = makeStyles(theme => ({
    searchboxroot: {
        marginBottom: 220,
        position: "relative",
        [theme.breakpoints.down(960)]: {
            marginBottom: 0,
        },
        "& .tab-panel-wrap": {
            width: 1200,
            minHeight: 245,
            background: theme.palette.background.light,
            [theme.breakpoints.down(1210)]: {
                width: "100%",
            },
            [theme.breakpoints.down(768)]: {
                width: "100vw",
            },
        },
        "& .MuiAutocomplete-listbox": {
            "&::-webkit-scrollbar": {
                width: 5,
            },
            "&::-webkit-scrollbar-button": {
                background: theme.palette.primary.main,
                width: 5,
            },
            "&::-webkit-scrollbar-thumb": {
                background: "#b1b1b1",
                width: 5,
            },
            "&::-webkit-scrollbar-track": {
                width: 5,
            },
            "& li": {
                margin: "2px 0",
            },
        },
        "& .tab-name-440": {
            display: "none",
            position: "absolute",
            textAlign: "center",
            [theme.breakpoints.down(441)]: {
                display: "block",
                top: -125,
                left: 0,
                right: 0,
            },
            "& h3": {
                fontWeight: 700,
                color: theme.palette.primary.lightText,
                textTransform: "uppercase",
                fontFamily: theme.palette.font.primary,
                fontSize: 30,
                textAlign: "center",
            },
        }
    },
    tabWrapper: {
        display: "flex",
        flexDirection: "column",
        position: "relative",
        "& .tab-head": {
            position: "absolute",
            top: -264,
            left: 0,
            right: 0,
            [theme.breakpoints.down(960)]: {
                top: -74,
            },
            [theme.breakpoints.between(439, 441)]: {
                top: -50,
            },
            [theme.breakpoints.down(440)]: {
                top: -74,
            },
            "& .tab-head-appbar": {
                background: "none",
                boxShadow: "none",
                "& .MuiTabs-scroller": {
                    height: "unset",
                },
            },
            "& .MuiTabs-indicator": {
                display: "none",
            },
            "& .MuiTabs-flexContainer": {
                justifyContent: "center",
                "& .MuiTab-root": {
                    [theme.breakpoints.down(826)]: {
                        minWidth: "75px !important",
                    },
                    [theme.breakpoints.down(800)]: {
                        border: "none !important",
                    },
                    [theme.breakpoints.down(440)]: {
                        minHeight: "55px !important",
                        minWidth: "46px !important",
                    },
                },
            },
            "& .MuiTab-root.MuiButtonBase-root": {
                backgroundColor: theme.palette.background.default,
                color: theme.palette.primary.main,
                margin: "1px 0.7px 1px",
                width: 150,
                [theme.breakpoints.down(826)]: {
                    width: 75,
                },
                [theme.breakpoints.down(440)]: {
                    width: 46,
                    margin: "18px 0.7px 1px",
                    "& .MuiTab-wrapper": {
                        fontSize: 10,
                        "& .MuiSvgIcon-root": {
                            fontSize: 16,
                        },
                    }
                },
            },
            "& .MuiTab-textColorSecondary.Mui-selected": {
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.primary.lightText,
            },
        },
        "& .tab-content-body": {
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            left: 0,
            right: 0,
            top: -190,
            [theme.breakpoints.down(960)]: {
                position: "unset",
            },
            [theme.breakpoints.down(441)]: {
                top: 0,
            },
            "& .tab-content-wrapper-bg": {
                maxWidth: 1280,
                position: "relative",
                backgroundColor: theme.palette.background.light,
                boxShadow: "0px 5px 40px -10px rgb(170 170 170)",
                WebkitBoxShadow: "0px 5px 40px -10px rgb(170 170 170)",
                MozBoxShadow: "0px 5px 40px -10px rgb(170 170 170)",
            },
            "& .tab-content-wrapper": {
                // margin: "0 5vw",
                maxWidth: "100%",
                width: "100%",
                [theme.breakpoints.down(600)]: {
                    padding: "20px 0"
                },
            }
        }
    }
}));

const tabPanelUseStyles = makeStyles((theme) => ({
    tabpanel_box: {
        [theme.breakpoints.up(600)]: {
            margin: "5px",
        },
    },
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    const tabPanelClasses = tabPanelUseStyles();

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-force-tabpanel-${index}`}
            aria-labelledby={`scrollable-force-tab-${index}`}
            className="tab-panel-wrap"
            {...other}
        >
            {value === index && (
                <Box className={tabPanelClasses.tabpanel_box}>{children}</Box>
            )}
        </div>
    );
}

const SearchForm = (props) => {
    const classes = useStyles();

    const dispatch = useDispatch();
    const defaultTab = "flights";
    const queryVar = getQueryVar({ ...props, q: "priTab" });
    const currentTabName = getCurrentTab(queryVar, defaultTab);
    const [isLoading, setIsLoading] = useState(false);
    const userTypeInfo = useSelector((state) => state.usertype);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down(441));
    const [currentService, setCurrentService] = useState("Flights");

    const getCurrentTabIndex = (key) => {
        switch (key) {
            case "flights":
                return 0;

            case "hotels":
                return 1;

            case "buses":
                return 2;

            case "moneytransfer":
                return 3;

            case "recharge":
                return 4;

            case "utility":
                return 5;

            // case "visas":
            //     return 4;

            // case "tours":
            //     return 4;

            default:
                return 0;
        }
    };

    const [inputData, setInputData] = useState({
        currentTab: getCurrentTabIndex(currentTabName),
    });

    useEffect(() => {
        switch (inputData.currentTab) {
            case 0: {
                setCurrentService("flights");
                break;
            }

            case 1: {
                setCurrentService("hotels");
                break;
            }

            case 2: {
                setCurrentService("buses");
                break;
            }

            case 3: {
                setCurrentService("money transfer");
                break;
            }

            case 4: {
                setCurrentService("recharge");
                break;
            }

            case 5: {
                setCurrentService("utility");
                break;
            }

            default: {
                setCurrentService("flights");
                break;
            }
        }
    }, [inputData.currentTab]);

    React.useEffect(() => {
        var tab = defaultTab;
        if (props.match.params?.priTab) {
            // console.log(props.match.params?.priTab)
            tab = getCurrentTab(queryVar, props.match.params?.priTab);
        }
        setInputData({
            currentTab: getCurrentTabIndex(tab)
        });
    }, [props.match]);

    return (
        <div className={classes.searchboxroot}>
            <div className="tab-name-440">
                <h3>{currentService}</h3>
            </div>
            <div className={classes.tabWrapper}>
                <div className="tab-head">
                    <AppBar
                        position="static"
                        color="default"
                        className="tab-head-appbar"
                    >
                        <Tabs
                            value={inputData.currentTab}
                            onChange={(e, i) => {
                                setInputData({ ...inputData, currentTab: i });
                                if (i == 0) props.history.push("/flights");
                                if (i == 1) props.history.push("/hotels");
                                if (i == 2) props.history.push("/buses");
                                // if (i == 3 && userTypeInfo.partnerId === 1002) props.history.push("/moneytransfer");
                                // if (i == 4 && userTypeInfo.partnerId === 1002) props.history.push("/recharges");
                                // if (i == 3) props.history.push("/visas");
                                // if (i == 4) props.history.push("/tours");

                            }}
                            indicatorColor="secondary"
                            textColor="secondary"
                            className={classes.tabs}
                        // TabIndicatorProps={}
                        >
                            {/* <Tab label={isMobile ? "" : "Flights"} icon={<FlightRoundedIcon />} />
                            <Tab label={isMobile ? "" : "Hotels"} icon={<ApartmentRoundedIcon />} /> */}
                            <Tab label={"Flights"} icon={<FlightRoundedIcon />} />
                            <Tab label={"Hotels"} icon={<ApartmentRoundedIcon />} />
                            <Tab label={"Bus"} icon={<DirectionsBusRoundedIcon />} />
                            {/* {
                                userTypeInfo.partnerId === 1002 &&
                                <Tab label={isMobile ? "" : "DMT"} icon={<MonetizationOnRounded />} />
                            } */}
                            {/* <Tab label="Visa" icon={<VisaRoundedIcon />} /> */}
                            {/* <Tab label="Tours" icon={<CardTravelOutlined />} /> */}
                            {/*{
                                userTypeInfo.partnerId === 1002 &&
                                <Tab label={isMobile ? "" : "Recharge"} icon={<MobileFriendly />} />
                            } */}
                        </Tabs>
                    </AppBar>
                </div>

                <div className="tab-content-body">
                    <div className="tab-content-wrapper-bg">
                        <div className="tab-content-wrapper">
                            <TabPanel value={inputData.currentTab} index={0}>
                                <FlightSection />
                            </TabPanel>
                            <TabPanel value={inputData.currentTab} index={1}>
                                <HotelSection />
                            </TabPanel>
                            <TabPanel value={inputData.currentTab} index={2}>
                                <BusSection />
                            </TabPanel>
                            {/* {
                                userTypeInfo.partnerId === 1002 &&
                                <TabPanel value={inputData.currentTab} index={3}>
                                    <DMTSection {...props}/>
                                </TabPanel>
                            } */}
                            {/* <TabPanel value={inputData.currentTab} index={3}>
                                <VisaSection />
                            </TabPanel> */}
                            {/* <TabPanel value={inputData.currentTab} index={4}>
                                <TourSection />
                            </TabPanel> */}
                            {/* {
                                userTypeInfo.partnerId === 1002 &&
                                <TabPanel value={inputData.currentTab} index={4}>
                                    <RechargeSection {...props}/>
                                </TabPanel>
                            } */}
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default withRouter(SearchForm);

const airlinesList = [
    { val: "all", text: "All" },
    { val: "gds-only", text: "GDS Only" },
    { val: "lcc-only", text: "LCC Only" },
    { val: "air-asia", text: "Air Asia" },
];

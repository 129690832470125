import { makeStyles } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import HomePageFooter from "./HomePageFooter";
import HomePageNavbar from "./HomePageNavbar";
import SearchForm from "./SearchForm";
import bgImage from "assets/triumphTravel/images/promo-bg-image.jpg";
import transLogo from "assets/triumphTravel/images/logo_faded.png"

const TopPromoSection = (props) => {

    const useStyles = makeStyles(theme => ({
        root: {
            padding: 0,
            margin: 0,
            "& .bg-div": {
                width: "100%",
                height: "40vw",
                backgroundImage: `url(${bgImage})`,
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                [theme.breakpoints.down(1290)]: {
                    height: props.isloggedin ? "40vw" : "50vw",
                },
                [theme.breakpoints.down(960)]: {
                    height: 490,
                },
                [theme.breakpoints.down(768)]: {
                    height: 400,
                },
                [theme.breakpoints.down(600)]: {
                    height: 350,
                },
                [theme.breakpoints.down(441)]: {
                    height: 200,
                },
            },
            "& .overlay-bg": {
                width: "100%",
                height: "100%",
                background: "rgba(9,45,92,0.2)",
                display: "flex",
                alignItems: "flex-start",
                padding: "0 80px",
                position: "relative",
            },
            "& .trans-image": {
                position: "absolute",
                top: "20%",
                right: "40%",
                [theme.breakpoints.down(960)]: {
                    right: "5%",
                },
                "& img": {
                    [theme.breakpoints.down(768)]: {
                        height: 100,
                    },
                },
            },
            "& .promo-text-container": {
                width: "70%",
                marginTop: 130,
                "& h1": {
                    textTransform: "none",
                    color: theme.palette.primary.lightText,
                    fontWeight: 600,
                },
                "& p": {
                    color: theme.palette.primary.lightText,
                    fontSize: 20,
                },
                // [theme.breakpoints.down(830)]: {
                //     "& h1": {
                //         fontSize: 24,
                //     },
                //     "& p": {
                //         fontSize: 14,
                //     },
                // },
                [theme.breakpoints.down(960)]: {
                    display: "none",
                },
            },
        },
    }));

    const classes = useStyles();

    return(
        <div className={classes.root}>
            <div className="bg-div">
                <div className="overlay-bg">
                    <div className="trans-image">
                        <img src={transLogo} />
                    </div> 
                    <div className="promo-text-container">
                        <h1>Your Journey Begins here</h1>
                        <p>
                            Where would you like to go?
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default withRouter(TopPromoSection);
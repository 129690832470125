import Dashboard from "pages/b2c/Dashboard";
import React from "react";
import { Switch, Route } from "react-router-dom";
// import PostLoginNavBar from "components/Navbars/PostLoginNavBar.js";
import PostLoginNavBar from "components/Navbars/PostLoginNavBar.TriumphTravels";
// import PreLoginNavBar from "components/Navbars/PreLoginNavBar.js";
import PageFooter from "components/Footer/PageFooter.TriumphTravels";
//import PageFooter from "components/LandingPage/TriumphTravel/HomePageFooter";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Itinerary from "pages/b2c/Itinerary";

import MyProfile from "pages/b2c/MyProfile";

import MyBookings from "pages/b2c/MyBookings";
import FareDetails from "pages/b2c/FareDetails";
import PrintTicket from "pages/b2c/PrintTicket";
import Register from "pages/b2c/Register";
import styles from "assets/jss/material-dashboard-pro-react/layouts/adminStyle.js";
import SingIn from "pages/b2c/SignIn";
import Faqs from "pages/siteinfo/Faqs";
import AboutUs from "components/LandingPage/TriumphTravel/siteinfo/AboutUsPage";
import ContactUs from "components/LandingPage/TriumphTravel/siteinfo/ContactUsPage";
import ForgotPassword from "pages/b2c/ForgotPassword";
import BastarPage from "components/InfoArea/BastarPage";
import FareDetailsHotel from "pages/b2c/FareDetailsHotel";
import ScrollToTopComp from "components/LandingPage/TriumphTravel/ScrollToTopComp";
import CancelTicket from "pages/b2c/CancelTicket";
import TermsAndConditions from "components/LandingPage/TriumphTravel/siteinfo/TermsAndConditions";
import PrivacyPolicy from "components/LandingPage/TriumphTravel/siteinfo/PrivacyPolicy";

const useStyles = makeStyles(styles);

// const useStyles = makeStyles((theme) => ({
//   wrapper: {
//     // position: "relative",
//     // paddingTop: "79px",
//     // height: "100vh",
//     //     maxWidth: "75%",
//     // margin: "auto",
//     // paddingTop: "6%",
//     // paddingBottom: "40px",
//     // "&:after": {
//     //   display: "table",
//     //   clear: "both",
//     //   content: '" "',
//     // },
//   },
// }));

const B2clayout = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const { ...rest } = props;
  return (
    <div className={classes.wrapper+" triumph-b2c"} >

      <PostLoginNavBar {...rest} isSearchPage={false} showServices={true} />


      {/* <div style={{ background: "#e8e8e8b0", marginTop: 40 }}> */}
      <div style={{ background: theme.palette.background.default, marginTop: 0, minHeight: "52vh" }}>
        <Switch>
          <Route path="/b2c/dashboard" exact component={Dashboard} />
          <Route path="/b2c/itinerary" exact component={Itinerary} />
          <Route path="/b2c/myprofile" exact component={MyProfile} />
          <Route path="/b2c/mybookings" exact component={MyBookings} />
          <Route path="/b2c/faredetails" exact component={FareDetails} />
          <Route path="/b2c/faredetailsHotel" exact component={FareDetailsHotel} />
          <Route path="/b2c/printeticket" exact component={PrintTicket} />
          <Route path="/b2c/register" exact>
            <Register tnc={TermsAndConditions} ppc={PrivacyPolicy}/>
          </Route>
          <Route path="/b2c/signin" exact component={SingIn} />
          <Route path="/b2c/faqs" exact component={Faqs} />
          <Route path="/b2c/aboutus" exact component={AboutUs} />
          <Route path="/b2c/contactus" exact component={ContactUs} />
          <Route path="/b2c/forgotpassword" exact component={ForgotPassword} />
          <Route path="/b2c/stours_section" exact component={BastarPage} />
          <Route path="/b2c/cancelticket" exact component={CancelTicket} />
        </Switch>
        <PageFooter white {...props} />
        <ScrollToTopComp />
      </div>
      <style>{`
      .triumph-b2c .oa-navbar-menu a {
        font-family: ${theme.palette.font.primary};
        color: ${theme.palette.primary.lightText};
      },
      `}</style>
    </div>
  );
};

export default B2clayout;

import OaFormAlerts from "pages/components/OaFormAlerts";
import React from "react";
import WebApi from "api/ApiConstants";
import { useHistory } from "react-router-dom";
import { Button, Grid } from "@material-ui/core";
import { apiCall } from "oautils/oaDataUtils";

const DMTTrnCallback = (props) => {

    const [isSaving, setIsSaving] = React.useState(false);
    const [isSuccess, setIsSuccess] = React.useState(false);
    const [isError, setIsError] = React.useState(false);
    const [infoText, setInfoText] = React.useState("");
    const [alertTitle] = React.useState("");
    const history = useHistory();

    React.useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        console.log()
        const data = params.get("data");

        apiCall(WebApi.dmtTrnCallback, { data: data }, response => {
            if (response.success === true) {
                setIsSuccess(response.success)
                setInfoText(response.message)
            } else {
                setIsError(true);
                setInfoText(response.message)
            }
        });
    }, []);

    return(
        <>
            <OaFormAlerts
                isSaving={isSaving}
                isSuccess={isSuccess}
                isError={isError}
                infoText={infoText}
                setIsError={setIsError}
                setIsSuccess={setIsSuccess}
                alertTitle={alertTitle}
            />

            {
                !isSuccess && !isError &&
                <Grid container justifyContent="center" alignItems="center">Please wait.. Processing data... </Grid>
            }

            {
                !isSuccess && isError &&
                <Grid container justifyContent="center" alignItems="center">
                    <Button
                        color="primary"
                        onClick={() => {
                            history.push("/moneytransfer");
                        }}>Try Again</Button></Grid>
            }

            {
                isSuccess && !isError &&
                <Grid container justifyContent="center" alignItems="center">

                    <Button
                        color="primary"
                        onClick={() => {
                            history.push("/moneytransfer");
                        }}

                    >
                        Create More Transactions
                    </Button>


                </Grid>
            }
        </>
    )
}

export default DMTTrnCallback;

import { makeStyles } from "@material-ui/core";
import React, { useState, useEffect, useContext } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import {
    localforageSetItem,
    localforageClear,
    localforageGetItemAsync,
    localforageGetItem,
} from "oautils/oaForageUtils";
import { useSelector, useDispatch } from "react-redux";
import { setUserType } from "redux/action";
import { AppStateContext } from "layouts/AppStateProvider";
import WebApi from "api/ApiConstants";
import PageFooter from "./HomePageFooter";
import HomePageNavbar from "./HomePageNavbar";
import SearchForm from "./SearchForm";
import TopPromoSection from "./TopPromoSection";
import TopSection from "./TopSection";
import AboutUs from "./AboutUs";
import Testimonial from "./Testimonial";
import BlogSection from "./BlogSection";
import DestinationSection from "./DestinationSection";
import LoginSection from "./LoginSection";
import aboutussvg from "assets/triumphTravel/images/svg/Aboutus.svg";
import testimoniassvg from "assets/triumphTravel/images/svg/Testimonials.svg";
import { refreshUserSession } from "oautils/oaAuthUtils";
import OaLoading from "pages/components/OaLoading";
import SpecialOffers from "./SpecialOffers";

const useStyles = makeStyles(theme => ({
    root: {
        padding: 0,
        margin: 0,
        "& .temp-container": {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            "& img": {
                width: "100%",
                maxWidth: 1280,
            },
        },
        "& .loader-view": {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "fixed",
            flexDirection: "column",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 103,
            background: "rgba(0,0,0,0.5)",
            "& img": {
                height: "7rem",
            },
            "& .loader-text": {
                color: theme.palette.primary.main,
                fontWeight: 600,
                textAlign: "center",
                marginLeft: 10,
            },
        },
    },
}));

const HomePage = (props) => {

    const classes = useStyles();
    const isMounted = React.useRef(false);

    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [usertype, setUsertype] = useState(null);
    const { setUserTypeb2c } = React.useContext(AppStateContext);
    const [corporateType, setCorporateType] = useState(null);
    const [serviceOptions, setServiceOptions] = useState([]);
    const [showServices, setShowServices] = useState(
        props.hideServices ? props.hideServices : props.showServices
    );
    const [userLogin, setUserLogin] = useState(false);
    // const [userId,setUserId]  = useState(null);

    const LoaderView = () => {
        return (
            <div className="loader-view">
                <OaLoading loadertext="."/>
            </div>
        );
    };

    const getValuesFromLocalStorage = () => {
        setIsLoading(true)
        localforageGetItem("user-details", function (err, value) {

          //  console.log(value.userTypeAbv);

            if (value) {
                if (value.userTypeAbv == "R") {
                    setIsLoggedIn(true);
                }
                if (value.userTypeAbv == 'S') {
                    setUserLogin(true);
                }

                setShowServices(
                    value.userType == "distributor"
                        ? false
                        : showServices != null
                            ? showServices
                            : true
                );

                setServiceOptions(value.serviceOption);

                setUsertype(value.userType);
                setUserTypeb2c(value.userType)
                setCorporateType(value.corporateType);
                setIsLoading(false);
            } else {
                console.log("localforage error",err);
                setIsLoading(false);
            }
        });
    };

    const callbackRefreshToken = (flag) => { setIsLoading(flag) }

    useEffect(() => {
        isMounted.current = true;
        refreshUserSession(dispatch, callbackRefreshToken);

        return () => {
            isMounted.current = false;
        };
    }, []);

    React.useEffect(() => {
        if(isMounted.current) {
            getValuesFromLocalStorage();
        }
    }, [isMounted.current]);

    return(
        <div className={classes.root}>
            {isLoading ? <LoaderView />:
            <>
            <div className="col-2">
                <HomePageNavbar />
            </div>
            <div className="col-10">        
            <TopPromoSection isloggedin={isLoggedIn} />
            
            {usertype && (usertype === "agent" || usertype === "direct_agent") ?
                // <SearchForm />
                <Switch>
                    <Route
                        path="/:priTab/:secTab/:serialNo"
                        component={SearchForm}
                    />
                    <Route
                        path="/:priTab/:secTab"
                        component={SearchForm}
                    />
                    <Route
                        path="/:priTab"
                        component={SearchForm}
                    />
                    <Route
                        path="/"
                        component={SearchForm}
                    />
                </Switch>
                :
                <LoginSection />
            }
            <br/>
            {
                // console.log(isLoggedIn)
                userLogin && <SpecialOffers/>
                // <SpecialOffers/>
            }

            <AboutUs />

            {/* <div className="temp-container" style={{margin: "200px 0 90px"}}>
                <img src={aboutussvg} />
            </div> */}

            {/* <div className="temp-container" style={{margin: "150px 0 90px"}}>
                <img src={testimoniassvg} />
            </div> */}
            <Testimonial />
            
            <DestinationSection />
            {/* <BlogSection /> */}
            <PageFooter />
            </div>
            </>}
        </div>
    );
}

export default withRouter(HomePage);

import React, { useState, useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import {
  getQueryVar,
  getCurrentTab,
  getAgencyId,
} from "oautils/oaCommonUtils.js";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useMediaQuery } from "@material-ui/core";
// import PostLoginNavBar from "components/Navbars/PostLoginNavBar.js";
import PostLoginNavBar from "components/Navbars/PostLoginNavBar.TriumphTravels";
import PageFooter from "components/Footer/PageFooter.TriumphTravels";
import styles from "assets/jss/material-dashboard-pro-react/layouts/adminStyle.js";
// import PageFooter from "components/Footer/PageFooter.js";
import Container from "@material-ui/core/Container";

import BusSearchProgressPage from "pages/module/bus/BusSearchProgressPage";
import HotelBookingFormPage from "pages/module/hotel/HotelBookingFormPage";
import BusSearchResult from "pages/module/bus/BusSearchResult";
import BusPassengerDetails from "pages/module/bus/BusPassengerDetails";
import BusPassengerReview from "pages/module/bus/BusPassengerReview";

import BusPriceChanged from "pages/module/bus/BusPriceChanged";
import BusPaymentProgressPage from "pages/module/bus/BusPaymentProgressPage";
import BusTicket from "pages/module/bus/BusTicket";
import companylogo from "assets/triumphTravel/images/logo/logo.png";
import BusPaymentStatus from "pages/module/bus/BusPaymentStatus";
import ExtRedirBusBookProgress from "pages/module/bus/ExtRedirBusBookProgress";
import PricingPolicy from "components/LandingPage/TriumphTravel/siteinfo/PricingPolicy";
import TermsAndConditions from "components/LandingPage/TriumphTravel/siteinfo/TermsAndConditions";

const useStyles = makeStyles(styles);

export default function BusLayout(props) {
  const { ...rest } = props;
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  React.useEffect(() => {
    document.body.style.overflow = "unset";
    let qAgencyId = getAgencyId(props);
    setAgencyId(qAgencyId);
    return function cleanup() {};
  }, [agencyId, props]);

  const [agencyId, setAgencyId] = useState(undefined);
  const [width, setWidth] = useState(0);

  useEffect(() => {
    const newWidth = window.innerWidth;
    setWidth(newWidth);
    // console.log("meow",newHeight)
  });

  // let defaultTab = 'hotel';
  // let queryVar = getQueryVar({ ...props, q: 'priTab' });
  // let currentTab = getCurrentTab(queryVar, defaultTab);

  return (
    <div
      className={classes.wrapper}
      style={{ backgroundColor: "#fff", width: `100%`, height: `100%` }}
    >
      <PostLoginNavBar
        {...rest}
        isSearchPage={true}
        showServices={true}
        isTimerShow={true}
      />
      <Container
        fixed
        style={{
          marginTop: 90,
          marginBottom: 20,
          padding: isMobile && "0px 5px",
        }}
        maxWidth={false}
        disableGutters={true}
      >
        {/* <div className={classes.container}> */}
        <Switch>
          <Route
            path="/bus/search-progress"
            exact
            component={BusSearchProgressPage}
          />
          <Route path="/:priTab/bus-result" exact component={BusSearchResult} />
          <Route
            path="/:priTab/passenger-details"
            exact
          >
            <BusPassengerDetails tnc={TermsAndConditions} ppc={PricingPolicy} {...props} />
          </Route>
          <Route
            path="/:priTab/passenger-review"
            exact
            component={BusPassengerReview}
          />
          {/* <Route path="/bus/bus-price-changed" exact component={BusPriceChanged}/> */}
          <Route
            path="/:priTab/bus-payment-progress/:transactionId"
            exact
            component={ExtRedirBusBookProgress}
          />
          <Route
            path="/:priTab/bus-payment-progress"
            exact
            component={BusPaymentProgressPage}
          />
          <Route
            path="/:priTab/payment-status/:tid/:status"
            component={BusPaymentStatus}
          />
          <Route path="/:priTab/bus-ticket" exact component={BusTicket} />
          <Route
            path="/:priTab/bus-ticket"
            render={(prop) => (
              <BusTicket companylogo={companylogo} {...props} />
            )}
          />
        </Switch>
        {/* </div> */}
      </Container>
      <PageFooter white {...props} />
    </div>
  );
}

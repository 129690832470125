import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import {
  BrowserRouter,
  Switch,
  Redirect,
  MemoryRouter,
  Route,
  Router
} from "react-router-dom";
import "index.css";
import AuthLayout from "layouts/TriumphTravel/Auth";
import AdminLayout from "layouts/TriumphTravel/Admin.js";
import DmtLayout from "layouts/TriumphTravel/DmtLayout.js";
import BusLayout from "layouts/TriumphTravel/BusLayout.js";
import HotelLayout from "layouts/TriumphTravel/HotelLayout.js";
import RechargeLayout from "layouts/TriumphTravel/RechargeLayout.js";
import {
  createTheme,
  ThemeProvider as MuiThemeProvider,
} from "@material-ui/core/styles";
import LandingPageLayout from "layouts/TriumphTravel/LandingPageLayout.js";

// import PrivateRoute from './PrivateRoute';
// import PublicRoute from './PublicRoute';
import AgentRegistration from "pages/agent/AgentRegistration";
import "assets/scss/material-dashboard-pro-react.scss?v=1.8.0";
import "assets/css/custom-table.css";
import FlightTicket from "pages/report/flight/FlightTicketNoHeader";
import FlightTicketPrint from "pages/report/flight/print/FlightTicketPrint";
import GroupBooking from "pages/groupBooking/GroupBooking";
import "assets/css/css-variable.css";
import AppStateProvider from "layouts/AppStateProvider";
import OaScrollToTop from "components/OaScrollToTop";
import PackagesLayout from "layouts/TriumphTravel/PackagesLayout";

import { Provider } from "react-redux";
import { store, persistor } from "redux/store";
import { PersistGate } from "redux-persist/integration/react";
import LinearProgress from "@material-ui/core/LinearProgress";
import FlightLayout from "layouts/TriumphTravel/FlightLayout";
import PrintLayout from "layouts/TriumphTravel/PrintLayout.js";
import PreLoginLayout from "layouts/TriumphTravel/PreLoginLayout.js";
import B2clayout from "layouts/TriumphTravel/B2Clayout";

import PrivacyPolicy from "components/LandingPage/TriumphTravel/siteinfo/PrivacyPolicy";
import TermsAndConditions from "components/LandingPage/TriumphTravel/siteinfo/TermsAndConditions";
import CancellationAndRefund from "components/LandingPage/TriumphTravel/siteinfo/CancellationAndRefund";
import PricingPolicy from "components/LandingPage/TriumphTravel/siteinfo/PricingPolicy";
import CorporatePages from "layouts/TriumphTravel/CorporateLayout";
import LoginPage from "pages/corporate/LoginPage";
import { colors } from "assets/triumphTravel/js/ColorPalette";
import SpecialOffersLayout from "layouts/TriumphTravel/SpecialOffersLayout";
import { Helmet } from "react-helmet";
import { AppConstant } from "appConstant";
import OaLogout from "pages/components/OaLogout";
import DMTTrnCallback from "pages/module/dmt/DMTTrnCallback";

const hist = createBrowserHistory();
window.appNameContext = "psa";

const theme = createTheme(colors);

ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <Helmet>
      <title>{AppConstant.title}</title>
    </Helmet>
    <AppStateProvider>
      <Provider store={store}>
        <PersistGate loading={<LinearProgress />} persistor={persistor}>
          <BrowserRouter history={hist} basename="/">
            <OaScrollToTop>
              <Switch>
                <Route path="/prelogin" component={PreLoginLayout} />
                <Route path="/print" component={PrintLayout} />
                <Route path="/auth" component={AuthLayout} />
                <Route path="/admin" component={AdminLayout} />
                <Route path="/hotel" component={HotelLayout} />
                <Route path="/bus" component={BusLayout} />
                <Route path="/flight" component={FlightLayout} />
                <Route path="/recharge" component={RechargeLayout} />
                <Route path="/registration" component={AgentRegistration} />
                <Route path="/packages" component={PackagesLayout} />
                <Route
                  path="/flightticket/:ticketId/:isAddress/:isFare/:isPerPassenger/:isGST"
                  component={FlightTicketPrint}
                />
                <Route
                  path="/flightticket/:ticketId"
                  component={FlightTicket}
                />
                <Route path="/groupbooking" component={GroupBooking} />
                <Route path="/b2c" component={B2clayout} />
                <Route path="/privacypolicy" component={PrivacyPolicy} />
                <Route path="/terms_and_conditions" component={TermsAndConditions} />
                <Route path="/cancellation_and_refund" component={CancellationAndRefund} />
                <Route path="/pricing_policy" component={PricingPolicy} />
                <Route path="/corporate" component={CorporatePages} />
                <Route path="/corporate_login" component={LoginPage} />
                <Route path="/fd" component={SpecialOffersLayout} />
                <Route path="/logout" component={OaLogout} />
                <Route path="/moneytransfer/trn_callback" component={DMTTrnCallback} />
                <Route path="/" component={LandingPageLayout} />

                <Redirect from="/" to="/auth/loading-page" />
              </Switch>
            </OaScrollToTop>
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </AppStateProvider>
  </MuiThemeProvider>,
  document.getElementById("root")
);

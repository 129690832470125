import React from "react";
import WebApi from "api/ApiConstants";
import ReactTable from "react-table";
import ODTrnHistory from "./ODTrnHistory";
import ODHistory from "./ODHistory";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import { makeStyles, Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import { Close } from '@material-ui/icons';

const ODListTable = (props) => {
    const classes = useStyles();

    const [tabledata, setTabledata] = React.useState([]);
    const [rowCount, setRowCount] = React.useState(4);
    const [noDataText, setNoDataText] = React.useState(" ");
    const [loading, setLoading] = React.useState(true);
    const [finishedLoading, setFinishedLoading] = React.useState(false);

    const [odAction, setOdAction] = React.useState(" ");
    const [odId, setOdId] = React.useState(null);
    const [agentId, setAgentId] = React.useState(null);
    const [openOdDetails, setOpenOdDetails] = React.useState(false);
    const [openTxnHistory, setOpenTxnHistory] = React.useState(false);
    const dropDownMenu = ["Details", "Txn History"];
    const dropDownMenuPast = ["Details", "Txn History"];

    let actionList = [
        { label: "Details", value: "details" },
        { label: "Txn History", value: "txnHistory" }
    ];

    const executeOdAction = (action, data) => {
        setOdAction(action);
        //  console.log("action ",action);
        switch (action) {
            case "details":
            case "Details": {
                getOdDetails(data);
                break;
            }
            case "txnHistory":
            case "Txn History": {
                getTxnHistory(data);
                break;
            }
        }
    };

    const filterMethod = (filter, row, column) => {
        const id = filter.pivotId || filter.id;
        return row[id] !== undefined
            ? String(String(row[id]).toLowerCase()).startsWith(
                filter.value.toLowerCase()
            )
            : true;
    };

    const isValidDate = (dateStr) => {
        return !isNaN(Date.parse(dateStr))
    };

    const getOdDetails = (data) => {
        setOdId(data.uid);
        setAgentId(data.agencyId);

        setOpenOdDetails(true);
    };

    const getTxnHistory = (data) => {
        // console.log("data.uid ",data);
        setOdId(data.uid);
        setAgentId(data.agencyId);
        //setPartnerId(data.partnerId);
        setOpenTxnHistory(true);
    };

    const closeOdDetails = () => {
        setOpenOdDetails(false);
        setOdAction(" ");
    };

    const closeTxnHistory = () => {
        setOpenTxnHistory(false);
        setOdAction(" ");
    };

    const tableLoader = () => {
        WebApi.odListGet(props.searchParams, response => {
            // console.log("table response", response)
            //setTabledata(response.data.creditChild);
            //            setRowCount(response.data.creditChild.length === 0 ? 4 : response.data.creditChild.length);
            //            setNoDataText(response.data.creditChild.length === 0 ? "No results" : response.data.creditChild.length);
            setTabledata(response.data.odList);
            setRowCount(response.data.odList.length === 0 ? 4 : response.data.odList.length);
            setNoDataText(response.data.odList.length === 0 ? "No results" : response.data.odList.length);

        }, error => {
            console.log("error getting OD", error)
            setNoDataText("ERROR");
        });
        setLoading(false);
        setFinishedLoading(true);
    };

    const columns = [
        {
            Header: "OD ID#",
            accessor: "uid",
            width: 100,
            filterable: false,
            Cell: rowProp => {
                return (
                    <div style={{ height: 44, verticalAlign: "middle" }}>
                        <CustomDropdown
                            buttonText={rowProp.value}
                            buttonProps={{
                                fullWidth: true,
                                style: { height: 32, padding: 4 },
                                color: "rose",
                            }}
                            onClick={(event) => {
                                executeOdAction(event, rowProp.original);
                            }}
                            dropPlacement="top-start"
                            dropdownList={
                                //rowProp.original.activeFlag === "Y"
                                //? 
                                dropDownMenu
                                //: dropDownMenuPast
                            }
                        />
                    </div>
                )
            },
        },
        // {
        //     Header: "Select action",
        //     accessor: "validFrom",
        //     filterable: false,
        //     width: 120,
        //     Cell: rowProp => {
        //         return (
        //             <Select
        //                 fullWidth
        //                 variant="outlined"
        //                 value={odAction}
        //                 onChange={(e) => executeOdAction(e.target.value, rowProp.original)}
        //             >
        //                 <MenuItem value=" ">Select</MenuItem>
        //                 {actionList.map((val, idx) => 
        //                 <MenuItem value={val.value} key={idx}>{val.label}</MenuItem>)}
        //             </Select>
        //         );
        //     }
        // },
        {
            Header: "Agency ID",
            accessor: "agencyId",
            filterable: false,
            width: 100
        },
        {
            Header: "Agency Name",
            accessor: "agencyName",
            filterable: false,
            width: 260
        },
        {
            Header: "Valid From",
            accessor: "validFrom",
            filterable: false,
            width: 160
        },
        {
            Header: "Valid To",
            accessor: "validTo",
            filterable: false,
            width: 160
        },


        {
            Header: "OD Days",
            accessor: "days",
            filterable: false,
            width: 80
        },
        // {
        //     Header: "Credit Type",
        //     accessor: "creditType",
        //     filterable: false,
        //     width: 110
        // },
        {
            Header: "OD Amount (₹)",
            accessor: "creditAmount",
            filterable: false,
            width: 150
        },
        {
            Header: "OD Utilized (₹)",
            accessor: "creditUtilize",
            filterable: false,
            width: 150
        },
        {
            Header: "Deposits (₹)",
            accessor: "totalDepositAmount",
            filterable: false,
            width: 150
        },
        {
            Header: "Refunds (₹)",
            accessor: "totalRefundAmount",
            filterable: false,
            width: 150
        },
        {
            Header: "Wallet Topups (₹)",
            accessor: "totalTopupAmount",
            filterable: false,
            width: 150
        },
        {
            Header: "Available OD (₹)",
            accessor: "odAvailBalance",
            filterable: false,
            width: 150
        },
        {
            Header: "Wallet Balance (₹)",
            accessor: "walletBalance",
            filterable: false,
            width: 150
        },
        {
            Header: "Updated By",
            accessor: "createdBy",
            filterable: false,
            width: 120
        },
        // {
        //     Header: "Created At",
        //     accessor: "createdDate",
        //     filterable: false,
        //     width: 120
        // },

        // {
        //     Header: "Remarks",
        //     accessor: "remarks",
        //     filterable: false,
        //     width: 180
        // }
    ];

    React.useEffect(() => {
        tableLoader();
    }, []);

    return (
        <div className={classes.root}>
            <ReactTable
                columns={columns}
                data={tabledata}
                defaultFilterMethod={filterMethod}
                filterable={true}
                showPagination={false}
                minRows={rowCount}
                loading={loading}
                pageSize={rowCount}
                noDataText={noDataText}
                className="-striped -highlight"
            />
            {/* 
            <Dialog open={openOdDetails} onClose={closeOdDetails} PaperProps={{
                className: classes.dialogPaper
            }}
            fullScreen
            >
                <DialogTitle>
                    <span>OD Details</span>
                    <span className="close-btn-edged" onClick={closeOdDetails}>
                        <Close />
                    </span>
                </DialogTitle>
                <DialogContent>
                    <ODHistory
                        odId={odId}
                        agentId={agentId}
                        onCloseDetails={closeTxnHistory}
                        searchParams={props.searchParams}
                    />
                </DialogContent>
            </Dialog> */}

            <Dialog open={openOdDetails} onClose={closeOdDetails} PaperProps={{
                className: classes.dialogPaper
            }}
                fullScreen
            >
                <DialogTitle>
                    <span>OD Details</span>
                    <span className="close-btn-edged" onClick={closeOdDetails}>
                        <Close />
                    </span>
                </DialogTitle>
                <DialogContent>
                    <ODHistory
                        odId={odId}
                        agentId={agentId}
                        onCloseDetails={closeOdDetails}
                    />
                </DialogContent>
            </Dialog>



            <Dialog open={openTxnHistory} onClose={closeTxnHistory} PaperProps={{
                className: classes.dialogPaper
            }}
                fullScreen
            >
                <DialogTitle>
                    <span>Txn History Details</span>
                    <span className="close-btn-edged" onClick={closeTxnHistory}>
                        <Close />
                    </span>
                </DialogTitle>
                <DialogContent>
                    <ODTrnHistory
                        odId={odId}
                        agentId={agentId}
                        //partnerId={partnerId}

                        onCloseDetails={closeTxnHistory}
                    />
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default ODListTable;

const useStyles = makeStyles(theme => ({
    root: {
        "& .table-action-btn": {
            cursor: "pointer",
            background: theme.palette.buttons.primary,
            border: `solid 0.5px ${theme.palette.buttons.primary}`,
            "& .MuiButton-label": {
                color: theme.palette.buttons.primaryContrastText,
            },
            "&:hover": {
                background: theme.palette.buttons.primaryContrastText,
                "& .MuiButton-label": {
                    color: theme.palette.buttons.primary,
                },
            }
        }
    },
    dialogPaper: {
        fontFamily: theme.palette.font.secondary,
        "& .MuiDialogTitle-root": {
            position: "relative",
            "& .close-btn-edged": {
                position: "absolute",
                top: 5,
                right: 5,
                height: 20,
                width: 20,
                background: theme.palette.buttons.primary,
                borderRadius: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: 2,
                cursor: "pointer",
                "& .MuiSvgIcon-root": {
                    color: theme.palette.buttons.primaryContrastText
                },
                "&:hover": {
                    background: "red",
                    "& .MuiSvgIcon-root": {
                        color: "#fff"
                    }
                }
            },
        },
        "& .revoke-submit-btn": {
            cursor: "pointer",
            background: theme.palette.buttons.secondary,
            "& .MuiButton-label": {
                color: theme.palette.buttons.secondaryContrastText
            }
        },
        "& .form-group": {
            margin: "5px 0",
            "&.action-group": {
                display: "flex",
                justifyContent: "center"
            }
        },
        "& .od-root": {
            width: "90vw"
        }
    }
}));

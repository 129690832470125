import React, { useState, useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import {
  getQueryVar,
  getCurrentTab,
  getAgencyId,
} from "oautils/oaCommonUtils.js";
import { makeStyles } from "@material-ui/core/styles";
import PostLoginNavBar from "components/Navbars/PostLoginNavBar.TriumphTravels";
import styles from "assets/jss/material-dashboard-pro-react/layouts/adminStyle.js";
import PageFooter from "components/Footer/PageFooter.TriumphTravels";
import Container from "@material-ui/core/Container";

import WebCheckIn from "pages/module/flight/webCheckIn";
import AirlineContact from "pages/module/flight/airlineContact";
import SpecialOffersLayout from "./SpecialOffersLayout";
import FlightSearchProgress from "pages/module/flight/compact/FlightSearchProgress";
import FlightSearch from "pages/module/flight/compact/FlightSearch";
import FlightBookingProcess from "pages/module/flight/compact/FlightBookingProcess";
import FlightSearchComRes from "pages/module/flight/compact/FlightSearchComRes";
import FlightPaymentStatus from "pages/module/flight/compact/FlightPaymentStatus";
import ComLoader from "assets/img/loaders/clock_loader.gif";
import FlAvLoader from "assets/img/loaders/flight_avail_loader.gif";
import TermsAndConditions from "components/LandingPage/TriumphTravel/siteinfo/TermsAndConditions";
import PricingPolicy from "components/LandingPage/TriumphTravel/siteinfo/PricingPolicy";

// const useStyles = makeStyles(styles);

export default function FlightLayout(props) {
  const { ...rest } = props;
  const classes = useStyles();

  return (
    <>
      <PostLoginNavBar {...rest} isSearchPage={true} showServices={true} />
      <div style={{ marginBottom: 20 }}>
        <div className={classes.root}>
          <Switch>
            <Route path="/flight/search-progress" render={prop => <FlightSearchProgress {...prop} availoader={FlAvLoader} />} />
            <Route path="/flight/search-result" render={prop => <FlightSearch {...prop} comloader={ComLoader} />} />
            <Route path="/flight/webcheckin" component={WebCheckIn} />
            <Route path="/flight/booking">
              <FlightBookingProcess {...props} comloader={ComLoader} tnc={TermsAndConditions} ppc={PricingPolicy}/>
            </Route>
            <Route path="/flight/airlinecontacts" component={AirlineContact} />
            <Route path="/flight/international/search-result" render={prop => <FlightSearchComRes {...prop} comloader={ComLoader} />} />
            <Route path="/flight/payment-status/:tid" component={FlightPaymentStatus} />
          </Switch>
        </div>
      </div>
      <PageFooter white {...props}/>
    </>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.lightGrayDefault,
    color: theme.palette.background.lightGrayDefaultContrast,
    minHeight: "75vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  }
}));

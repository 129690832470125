import React from "react";
import OaCard from 'oahoc/OaCard';
import OaTabBar from 'oahoc/OaTabBar';
import ODListSearchForm from "./ODListSearchForm";
import ODListTable from "./ODListTable";
import dateFnsFormat from 'date-fns/format';
import { useHistory } from "react-router-dom";
import { localforageGetItem } from "oautils/oaForageUtils";

const ODList = (props) => {
    const history = useHistory();
    const [isLoading, setIsLoading] = React.useState(true);
    const [agentId, setAgentId] = React.useState(null);
    const isMounted = React.useRef(false);

    let tabs = [
        { name: 'activeodlist', label: 'Active ODs', url: '/admin/agents/od/list' },
    ];
    let currentTab = 'activeodlist';

    let initialParams = {
        userId: agentId,
        partnerId: 1002,
        activeFlag: "Y",
        endDate: dateFnsFormat(new Date(), "dd-MM-yyyy"),
        startDate: dateFnsFormat(new Date(), "dd-MM-yyyy"),
    };

    const [tableKey, setTableKey] = React.useState(0);
    const [searchParams, setSearchParams] = React.useState(initialParams);

    const getAgentDetails = () => {
        localforageGetItem("user-details", function(err, details) {
            if(details) {
                setAgentId(details.userId);
            }
            if(err) {
                console.log("error getting details from localforage", err);
            }
        });
    };

    React.useEffect(() => {
        isMounted.current = true;
        return () => {
            isMounted.current = false;
        };
    }, []);

    const ODListLoader = (searchParams) => {
        setSearchParams(searchParams);
        setTableKey(tableKey + 1);
    };

    React.useEffect(() => {
        if(isMounted.current) {
            getAgentDetails();
        }
    }, [isMounted.current]);

    React.useEffect(() => {
        if(agentId) {
            setSearchParams({...searchParams, userId: agentId});
            setTableKey(tableKey + 1);
            setIsLoading(false);
        }
    }, [agentId]);

    return(
        <>
        {!isLoading &&
            <OaCard className={'oa-card-primary'}>
                <OaTabBar tabs={tabs} currentTab={currentTab} type='primary'/>
                <OaCard> 
                    <ODListSearchForm 
                        target={ODListLoader} 
                        agentId={agentId} 
                        searchParams={searchParams}
                        setAgentId={setAgentId}
                    />
                </OaCard>
                <OaCard>
                    <ODListTable agentId={agentId} key={tableKey} searchParams={searchParams}/>
                </OaCard>
            </OaCard>
        }
        </>
    );
};

export default ODList;

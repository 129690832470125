import React, { useState } from "react";
import { Grid, Typography, Divider, useMediaQuery } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { COLORS, FONTS } from "assets/css/CssConstants";
import { AppConstant } from "appConstant";
import { withRouter } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    "& .highlight": {
      color: theme.palette.secondary.info,
    },
  },

  Grid: {
    background: theme.palette.background.default,
    marginTop: "20px",
    boxShadow: "0px 53px 99px -81px rgba(0,0,0,0.75)",
    padding: 30,
    borderRadius: 20,
  },
  // firstCard: {
  //     "& .MuiTypography-root": {
  //         color: COLORS.PRIMARY_BG_DARK,
  //     },
  // },
  sectionHeader: {
    color: `${theme.palette.primary.darkFadedText} !important`,
    fontWeight: 500,
    margin: "5px 0 30px",
  },
  sectionSubhead: {
    color: `${theme.palette.primary.darkText} !important`,
    fontWeight: 400,
  },
  sectionText: {
    color: `${theme.palette.primary.defaultText} !important`,
    fontWeight: 400,
    lineHeight: "27px",
  },
  sectionTextGrid: {
    margin: "15px 0",
    flexDirection: "column",
  },
  listText: {
    "& li": {
      color: theme.palette.primary.darkFadedText,
      fontWeight: 400,
      lineHeight: "27px",
    },
  },
  breadcrumbLink: {
    color: `${theme.palette.secondary.main} !important`,
    cursor: "pointer",
    "&:hover": {
      color: `${theme.palette.secondary.active} !important`,
    },
  },
}));

const Gap10 = () => {
  return <div className="gap-10" style={{ margin: 10, width: "100%" }}></div>;
};

const TermsAndConditions = (props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();

  return (
    <div
      className={classes.root}
      style={{
        marginBottom: 60,
        paddingLeft: isMobile ? 10 : "6%",
        paddingRight: isMobile && 10,
      }}
    >
      <Grid
        container
        spacing={isMobile ? 0 : 3}
        style={{ width: "100%" }}
        justifyContent="center"
      >
        <Grid item xs={12} md={10} style={{ paddingTop: "50px" }}>
          <a
            onClick={() => props.history.push("/")}
            className={classes.breadcrumbLink}
          >
            {/* <a href="/auth/login-page" className={classes.breadcrumbLink}> */}
            <i className="fa fa-home"></i> Home
          </a>
        </Grid>

        <Grid item xs={12} md={10}>
          <Grid
            container
            spacing={1}
            className={`${classes.firstCard} ${classes.Grid}`}
          >
            <Grid item md={12}>
              <Typography
                variant="h3"
                className={classes.sectionHeader}
                gutterBottom
              >
                TERMS & CONDITIONS:
              </Typography>
              <hr style={{ width: "100%" }} />
            </Grid>

            <Grid item md={12} className={classes.sectionTextGrid}>
            <Typography
                variant="body2"
                gutterBottom
                className={classes.sectionText}
              >
                This web site is maintained by {AppConstant.name} TRAVEL N MORE PVT
                LTD, a company incorporated and existing in accordance with the
                laws of India. When you access, browse or use this website you
                accept, without limitation or qualification, the terms and
                conditions set forth below.
              </Typography>

              <Gap10 />
              <Gap10 />
              <Gap10 />
            </Grid>

            <Grid item md={12} className={classes.sectionTextGrid}>
              <Typography
                variant="body2"
                gutterBottom
                className={classes.sectionText}
              >
                1. You shall not distribute, exchange, modify, sell or transmit
                anything you copy from this webite, including but not limited to
                any text, images, audio and video, for any business, commercial
                or public purpose.
              </Typography>

              <Gap10 />

              <Typography
                variant="body2"
                gutterBottom
                className={classes.sectionText}
              >
                2.If you comply with the terms of these Terms and Conditions of
                Use, TRIUMPHH TRAVEL N MORE PVT. LTD grants you a non-exclusive,
                non-transferable, limited right to enter, view and use the
                website
              </Typography>
              <Gap10 />
              <Typography
                variant="body2"
                gutterBottom
                className={classes.sectionText}
              >
                3.You agree not to interrupt or attempt to interrupt the
                operation of this website.
              </Typography>
              <Gap10 />
              <Typography
                variant="body2"
                gutterBottom
                className={classes.sectionText}
              >
                3.You agree not to interrupt or attempt to interrupt the
                operation of this website.
              </Typography>
              <Gap10 />
              <Typography
                variant="body2"
                gutterBottom
                className={classes.sectionText}
              >
                4.Certain areas of the website may only be available to
                registered members for access.
              </Typography>
              <Gap10 />
              <Typography
                variant="body2"
                gutterBottom
                className={classes.sectionText}
              >
                5.To become a registered member, you may be required to answer
                certain questions. Answers to such questions may be mandatory
                and/or optional. You represent and warrant that all information
                you supply to us, about yourself, and others, is true and
                accurate.
              </Typography>
              <Gap10 />
              <Typography
                variant="body2"
                gutterBottom
                className={classes.sectionText}
              >
                6. You shall not distribute, exchange, modify, sell or transmit
                anything you copy from this web site, including any text,
                images, audio and video, for any business, commercial or public
                purpose.
              </Typography>
              <Gap10 />
              <Typography
                variant="body2"
                gutterBottom
                className={classes.sectionText}
              >
                7. You cannot use the Content, except as specified herein. You
                agree to follow all instructions on this Site limiting the way
                you may use the Content
              </Typography>
              <Gap10 />
              <Gap10 />
              <Typography
                variant="body2"
                gutterBottom
                className={classes.sectionText}
              >
                8. Any unauthorized use of the Content may violate copyright
                laws, trademark laws, the laws of privacy and publicity, and
                civil and criminal statutes.
              </Typography>
              <Gap10 />
              <Typography
                variant="body2"
                gutterBottom
                className={classes.sectionText}
              >
                9. Defame, abuse, harass, stalk, threaten or otherwise violate
                the legal rights (such as rights of privacy and publicity) of
                others
              </Typography>
              <Gap10 />
              <Typography
                variant="body2"
                gutterBottom
                className={classes.sectionText}
              >
                10. If you send any communications or materials to the website
                by electronic mail or otherwise, including any comments, data,
                questions, suggestions, all such communications are, and will be
                treated by , as non-confidential.
              </Typography>
              <Gap10 />
            </Grid>

            <Grid item md={12} className={classes.sectionTextGrid}>
              <Typography
                variant="subtitle1"
                className={`${classes.sectionSubhead}`}
                gutterBottom
              >
                <span className=" highlight">Payment terms</span>
              </Typography>

              <ul className={classes.listText}>
                <li>
                  Payment due to Domestic/International Tickets for the weekly
                  credit to be made within 6 days of the closing of the week
                  (starting from 1st to 7th of the month) or as per the
                  Collection period notified by {AppConstant.name} PVT LTD from time
                  to time.
                </li>
                <li>
                  (a) Voiding would be as per the airline norms plus Rs.150/ -
                  per ticket as {AppConstant.name} N MORE PVT. LTD. RAF Charges. (b)
                  Refund charges would be as per the airline norms plus Rs.250/
                  - per ticket as {AppConstant.name} N MORE PVT. LTD. RAF Charges.
                </li>
                <li>
                  Service charges levied are to be collected from the customers
                  on our behalf.
                </li>
                <li>
                  (a) Any voidation/cancellation of the ticket will be
                  entertained only till 1800 Hrs. (b) If the payment is not made
                  on time {AppConstant.name} N MORE PVT LTD can anytime cancel all the
                  PNR’s/Tickets without any prior notice.
                </li>
                <li>
                  Any kind of Misuse of Airline/GDS PNR will solely be the
                  responsibility of the agent and the amount will be recovered
                  from the agent as an when received the debit note by the
                  Airline or by the GDS.
                </li>
                <li>
                  There will no adjusting of the refund in the payment schedule.
                  The refund will be given back to the agent whenever we have
                  received it from the airline.
                </li>
                <li>
                  TDS will be deducted as per the law. TDS will be deducted on
                  all commissions/incentives. The TDS certificate will be issued
                  in the next Financial Year.
                </li>
                <li>
                  {" "}
                  <strong>Responsibility for Taxes.</strong> Travel Agent
                  acknowledges that, regardless of any action taken by{" "}
                  {AppConstant.name} N MORE PVT LTD, the ultimate responsibility for
                  Tax Collected at Source (‘TCS’) under section 206C(1G) or any
                  other taxes as may be legally applicable and filing
                  requirements in connection with the same thereof (hereinafter
                  collectively referred to as ‘Tax Obligations’), is and shall
                  remain his (or her) responsibility.
                </li>
                <li>
                  The credit limit will be on a weekly basis depending upon the
                  certain guarantees
                </li>
                <li>
                  Any other refunds or unadjusted credits received from
                  suppliers (including hotels, airlines etc.) and not claimed or
                  pursued by travel agents or suppliers within 2 years of
                  receipt of such amounts by Company, shall be deemed to have
                  been forfeited
                </li>
                <li>
                  That our Client shall grant a limited and non-transferable
                  right to you, the Noticees, to use the website of our Client-{" "}
                  <a href="www.triumphhtravel.com" target="_blank">
                    www.triumphhtravel.com
                  </a>{" "}
                  for conducting online booking of the various travel services
                  as made available by our Client to you.
                </li>
              </ul>
            </Grid>

            <Grid
              item
              md={12}
              className={classes.sectionTextGrid}
              style={{ textAlign: "center" }}
            >
              <a
                onClick={() => props.history.push("/")}
                className={classes.breadcrumbLink}
              >
                {/* <a href="/auth/login-page" className={classes.breadcrumbLink}> */}
                <i className="fa fa-home"></i> Home
              </a>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
export default withRouter(TermsAndConditions);

import React, { useState } from "react";
import { Grid, Typography, Divider, useMediaQuery } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { AppConstant } from "appConstant";
import { withRouter } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        "& .highlight": {
            color: theme.palette.secondary.info,
        },
    },

    Grid: {

        background: theme.palette.background.default,
        marginTop: "20px",
        boxShadow: "0px 53px 99px -81px rgba(0,0,0,0.75)",
        padding: 30,
        borderRadius: 20,

    },
    // firstCard: {
    //     "& .MuiTypography-root": {
    //         color: COLORS.PRIMARY_BG_DARK,
    //     },
    // },
    sectionHeader: {
        color: `${theme.palette.primary.darkFadedText} !important`,
        fontWeight: 500,
        margin: "5px 0 30px",
    },
    sectionSubhead: {
        color: `${theme.palette.primary.darkText} !important`,
        fontWeight: 400,
    },
    sectionText: {
        color: `${theme.palette.primary.defaultText} !important`,
        fontWeight: 400,
        lineHeight: "27px",
    },
    sectionTextGrid: {
        margin: "15px 0",
        flexDirection: "column",
    },
    listText: {
        "& li": {
            color: theme.palette.primary.darkFadedText,
            fontWeight: 400,
            lineHeight: "27px",
        }
    },
    breadcrumbLink: {
        color: `${theme.palette.secondary.main} !important`,
        cursor: "pointer",
        "&:hover": {
            color: `${theme.palette.secondary.active} !important`
        }
    }
}));

const Gap10 = () => {
    return (
        <div className="gap-10" style={{ margin: 10, width: "100%" }}></div>
    );
}

const CancellationAndRefund = (props) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const classes = useStyles();

    return (
        <div className={classes.root} style={{ marginBottom: 60, paddingLeft: isMobile ? 10 : '6%', paddingRight: isMobile && 10 }}>
            <Grid container spacing={isMobile ? 0 : 3} style={{ width: "100%" }} justifyContent="center">
                <Grid item xs={12} md={10} style={{ paddingTop: "50px" }}>
                    <a onClick={() => props.history.push("/")} className={classes.breadcrumbLink}>
                        {/* <a href="/auth/login-page" className={classes.breadcrumbLink}> */}
                        <i className="fa fa-home"></i> Home
                    </a>
                </Grid>

                <Grid item xs={12} md={10}>
                    <Grid
                        container
                        spacing={1}
                        className={`${classes.firstCard} ${classes.Grid}`}
                    >
                        <Grid item md={12}>
                            <Typography
                                variant="h3"
                                className={classes.sectionHeader}
                                gutterBottom
                            >
                                CANCELLATION & REFUND POLICY:
                            </Typography>
                            <hr style={{ width: "100%" }} />
                        </Grid>

                        <Grid item md={12} className={classes.sectionTextGrid}>
                            <Typography
                                variant="body2"
                                gutterBottom
                                className={classes.sectionText}
                            >
                                The travel site {AppConstant.domainAlias}, site maintained by {AppConstant.name}, a company incorporated and existing compliant with the laws of the Republic of India. By accessing or using this site or using any of its services, you are agreed on cancellation and refund policy mentioned below, including any supplementary guidelines and future modifications.
                            </Typography>

                            <Gap10 />

                            <Typography
                                variant="body2"
                                gutterBottom
                                className={classes.sectionText}
                            >
                                {AppConstant.name} may change, alter or remove any part of these below written Terms and Conditions without any prior notice. Any changes done in this Cancellation and Refund Policy will be applicable the moment they are posted on the site. You need to re-check the "Cancellation & Refund Policy" link to be fully aware of the entire scenario.
                            </Typography>
                        </Grid>

                        <Grid item md={12} className={classes.sectionTextGrid}>
                            <Typography
                                variant="subtitle1"
                                className={classes.sectionSubhead}
                                gutterBottom
                            >
                                Flight Amendment & Cancellation Policy:
                            </Typography>

                            <Typography
                                variant="body2"
                                gutterBottom
                                className={classes.sectionText}
                            >
                                Every booking made on Triumphh Travel is subject to cancellation charges levied by the airline, which may vary with respect to flight and booking class.
                            </Typography>

                            <Gap10 />

                            <Typography
                                variant="body2"
                                gutterBottom
                                className={classes.sectionText}
                            >
                                {AppConstant.domainAlias} levies negligible amount of <span className="highlight">Rs.</span> per passenger/per sector for domestic and <span className="highlight">Rs.</span> per passenger/per sector for international air tickets as cancellation service charges.
                            </Typography>

                            <Gap10 />

                            <Typography
                                variant="body2"
                                gutterBottom
                                className={classes.sectionText}
                            >
                                <span className="highlight">Zero Cancellation-Fee product premium charged at the time of booking is non-refundable.</span>
                            </Typography>

                            <Gap10 />

                            <Typography
                                variant="body2"
                                gutterBottom
                                className={classes.sectionText}
                            >
                                Convenience fees are non-refundable in all cases.
                            </Typography>

                            <Gap10 />

                            <Typography
                                variant="body2"
                                gutterBottom
                                className={classes.sectionText}
                            >
                                Some bookings may be non-refundable as per the specific airline’s policy.
                            </Typography>

                            <Gap10 />

                            <Typography
                                variant="body2"
                                gutterBottom
                                className={classes.sectionText}
                            >
                                Some bookings cannot be cancelled partially as per the specific airline’s policy.
                            </Typography>

                            <Gap10 />

                            <Typography
                                variant="body2"
                                gutterBottom
                                className={classes.sectionText}
                            >
                                Cancellations can be made online or offline through My Booking section or by sending an email to Triumphh Travel.
                            </Typography>

                            <Gap10 />

                            <Typography
                                variant="body2"
                                gutterBottom
                                className={classes.sectionText}
                            >
                                We assist you in making various amendments for bookings. In few cases, you need to contact the airline directly.
                            </Typography>

                            <Gap10 />

                            <Typography
                                variant="body2"
                                gutterBottom
                                className={classes.sectionText}
                            >
                                Amendment charges on {AppConstant.domainName} are subject to change as per the policy of the airline, which varies due to flight timings and booking class.
                            </Typography>

                            <Gap10 />

                            <Typography
                                variant="body2"
                                gutterBottom
                                className={classes.sectionText}
                            >
                                Amendment charges on {AppConstant.domainName} are subject to change as per the policy of the airline, which varies due to flight timings and booking class.
                            </Typography>

                            <Gap10 />

                            <Typography
                                variant="body2"
                                gutterBottom
                                className={classes.sectionText}
                            >
                                To cancel the tickets within 24 Hrs of the journey, Triumphh Travel recommends customer to contact directly to the Airlines and after that, send the cancellation request on our website for refunds
                            </Typography>

                            <Gap10 />

                            <Typography
                                variant="body2"
                                gutterBottom
                                className={classes.sectionText}
                            >
                                <span className="highlight">
                                    In case of no-show or unutilized bookings, the customers are required to make requests for any valid refunds, as per the defined policies, within 90 days from the travel date in case of air/bus tickets. No refund would be payable for requests raised after the expiry of 90 days of travel date/ check-in as aforementioned and all unclaimed amounts for such no-show or unutilized bookings shall accordingly be deemed to have been forfeited.
                                </span>
                            </Typography>

                            <Gap10 />

                            <Typography
                                variant="body2"
                                gutterBottom
                                className={classes.sectionText}
                            >
                                For cancellation requests received, the refund shall be processed at the earliest once request is received. In case of direct cancellation through airlines, refunds shall be processed post deduction of airline charges along with our service charge. All refunds shall be processed subject to processing of refunds by the respective airline/service provider. Please note convenience fee charged at the time of booking is not refundable.
                            </Typography>
                        </Grid>

                        <Grid item md={12} className={classes.sectionTextGrid}>
                            <Typography
                                variant="subtitle1"
                                className={classes.sectionSubhead}
                                gutterBottom
                            >
                                Refunds in cases where Airlines is declared as Insolvent or Bankrupt:
                            </Typography>

                            <Typography
                                variant="body2"
                                gutterBottom
                                className={classes.sectionText}
                            >
                                Triumphh Travel is not be liable to pay any refunds whatsoever in cases where the Airlines stops its operations or declares itself as insolvent.
                            </Typography>

                            <Gap10 />

                            <Typography
                                variant="body2"
                                gutterBottom
                                className={classes.sectionText}
                            >
                                The customers or clients or agents shall not hold the Triumphh Travel liable to pay the refunds as assured at the time of booking of ticket in cases where the Airlines stops its operation or declares itself as insolvent
                            </Typography>

                            <Gap10 />

                            <Typography
                                variant="body2"
                                gutterBottom
                                className={classes.sectionText}
                            >
                                On the basis of assurance given by airlines, Triumphh Travel may sometimes refund amount to the customer but Triumphh Travel reserves the right to recover the refunded amount on event of Airlines getting shut down/non-operational/bankrupt.
                            </Typography>
                        </Grid>

                        <Grid item md={12} className={classes.sectionTextGrid}>
                            <Typography
                                variant="subtitle1"
                                className={classes.sectionSubhead}
                                gutterBottom
                            >
                                Refund Policy:
                            </Typography>

                            <Typography
                                variant="body2"
                                gutterBottom
                                className={classes.sectionText}
                            >
                                Triumphh Travel will process the refund only after getting cancellation request/claim from the customer/travel agent/corporate company through mybooking section or email.
                            </Typography>

                            <Gap10 />

                            <Typography
                                variant="body2"
                                gutterBottom
                                className={classes.sectionText}
                            >
                                <span className="highlight">
                                    Refund against the cancellation of air tickets will be credited directly in customer’s mode of payment after receiving the refund amount from the Airlines
                                </span>
                            </Typography>

                            <Gap10 />

                            <Typography
                                variant="body2"
                                gutterBottom
                                className={classes.sectionText}
                            >
                                Triumphh Travel will process refund within <span clasName="highlight">72 hours</span> only after receiving it from the concerned Airlines/bus service.
                            </Typography>

                            <Gap10 />

                            <Typography
                                variant="body2"
                                gutterBottom
                                className={classes.sectionText}
                            >
                                Due to Banking Procedures, Refund in credit cards may take duration of <span clasName="highlight">72 hours</span> while it may take <span clasName="highlight">7 working days in case of net banking transactions</span>.
                            </Typography>
                        </Grid>

                        <Grid item md={12} className={classes.sectionTextGrid} style={{ textAlign: "center" }}>
                            <a onClick={() => props.history.push("/")} className={classes.breadcrumbLink}>
                                {/* <a href="/auth/login-page" className={classes.breadcrumbLink}> */}
                                <i className="fa fa-home"></i> Home
                            </a>
                        </Grid>
                    </Grid>
                </Grid>

            </Grid>
        </div>
    );
};
export default withRouter(CancellationAndRefund);

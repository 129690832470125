import React from "react";
import { makeStyles } from "@material-ui/core";
import FoodAndCulture from "assets/triumphTravel/images/food-and-culture.jpg";
import Camping from "assets/triumphTravel/images/camping.jpg";
import CorporateTrips from "assets/triumphTravel/images/corporate-trips.jpg";
import DestinationWedding from "assets/triumphTravel/images/destination-wedding.jpg";
import FamilyVaction from "assets/triumphTravel/images/family-vacation.jpg";
import WildlifeSafari from "assets/triumphTravel/images/wildlife-safari.jpg";

const useStyles = makeStyles(theme => ({

    root: {
        padding: 0,
        margin: "90px 0 90px",
        display: "flex",
        justifyContent: "center",
        width: "100%",
        fontFamily: theme.palette.font.primary,
        "& .section-container": {
            maxWidth: 1280,
            "& .caption": {
                padding: "0 12px",
                "& p": {
                    color: theme.palette.secondary.main,
                    fontWeight: 600,
                }
            },
            [theme.breakpoints.down(960)]: {
                textAlign: 'center',
            },
        },
        "& .section-header": {
            padding: "0 10px",
            marginBottom: 30,
            "& p": {
                padding: "0 0 0 0",
                fontSize: 36,
                lineHeight: 1,
                color: theme.palette.primary.defaultText,
                fontWeight: 700,
            },
        },
        "& .blogs-container": {
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
            alignItems: "center",
            [theme.breakpoints.down(960)]: {
                justifyContent: "center",
            },
        },
        "& .blog-item": {
            width: 353,
            margin: 10,
        },
        "& .blog-image": {
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            height: 212,
        },
        "& .blog-text-content": {
            textAlign: "center",
            backgroundColor: theme.palette.primary.main,
            padding: 20,
            "& .blog-text-title": {
                color: theme.palette.secondary.main,
                textTransform: "uppercase",
                fontWeight: 600,
                fontSize: 12,
            },
            "& .blog-text-caption": {
                color: theme.palette.primary.lightText,
                fontSize: 16,
            },
            "& .blog-text": {
                color: theme.palette.primary.disabled,
                fontSize: 12,
            },
        },
    },
}));

const BlogSection = () => {

    const classes = useStyles();
    return(
        <div className={classes.root}>
            <div className="section-container">
                <div className="caption">
                    <p>Experience</p>
                </div>
                <div className="section-header">
                    <p>Read our newest blog <br/>post right away</p>
                </div>
                <div className="blogs-container">
                    <div className="blog-item">
                        <div className="blog-image" style={{backgroundImage: `url(${FoodAndCulture})`}}></div>
                        <div className="blog-text-content">
                            <p className="blog-text-title">
                                Food and culture
                            </p>
                            <p className="blog-text-caption">
                                Lifetime Experience
                            </p>
                            <p className="blog-text">
                               
                            </p>
                        </div>
                    </div>

                    <div className="blog-item">
                        <div className="blog-image" style={{backgroundImage: `url(${Camping})`}}></div>
                        <div className="blog-text-content">
                            <p className="blog-text-title">
                                Camping
                            </p>
                            <p className="blog-text-caption">
                                Close group in remote places
                            </p>
                            <p className="blog-text">
                               
                            </p>
                        </div>
                    </div>

                    <div className="blog-item">
                        <div className="blog-image" style={{backgroundImage: `url(${CorporateTrips})`}}></div>
                        <div className="blog-text-content">
                            <p className="blog-text-title">
                                Corporate Trips
                            </p>
                            <p className="blog-text-caption">
                                Product launch celebrations
                            </p>
                            <p className="blog-text">
                               
                            </p>
                        </div>
                    </div>

                    <div className="blog-item">
                        <div className="blog-image" style={{backgroundImage: `url(${DestinationWedding})`}}></div>
                        <div className="blog-text-content">
                            <p className="blog-text-title">
                                Destination Wedding
                            </p>
                            <p className="blog-text-caption">
                                Large Family in an old castle
                            </p>
                            <p className="blog-text">
                                
                            </p>
                        </div>
                    </div>

                    <div className="blog-item">
                        <div className="blog-image" style={{backgroundImage: `url(${FamilyVaction})`}}></div>
                        <div className="blog-text-content">
                            <p className="blog-text-title">
                                Family Vacation
                            </p>
                            <p className="blog-text-caption">
                                Most awaiting moments
                            </p>
                            <p className="blog-text">
                               
                            </p>
                        </div>
                    </div>

                    <div className="blog-item">
                        <div className="blog-image" style={{backgroundImage: `url(${WildlifeSafari})`}}></div>
                        <div className="blog-text-content">
                            <p className="blog-text-title">
                                Wildlife Safari
                            </p>
                            <p className="blog-text-caption">
                                Adventures and thrilling
                            </p>
                            <p className="blog-text">
                                
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BlogSection;
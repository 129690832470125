import React, { useState, useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import {
  getQueryVar,
  getCurrentTab,
  getAgencyId,
} from "oautils/oaCommonUtils.js";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useMediaQuery } from "@material-ui/core";

import PostLoginNavBar from "components/Navbars/PostLoginNavBar.TriumphTravels.js";
import styles from "assets/jss/material-dashboard-pro-react/layouts/adminStyle.js";
import PageFooter from "components/Footer/PageFooter.TriumphTravels.js";
import Container from "@material-ui/core/Container";

import HotelSearchProcessPage from "pages/module/hotel/HotelSearchProcessPage";
import HotelSearchResultPage from "pages/module/hotel/HotelSearchResultPage";
import HotelBookingFormPage from "pages/module/hotel/HotelBookingFormPage";
import HotelDetailPage from "pages/module/hotel/HotelDetailPage";
import HotelBookingReview from "pages/module/hotel/HotelBookingReview";
import HotelPaymentProgressPage from "pages/module/hotel/HotelPaymentProgressPage";
import HotelVoucher from "pages/module/hotel/HotelVoucher";
import companylogo from "assets/triumphTravel/images/logo/logo.png";
import HotelPaymentStatus from "pages/module/hotel/HotelPaymentStatus";
import ExtRedirHotelBookProgress from "pages/module/hotel/ExtRedirHotelBookProgress";
import PricingPolicy from "components/LandingPage/TriumphTravel/siteinfo/PricingPolicy";
import TermsAndConditions from "components/LandingPage/TriumphTravel/siteinfo/TermsAndConditions";

const useStyles = makeStyles(styles);

export default function HotelLayout(props) {
  const { ...rest } = props;
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  React.useEffect(() => {
    document.body.style.overflow = "unset";
    let qAgencyId = getAgencyId(props);
    setAgencyId(qAgencyId);
    return function cleanup() {};
  }, [agencyId, props]);

  const [agencyId, setAgencyId] = useState(undefined);

  let defaultTab = "hotel";
  let queryVar = getQueryVar({ ...props, q: "priTab" });
  let currentTab = getCurrentTab(queryVar, defaultTab);

  return (
    <div className={classes.wrapper} style={{ backgroundColor: "#FFFFFF" }}>
      <PostLoginNavBar
        isTimerShow={true}
        {...rest}
        isSearchPage={true}
        showServices={true}
      />
      <Container
        fixed
        style={{
          marginBottom: 20,
          paddingTop: 80,
          paddingLeft: 5,
          paddingRight: 5,
          maxWidth: "100%",
          width: "100%",
        }}
      >
        <div className={isMobile ? "" : classes.container}>
          <Switch>
            <Route
              path="/hotel/search"
              exact
              component={HotelSearchProcessPage}
            />
            <Route
              path="/hotel/searchResult"
              exact
              component={HotelSearchResultPage}
            />
            <Route
              path="/hotel/HotelDetail"
              exact
              component={HotelDetailPage}
            />
            <Route
              path="/hotel/bookingForm"
              exact
            >
              <HotelBookingFormPage tnc={TermsAndConditions} ppc={PricingPolicy} {...props}/>
            </Route>
            <Route
              path="/hotel/reviewbooking"
              exact
              component={HotelBookingReview}
            />
            <Route
              path="/hotel/hotelpaymentprogress/:transactionId"
              component={ExtRedirHotelBookProgress}
            />
            <Route
              path="/hotel/hotelpaymentprogress"
              component={HotelPaymentProgressPage}
            />
            <Route
              path="/hotel/hotelvoucher"
              render={(prop) => (
                <HotelVoucher companylogo={companylogo} {...props} />
              )}
            />
            <Route
              path="/hotel/payment-status/:tid/:status"
              component={HotelPaymentStatus}
            />
          </Switch>
        </div>
      </Container>
      <PageFooter white {...props} />
    </div>
  );
}

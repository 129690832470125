import React, {useState} from "react";
import { Button, Grid, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { COLORS, FONTS } from "assets/css/CssConstants";

import WebApi from "api/ApiConstants";
import {apiCall} from 'oautils/oaDataUtils';
import OaFormAlerts from 'pages/components/OaFormAlerts';
import {scrollToTop} from 'oautils/oaCommonUtils';

const useStyles = makeStyles(theme => ({

    contactFormRoot: {
        "& .contact-form-container": {
            "& .MuiInputBase-root ":{
                color: theme.palette.primary.lightText,
            },
            "& .contact-form-row": {
                display: "grid",
                gap: "10px",
                "& label": {
                    color: theme.palette.primary.lightText,
                    "& b": {
                        color: theme.palette.primary.danger,
                    }
                },
                "& .MuiInput-underline": {
                    borderBottom: `solid 1px ${theme.palette.secondary.lightText}`
                },
                "& .MuiInput-underline:before": {
                    borderBottom: `solid 1px ${theme.palette.secondary.lightText}`
                },
                "& .MuiInput-underline:after": {
                    borderBottom: `solid 1px ${theme.palette.secondary.active}`
                },
            },
            "& .row-100": {
                gridTemplateColumns: "100%",
            },
            "& .row-50": {
                gridTemplateColumns: "50% 50%",
                [theme.breakpoints.down(600)]: {
                    gridTemplateColumns: "100%",
                },
            },
            "& .flex-column": {
                display: "flex",
                flexDirection: "column",
            },
            "& .contact-form-item": {
                gridColumn: "1 / span 2",
                padding: 10,
                "&.action-item": {
                    [theme.breakpoints.down(960)]: {
                        textAlign: "center",
                    },
                }
            },
            "& .contact-form-item-left": {
                gridColumn: "1 / span 1",
                padding: 10,
                [theme.breakpoints.down(600)]: {
                    gridColumn: "1 / span 2",
                },
            },
            "& .contact-form-item-right": {
                gridColumn: "2 / span 1",
                padding: 10,
                [theme.breakpoints.down(600)]: {
                    gridColumn: "1 / span 2",
                },
            },
            "& .action-item button": {
                width: 140,
                color: theme.palette.secondary.defaultText,
                background: theme.palette.secondary.lightText,
            }
        }
    }
}));

const ContactUsForm = (props) => {
    const [isSaving, setIsSaving] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isError, setIsError] = useState(false);
    const [infoText, setInfoText] = useState('');
    const [isLoading, setIsLoading] = useState(true);

    const classes = useStyles();

    



    return (
        <div className={classes.contactFormRoot}>
            <OaFormAlerts 
                    isSaving={isSaving} 
                    isSuccess={isSuccess} 
                    isError={isError} 
                    infoText={infoText} 
                    setIsError={setIsError} 
                    setIsSuccess={setIsSuccess} 
                />

            <Formik
                initialValues={{
                    firstname: "",
                    lastname: "",
                    phone: "",
                    email: "",
                    message: "",
                    partner_id:1001,
                }}
                validationSchema={Yup.object().shape({
                    firstname: Yup.string().required("First Name is Required"),
                    email: Yup.string().email('Must be a valid email').max(255).required("e-Mail address is Required"),
                    message: Yup.string().required("Message/Comment is Required"),
                })}
                onSubmit={(values,  {setSubmitting}) => {
                    // console.log("values", values);
                   setIsError(false);
                    setIsSuccess(false);
                    setSubmitting(true);
                    setIsSaving(true);
                    scrollToTop(0);             
                    // console.log("The search params are ", searchParams);
                    apiCall(WebApi.addConcatus, values, (response) => {
                          setIsError(!response.success);
                          setIsSuccess(response.success);
                          setSubmitting(false);
                          setIsSaving(false);
                          setInfoText(response.message);
                        //   if(response.success === true) {
                        //       setInfoText('Agency successfully updated');
                        //       // enableReinitialize=true;
                        //       // resetForm(false);
                        //   }
                      });
                }}
            >
                {({ values, errors, touched, handleChange, setFieldValue }) =>
                <Form>
                    <div className="contact-form-container">
                        <div className="contact-form-row row-50">
                            <div className="contact-form-item-left flex-column">
                                <label>First Name <b>*</b></label>
                                <TextField
                                    name="firstname"
                                    value={values.firstname}
                                    onChange={(event) => {
                                        setFieldValue("firstname", event.target.value)
                                    }}
                                    fullWidth
                                    placeholder="Enter First Name"
                                />
                                <ErrorMessage
                                    name="firstname"
                                    component="div"
                                    className="error"
                                />
                            </div>
                            <div className="contact-form-item-right flex-column">
                            <label>Last Name</label>
                                <TextField
                                    name="lastname"
                                    value={values.lastname}
                                    onChange={(event) => {
                                        setFieldValue("lastname", event.target.value)
                                    }}
                                    fullWidth
                                    placeholder="Enter Last Name"
                                />
                            </div>
                        </div>

                        <div className="contact-form-row row-50">
                            <div className="contact-form-item-left flex-column">
                                <label>Phone</label>
                                <TextField
                                    name="phone"
                                    value={values.phone}
                                    onChange={(event) => {
                                        setFieldValue("phone", event.target.value)
                                    }}
                                    type="number"
                                    fullWidth
                                    placeholder="Enter Phone Number"
                                />
                            </div>
                            <div className="contact-form-item-right flex-column">
                                <label>Email <b>*</b></label>
                                <TextField
                                    name="email"
                                    value={values.email}
                                    onChange={(event) => {
                                        setFieldValue("email", event.target.value)
                                    }}
                                    fullWidth
                                    placeholder="Enter e-Mail Address"
                                />
                                <ErrorMessage
                                    name="email"
                                    component="div"
                                    className="error"
                                />
                            </div>
                        </div>

                        <div className="contact-form-row row-100">
                            <div className="contact-form-item flex-column">
                                <label>Comment or Message <b>*</b></label>
                                <TextField
                                    name="message"
                                    value={values.message}
                                    onChange={(event) => {
                                        setFieldValue("message", event.target.value)
                                    }}
                                    multiline
                                    rows={4}
                                    fullWidth
                                    placeholder="Enter Your Message/Comment"
                                />
                                <ErrorMessage
                                    name="message"
                                    component="div"
                                    className="error"
                                />
                            </div>
                        </div>

                        <div className="contact-form-row row-100">
                            <div className="contact-form-item action-item">
                                <Button
                                    type="submit"
                                >
                                    Submit
                                </Button>
                            </div>
                        </div>
                    </div>
                </Form>}
            </Formik>
        </div>
    );
}

export default ContactUsForm;

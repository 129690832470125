import { makeStyles } from "@material-ui/core";
import React from "react"

const useStyles = makeStyles(theme => ({

    root: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        width: "100%",
    },
}));
const UtilitySection = (props) => {

    const classes = useStyles();

    return(
        <div className={classes.root}>
            <h1>Coming Soon!</h1>
        </div>
    );
}

export default UtilitySection;
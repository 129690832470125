import React, { useState } from "react";
import { Grid, Typography, Divider, useMediaQuery } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { COLORS, FONTS } from "assets/css/CssConstants";
import { AppConstant } from "appConstant";
import { withRouter } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        "& .highlight": {
            color: theme.palette.secondary.info,
        },
    },

    Grid: {

        background: theme.palette.background.default,
        marginTop: "20px",
        boxShadow: "0px 53px 99px -81px rgba(0,0,0,0.75)",
        padding: 30,
        borderRadius: 20,

    },
    // firstCard: {
    //     "& .MuiTypography-root": {
    //         color: COLORS.PRIMARY_BG_DARK,
    //     },
    // },
    sectionHeader: {
        color: `${theme.palette.primary.darkFadedText} !important`,
        fontWeight: 500,
        margin: "5px 0 30px",
    },
    sectionSubhead: {
        color: `${theme.palette.primary.darkText} !important`,
        fontWeight: 400,
    },
    sectionText: {
        color: `${theme.palette.primary.defaultText} !important`,
        fontWeight: 400,
        lineHeight: "27px",
    },
    sectionTextGrid: {
        margin: "15px 0",
        flexDirection: "column",
    },
    listText: {
        "& li": {
            color: theme.palette.primary.darkFadedText,
            fontWeight: 400,
            lineHeight: "27px",
        }
    },
    breadcrumbLink: {
        color: `${theme.palette.secondary.main} !important`,
        cursor: "pointer",
        "&:hover": {
            color: `${theme.palette.secondary.active} !important`
        }
    }
}));

const Gap10 = () => {
    return(
        <div className="gap-10" style={{margin: 10, width: "100%"}}></div>
    );
}

const PrivacyPolicy = (props) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const classes = useStyles();

    return (
        <div className={classes.root} style={{ marginBottom: 60, paddingLeft: isMobile ? 10 : '6%', paddingRight: isMobile && 10 }}>
            <Grid container spacing={isMobile ? 0 : 3} style={{ width: "100%" }} justifyContent="center">
                <Grid item xs={12} md={10} style={{ paddingTop: "50px" }}>
                    <a onClick={() => props.history.push("/")} className={classes.breadcrumbLink}>
                    {/* <a href="/auth/login-page" className={classes.breadcrumbLink}> */}
                        <i className="fa fa-home"></i> Home
                    </a>
                </Grid>

                <Grid item xs={12} md={10}>
                    <Grid
                        container
                        spacing={1}
                        className={`${classes.firstCard} ${classes.Grid}`}
                    >
                        <Grid item md={12}>
                            <Typography
                                variant="h3"
                                className={classes.sectionHeader}
                                gutterBottom
                            >
                                PRIVACY POLICY:
                            </Typography>
                            <hr style={{ width: "100%" }} />
                        </Grid>

                        <Grid item md={12} className={classes.sectionTextGrid}>
                            <Typography
                                variant="body2"
                                gutterBottom
                                className={classes.sectionText}
                            >
                               We at {AppConstant.name} N MORE PVT LTD understand the value of our customer’s privacy and undertakes measures of keeping safe and protecting their personal information that is being shared with us. The Privacy Policy is applicable to the user who avails any service (s) offered by {AppConstant.name} N MORE PVT LTDthrough any of its online channels including website, app, mobile site and offline channels. If you disagree with the Privacy Policy mentioned here we request you to not access or use our website or any other sales channels. This Privacy Policy does not apply to any third-party website(s), mobile site or apps even if their website/services is linked to our website. It is recommended that a user goes through the privacy statements/policies of any third-party with whom they interact through hyperlink(s) provided on website.
                            </Typography>
                        </Grid>

                        <Grid item md={12} className={classes.sectionTextGrid}>
                            <Typography
                                variant="subtitle1"
                                className={classes.sectionSubhead}
                                gutterBottom
                            >
                                Personal Information We Collect of the User:
                            </Typography>

                            <Typography
                                variant="body2"
                                gutterBottom
                                className={classes.sectionText}
                            >
                                 {AppConstant.name} provides services such as air travel booking and bus tickets booking. To provide the services we collect information as mentioned below to fulfil our legal obligations as well as our obligations towards the third-parties as per our User Agreement.
                            </Typography>

                            <ul className={classes.listText}>
                                <li>
                                    Name
                                </li>
                                <li>
                                    Address
                                </li>
                                <li>
                                    Date of birth
                                </li>
                                <li>
                                    Passport number
                                </li>
                                <li>
                                    Email address
                                </li>
                                <li>
                                    Phone number
                                </li>
                                <li>
                                    Frequent Flyer Numbers
                                </li>
                                <li>
                                    IP address
                                </li>
                            </ul>
                        </Grid>

                        <Grid item md={12} className={classes.sectionTextGrid}>
                            <Typography
                                variant="subtitle1"
                                className={classes.sectionSubhead}
                                gutterBottom
                            >
                                How We Use the Information:
                            </Typography>
                            
                            <Typography
                                variant="body2"
                                gutterBottom
                                className={classes.sectionText}
                            >
                                The information shared by a user is used to register on the website and complete the order of the service(s) requested on t{AppConstant.domanName}We never share this information with any third-parties except to the details required to complete the order. Occasionally, we also use this information to inform our users/customers of any promotional offers being run on our website, mobile site or app. Each promotional email sent to the customer/user will include an option to opt out from receiving such emails in the future. We do not disclose any personal information that can be used to identify an individual. 
                            </Typography>
                        </Grid>

                        <Grid item md={12} className={classes.sectionTextGrid}>
                            <Typography
                                variant="subtitle1"
                                className={classes.sectionSubhead}
                                gutterBottom
                            >
                                Data Security
                            </Typography>
                            
                            <Typography
                                variant="body2"
                                gutterBottom
                                className={classes.sectionText}
                            >
                                To prevent unauthorized access, maintain data accuracy, and ensure the correct use of information, we have put in place appropriate physical, electronic, and managerial procedures to safeguard and secure the information we collect online.
                            </Typography>
                        </Grid>

                        <Grid item md={12} className={classes.sectionTextGrid}>
                            <Typography
                                variant="subtitle1"
                                className={classes.sectionSubhead}
                                gutterBottom
                            >
                                How You Can Access or Correct Your Information
                            </Typography>
                            
                            <Typography
                                variant="body2"
                                gutterBottom
                                className={classes.sectionText}
                            >
                                You can access all your personally identifiable information that we collect online and maintain by logging in to your account. All your information is safe and accessible only through the login username and password created by you
                            </Typography>
                        </Grid>

                        <Grid item md={12} className={classes.sectionTextGrid}>
                            <Typography
                                variant="subtitle1"
                                className={classes.sectionSubhead}
                                gutterBottom
                            >
                          Contact Us
                            </Typography>
                            
                            <Typography
                                variant="body2"
                                gutterBottom
                                className={classes.sectionText}
                            >
                                <span className="highlight">
                                In case you have any questions or concerns about these privacy policies, please call us at or send us an email at  <a href="www.triumphhtravel.com" target="_blank">online@triumphhtravel.com</a> 
                                </span>
                            </Typography>
                        </Grid>

                        <Grid item md={12} className={classes.sectionTextGrid} style={{ textAlign: "center" }}>
                            <a onClick={() => props.history.push("/")} className={classes.breadcrumbLink}>
                            {/* <a href="/auth/login-page" className={classes.breadcrumbLink}> */}
                                <i className="fa fa-home"></i> Home
                            </a>
                        </Grid>
                    </Grid>
                </Grid>

            </Grid>
        </div>
    );
};
export default withRouter(PrivacyPolicy);

import React, { useState, useEffect } from 'react';
import { Switch, Route, Redirect } from "react-router-dom";
import { getQueryVar, getCurrentTab, getAgencyId } from 'oautils/oaCommonUtils.js';
import { makeStyles, useTheme } from "@material-ui/core/styles";
import PostLoginNavBar from "components/Navbars/PostLoginNavBar.TriumphTravels";
import styles from "assets/jss/material-dashboard-pro-react/layouts/adminStyle.js";
import PageFooter from "components/Footer/PageFooter.TriumphTravels.js";
import Container from '@material-ui/core/Container';
import Voucher from "pages/voucher/Voucher";
import HotelVoucher from "pages/module/hotel/HotelVoucher";
import companylogo from "assets/triumphTravel/images/logo/logo.png";
import BusTicket from "pages/module/bus/BusTicket";

import FlightTicket from "pages/report/flight/FlightTicketNoHeader";

const useStyles = makeStyles(styles);

export default function PrintLayout(props) {
  const { ...rest } = props;
  const classes = useStyles();
  React.useEffect(() => {
    document.body.style.overflow = "unset";
    let qAgencyId = getAgencyId(props);
    setAgencyId(qAgencyId);
    return function cleanup() { };
  }, [agencyId, props]);

  const [agencyId, setAgencyId] = useState(undefined);

  let defaultTab = 'hotel';
  let queryVar = getQueryVar({ ...props, q: 'priTab' });
  let currentTab = getCurrentTab(queryVar, defaultTab);
  const theme = useTheme();

  return (
    <div className={classes.wrapper} style={{ backgroundColor: theme.palette.background.default, width: `100%`, height: `100%` }}>
      <PostLoginNavBar {...rest} isSearchPage={true} showServices={true} />
      <Container fixed style={{ margin: 0, padding: 0, marginBottom: 20, marginTop: 80 }}>
        <div className={classes.container}>
          <Switch>
            <Route
              path="/print/flightticket/:ticketId"
              render={prop => <FlightTicket companylogo={companylogo} {...prop}/>}
            />
            <Route
              path="/print/busticket/:ticketId"
              render={prop => <BusTicket companylogo={companylogo} {...prop}/>}
            />
            <Route
              path="/print/hotelticket/:ticketId"
              render={prop => <HotelVoucher companylogo={companylogo} {...prop}/>}
            />
            <Route path="/print/voucher/:type/:ref" 
              companylogo={companylogo} 
              render={prop => <Voucher companylogo={companylogo} {...prop}/>} />
          </Switch>
        </div>
      </Container>
      <PageFooter white {...props} />
    </div>
  );
}

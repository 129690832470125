export const theme = {
    paracolor: "#1374B3",
    pricecolor: "#EC6161",
    headcolor: "#1374B3",
    titlefont: "bold",
    textcase: "uppercase",
    datacolor: "white",
    infocolor: "#9B9B9B",
    fontstyle: "Helvetica Neue, Helvetica, Arial, sans-serif"
    
}
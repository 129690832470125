import React, { useRef, useEffect, useState, useContext } from "react";
import { AppStateContext } from "layouts/AppStateProvider";
import {
  localforageSetItem,
  localforageGetItem,
  localforageClear,
} from "oautils/oaForageUtils";
import { apiCall } from "oautils/oaDataUtils";
import WebApi from "api/ApiConstants";
import { makeStyles, useTheme, useMediaQuery } from "@material-ui/core";
import { useHistory, withRouter } from "react-router-dom";
import companyLogo from "assets/triumphTravel/images/company_logo.png";
import TopSection from "./TopSection";
import { MenuOutlined } from "@material-ui/icons";
import { RefreshRounded } from "@material-ui/icons";
import { currencyFormatter } from "oautils/oaCommonUtils";

const HomePageNavbar = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const isMounted = useRef(false);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [accountAnchorEl, setAccountAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [corpName, setCorpName] = useState("");
  const [corpIdentity, setCorpIdentity] = useState("");
  const [accountBalance, setAccountBalance] = useState("");
  const [showServices, setShowServices] = useState(
    props.hideServices ? props.hideServices : props.showServices
  );
  const [scrollPosition, setScrollPosition] = useState(0);
  const { secLogo } = useContext(AppStateContext);
  const [serviceOptions, setServiceOptions] = useState([]);
  const isMenuOpen = Boolean(anchorEl);
  const isAccountMenuOpen = Boolean(accountAnchorEl);
  const [showSupport, setShowSupport] = React.useState(false);
  const [anchorEll, setAnchorEll] = React.useState(null);
  const [userType, setUserType] = useState(null);
  const [anchorElLog, setAnchorElLog] = React.useState(null);
  const openLog = Boolean(anchorElLog);
  const { setSecLogo } = useContext(AppStateContext);
  const idLog = openLog ? "simple-popover" : undefined;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(960));
  const { setUserTypeb2c } = React.useContext(AppStateContext);
  const [corporateType, setCorporateType] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const oaNavBarRootRef = useRef();
  const oaNavBarBgRef = useRef();
  const oaNavMenuRef = useRef();

  const getValuesFromLocalStorage = () => {
    localforageGetItem("user-details", function (err, value) {
      if (value) {
        if (value.userTypeAbv == "R") {
          setIsLoggedIn(true);
        }

        setShowServices(
          value.userType == "distributor"
            ? false
            : showServices != null
              ? showServices
              : true
        );
        setCorpName(value.corpname);
        setCorpIdentity(value.userIdentity);
        // setAccountBalance(value.cashBalance);
        setServiceOptions(value.serviceOption);

        // console.log("serviceOptions", value.serviceOption);

        setUserType(value.userType);
        setUserTypeb2c(value.userType);
        // setCorporateType(value.corporateType);
      }
    });
  };

  const refreshBalance = () => {
    setAccountBalance(0);
    apiCall(WebApi.getDashboardData, {}, function (response) {
      let mainAccountBalance = response.data.mainAccountBalance;
      setAccountBalance(mainAccountBalance);
      localforageGetItem("user-details", function (err, value) {
        localforageSetItem("user-details", {
          ...value,
          cashBalance: mainAccountBalance,
        });
      });
    });
  };

  const logOut = (event) => {
    localforageClear(() => {
      WebApi.getAgentInfo({ userType: "G" }, (response) => {
        if (response != null && response.success === true) {
          localforageClear(() => {
            localforageSetItem("user-id", 0);

            const loginPagePath =
              corporateType && corporateType != "corporate"
                ? "/auth/login-page"
                : "/corporate_login";

            localforageSetItem("user-details", response.data, () => {
              localforageSetItem("access-key", response.accesskey, () => {
                history.push("/flights");
                window.location.reload();
              });
            });
          });
        }
      });
    });
  };

  const logOutB2C = (event) => {
    localforageClear(() => {
      WebApi.getAgentInfo({ userType: "G" }, (response) => {
        if (response != null && response.success === true) {
          localforageClear(() => {
            localforageSetItem("user-id", 0);

            localforageSetItem("user-details", response.data, () => {
              localforageSetItem("access-key", response.accesskey, () => {
                history.push("/flights");
                window.location.reload();
              });
            });
          });
        }
      });
    });
  };

  const openDashboard = (event) => {
    history.push("/admin/dashboard");
  };

  const openDashboardB2c = (event) => {
    history.push("/b2c/mybookings");
  };

  useEffect(() => {
    if (mobileMenuOpen) {
      oaNavMenuRef &&
        oaNavMenuRef.current &&
        oaNavMenuRef.current.classList.add("oa-mobile-menu-show");
    } else {
      oaNavMenuRef &&
        oaNavMenuRef.current &&
        oaNavMenuRef.current.classList.remove("oa-mobile-menu-show");
    }
  }, [mobileMenuOpen]);

  useEffect(() => {
    isMounted.current = true;

    window.addEventListener("scroll", (event) => {
      if (window.scrollY >= 80) {
        oaNavBarRootRef &&
          oaNavBarRootRef.current &&
          oaNavBarRootRef.current.classList.add("oa-navbar-sticky");
        oaNavBarBgRef &&
          oaNavBarBgRef.current &&
          oaNavBarBgRef.current.classList.add("oa-primary-bg");
      } else {
        oaNavBarRootRef &&
          oaNavBarRootRef.current &&
          oaNavBarRootRef.current.classList.remove("oa-navbar-sticky");
        oaNavBarBgRef &&
          oaNavBarBgRef.current &&
          oaNavBarBgRef.current.classList.remove("oa-primary-bg");
      }
    });

    if (userType === "agent" || userType === "direct_agent") {
      refreshBalance();
    }

    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (isMounted.current) {
      getValuesFromLocalStorage();
    }
  }, [isMounted.current]);

  return (
    <>
      <TopSection />
      <div className={classes.root} ref={oaNavBarRootRef}>
        <div className="oa-navbar-container" ref={oaNavBarBgRef}>
          <div className="oa-navbar">
            <div className="oa-navbar-brand-wrapper">
              <a style={{cursor: "pointer"}}>
                <img src={companyLogo} onClick={() => {
                  history.push("/paygoal-demo");
                }}/>
              </a>
            </div>

            <div className="oa-mobile-menu-toggler-wrapper">
              <a
                className="oa-mobile-menu-toggler"
                onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
              >
                <MenuOutlined />
              </a>
            </div>

            <div className="oa-navbar-menu-wrapper">
              <ul className="oa-navbar-menu" ref={oaNavMenuRef}>
                {userType === "b2c_user" && (
                  <li className="mobile-user-li">
                    <a className="mu-li-item">
                      {corpName}({corpIdentity})
                    </a>
                    <a
                      className="mu-li-item"
                      onClick={(event) => openDashboardB2c(event)}
                    >
                      Dashboard
                    </a>
                    <a
                      className="mu-li-item"
                      onClick={(event) => logOutB2C(event)}
                    >
                      Sign out
                    </a>
                  </li>
                )}
                {(userType === "agent" || userType === "direct_agent") && (
                  <li className="mobile-user-li">
                    <a className="mu-li-item">
                      {corpName}({corpIdentity})
                    </a>
                    <span className="mu-li-item">
                      Balance:&nbsp;&nbsp;
                      <a
                        onClick={(event) => refreshBalance()}
                        className="balance-link"
                      >
                        <RefreshRounded />
                        &nbsp;{currencyFormatter(accountBalance)}
                      </a>
                    </span>
                    <a
                      className="mu-li-item"
                      onClick={(event) => openDashboard(event)}
                    >
                      Dashboard
                    </a>
                    <a
                      className="mu-li-item"
                      onClick={(event) => logOut(event)}
                    >
                      Sign out
                    </a>
                  </li>
                )}
                {!userType && (
                  <li className="mobile-user-li">
                    <a
                      className="mu-li-item"
                      onClick={() => history.push("/")}
                    >
                      Sign in
                    </a>
                    <a
                      className="mu-li-item"
                      onClick={() =>
                        history.push("/prelogin/registration")
                      }
                    >
                      New User?
                    </a>
                  </li>
                )}
                <li>
                  <a onClick={() => history.push("/flights")}>
                    Home
                  </a>
                </li>
                {(userType === "agent" || userType === "direct_agent") &&
                  serviceOptions &&
                  serviceOptions.map((val, ind) => (
                    <li key={ind}>
                      <a
                        onClick={() =>
                          history.push("/" + getServicePath(val.serviceValue))
                        }
                      >
                        {val.serviceName}
                      </a>
                    </li>
                  ))}
                {(userType === "agent" || userType === "direct_agent") && (
                  <li>
                    <a onClick={() => history.push("/fd/hotdeals")}>
                      Hot deals
                    </a>
                  </li>
                )}
                {/* <li>
                                <a href="">Flights</a>
                            </li>
                            <li>
                                <a href="">Hotels</a>
                            </li> */}
                {/* <li>
                  <a onClick={() => history.push("/b2c/aboutus")}>
                    About Us
                  </a>
                </li> */}
                {/* <li>
                                <a href="">Blog</a>
                            </li> */}
                {/* <li>
                  <a onClick={() => history.push("/b2c/contactus")}>
                    Contact
                  </a>
                </li> */}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomePageNavbar;

const getServicePath = (serviceName) => {
  let servicePath = "";
  switch (serviceName) {
    case "flights": {
      servicePath = "flights";
      break;
    }

    case "buses": {
      servicePath = "buses";
      break;
    }

    case "hotels": {
      servicePath = "hotels";
      break;
    }

    case "recharge": {
      servicePath = "recharges";
      break;
    }

    case "insurance": {
      servicePath = "insurances";
      break;
    }

    case "moneytransfer": {
      servicePath = "moneytransfer";
      break;
    }

    case "pan": {
      servicePath = "pancard";
      break;
    }

    default: {
      servicePath = "flights";
      break;
    }
  };
  return servicePath;
};

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
    margin: 0,
    position: "fixed",
    top: 50,
    width: "100%",
    zIndex: 9,
    [theme.breakpoints.down(960)]: {
      top: 0,
      background: theme.palette.background.primary,
    },
    "& .oa-navbar-container": {
      padding: "0 30px 0 30px",
      margin: 0,
      position: "relative",
      [theme.breakpoints.down(1441)]: {
        padding: "0 80px 0 80px",
      },
      [theme.breakpoints.down(768)]: {
        padding: "0 20px",
      },
    },
    "& .oa-navbar": {
      display: "flex",
      alignItems: "center",
      flexDirection: "row",
      width: "100%",
    },
    "&.oa-navbar-sticky": {
      top: "0 !important",
    },
    "& .oa-primary-bg": {
      backgroundColor: theme.palette.primary.main,
      transition: "ease-in-out .8s",
    },
    "& .oa-navbar-brand-wrapper": {
      color: theme.palette.primary.lightText,
      marginRight: "auto",
      marginTop: 8,
      marginBottom: 8,
      "& a": {
        color: theme.palette.primary.lightText,
        textDecoration: "none",
      },
      "& img": {
        height: 63,
      },
    },
    "& .oa-navbar-menu-wrapper": {
      marginLeft: "auto",
    },
    "& .oa-navbar-menu": {
      display: "flex",
      flexDirection: "row",
      listStyle: "none",
      "& li": {
        margin: "0 2vw",
        textTransform: "uppercase",
        [theme.breakpoints.down(960)]: {
          "&:hover,:focus": {
            background: theme.palette.primary.main,
            "& a": {
              color: theme.palette.secondary.main,
            },
          },
        },
      },
      "& a": {
        color: theme.palette.primary.lightText,
        textTransform: "uppercase",
        cursor: "pointer",
        [theme.breakpoints.down(1441)]: {
          fontSize: 12,
        },
      },
      "&.oa-mobile-menu-show": {
        display: "flex",
        flexDirection: "column",
        background: theme.palette.background.darkDefault,
        position: "absolute",
        left: 0,
        right: 0,
        top: 65,
        padding: "0 0 15px",
        "& .mobile-user-li": {
          display: "flex",
          flexWrap: "wrap",
          color: theme.palette.primary.shadedLightText,
          justifyContent: "space-evenly",
          alignItems: "center",
          border: `solid 1px ${theme.palette.secondary.main}`,
          margin: "0 0 8px",
          "&:hover,:focus": {
            background: theme.palette.background.darkDefault,
          },
          "& .mu-li-item": {
            margin: 10,
            "&:hover,:focus, a:hover, a:focus": {
              color: theme.palette.secondary.main,
            },
          },
        },
        "& .MuiSvgIcon-root": {
          fontSize: 12,
        },
      },
      [theme.breakpoints.down(960)]: {
        display: "none",
        "& li": {
          margin: "8px 0",
          textAlign: "center",
        },
      },
    },
    "& .oa-mobile-menu-toggler-wrapper": {
      display: "none",
      position: "absolute",
      right: 80,
      [theme.breakpoints.down(960)]: {
        display: "block",
      },
      [theme.breakpoints.down(768)]: {
        right: 20,
      },
    },
    "& .oa-mobile-menu-toggler": {
      cursor: "pointer",
      color: theme.palette.primary.lightText,
    },
    "& .mobile-user-li": {
      display: "none",
    },
  },
}));

import React from "react"
import { Switch, Route, Redirect } from "react-router-dom";
import HotelPackages from "pages/module/packages/HotelPackages";
import HotelPackageDetail from "pages/module/packages/HotelPackageDetail";
import PackageInfo from "pages/module/packages/PackageInfo";
import PostLoginNavBar from "components/Navbars/PostLoginNavBar.TriumphTravels";
import PageFooter from "components/Footer/PageFooter.TriumphTravels";
import Container from '@material-ui/core/Container';

const PackagesLayout=(props)=>{
    const { ...rest } = props;
    return(
        <div>
             <PostLoginNavBar {...rest} isSearchPage={true} showServices={true} />
             <div style={{ background: "#f5f5f5", paddingTop: 70 }}>
             <Switch>
            <Route path="/packages/categories" exact component={HotelPackages}/>
            <Route path="/packages/details" exact component ={HotelPackageDetail}/>
            <Route path ="/packages/packageinfo" exact component={PackageInfo}/>
            </Switch>
             </div>
           
            <PageFooter white {...props}/>
        </div>
    )
}

export default PackagesLayout;
import React from "react";
import { makeStyles } from "@material-ui/core";
import MoroccoDest from "assets/triumphTravel/images/morocco-dest.jpg";
import DubaiDest from "assets/triumphTravel/images/dubai-dest.jpg";
import ItalyDest from "assets/triumphTravel/images/italy-dest.jpg";
import BhutanDest from "assets/triumphTravel/images/bhutan-dest.jpg";
import ArrowRight from "assets/triumphTravel/images/icons/arrow-right.svg";

const useStyles = makeStyles(theme => ({

    root: {
        padding: 0,
        margin: "90px 0 90px",
        display: "flex",
        justifyContent: "center",
        width: "100%",
        fontFamily: theme.palette.font.primary,
        "& .section-container": {
            maxWidth: 1280,
            "& .caption": {
                padding: "0 12px",
                "& p": {
                    color: theme.palette.secondary.main,
                    fontWeight: 600,
                }
            },
            [theme.breakpoints.down(1112)]: {
                textAlign: "center",
            },
        },
        "& .section-header": {
            padding: "0 10px",
            marginBottom: 30,
            "& p": {
                padding: "0 0 0 0",
                fontSize: 36,
                lineHeight: 1,
                color: theme.palette.primary.defaultText,
                fontWeight: 700,
            },
            [theme.breakpoints.down(1112)]: {
                textAlign: "center",
            },
        },
        "& .contents-container": {
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
            alignItems: "center",
            [theme.breakpoints.down(1112)]: {
                justifyContent: "center",
            },
        },
        "& .content-item": {
            width: 536,
            margin: 10,
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            [theme.breakpoints.down(440)]: {
                width: "100%",
                justifyContent: "center",
                flexWrap: "wrap",
            },
        },
        "& .content-image": {
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            width: 168,
            height: 190,
            [theme.breakpoints.down(440)]: {
                width: "100%",
            }
        },
        "& .content-text-content": {
            textAlign: "left",
            backgroundColor: theme.palette.primary.main,
            width: 368,
            height: 190,
            padding: 20,
            position: "relative",
            [theme.breakpoints.down(440)]: {
                width: "100%",
            },
            "& .content-text-title": {
                color: theme.palette.secondary.main,
                textTransform: "uppercase",
                fontWeight: 600,
                fontSize: 12,
            },
            "& .content-text-caption": {
                color: theme.palette.primary.lightText,
                fontSize: 16,
            },
            "& .content-text": {
                color: theme.palette.primary.disabled,
                fontSize: 12,
                color: theme.palette.secondary.main,
            },
            "& .content-review": {
                color: theme.palette.primary.disabled,
                position: "absolute",
                width: 154,
                bottom: 10,
                left: 20,
            },
            "& .right-link": {
                position: "absolute",
                width: 60,
                height: 60,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                background: theme.palette.background.dark,
                bottom: 0,
                right: 0,
                cursor: "pointer",
            },
        },
    },
}));

const DestinationSection = () => {

    const classes = useStyles();
    return(
        <div className={classes.root}>
            <div className="section-container">
                <div className="caption">
                    <p>Tours</p>
                </div>
                <div className="section-header">
                    <p>Destination List <br/>Go exotic Places</p>
                </div>
                <div className="contents-container">
                    
                    <div className="content-item">
                        <div className="content-image" style={{backgroundImage: `url(${MoroccoDest})`}}></div>
                        <div className="content-text-content">
                            <p className="content-text-title">
                                Morocco
                            </p>
                            <p className="content-text-caption">
                                Desert Safari and Culture
                            </p>
                            <p className="content-text">
                                4 tours
                            </p>
                            <p className="content-review">
                                10 reviews | 4.5 rating
                            </p>
                            <a className="right-link">
                                <img src={ArrowRight} />
                            </a>
                        </div>
                    </div>

                    <div className="content-item">
                        <div className="content-image" style={{backgroundImage: `url(${DubaiDest})`}}></div>
                        <div className="content-text-content">
                            <p className="content-text-title">
                                Dubai
                            </p>
                            <p className="content-text-caption">
                                Night life with Adventure
                            </p>
                            <p className="content-text">
                                12 tours
                            </p>
                            <p className="content-review">
                                10 reviews | 4.5 rating
                            </p>
                            <a className="right-link">
                                <img src={ArrowRight} />
                            </a>
                        </div>
                    </div>

                    <div className="content-item">
                        <div className="content-image" style={{backgroundImage: `url(${ItalyDest})`}}></div>
                        <div className="content-text-content">
                            <p className="content-text-title">
                                Italy
                            </p>
                            <p className="content-text-caption">
                                Romantic City Walk
                            </p>
                            <p className="content-text">
                                6 tours
                            </p>
                            <p className="content-review">
                                10 reviews | 4.5 rating
                            </p>
                            <a className="right-link">
                                <img src={ArrowRight} />
                            </a>
                        </div>
                    </div>

                    <div className="content-item">
                        <div className="content-image" style={{backgroundImage: `url(${BhutanDest})`}}></div>
                        <div className="content-text-content">
                            <p className="content-text-title">
                                Bhutan
                            </p>
                            <p className="content-text-caption">
                                Mesmerising Surroundings
                            </p>
                            <p className="content-text">
                                2 tours
                            </p>
                            <p className="content-review">
                                10 reviews | 4.5 rating
                            </p>
                            <a className="right-link">
                                <img src={ArrowRight} />
                            </a>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    );
}

export default DestinationSection;
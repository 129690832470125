import React, { useState, useEffect } from 'react';
import OaCard from 'oahoc/OaCard';
import OperatorSearchForm from './OperatorSearchForm';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import { apiCall } from 'oautils/oaDataUtils';
import WebApi from "api/ApiConstants";
import { GET_FILE_UPLOAD } from "api/ApiConstants";
import OaLoading from 'pages/components/OaLoading';
import { useMediaQuery } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
    root: {
        // display: 'flex',
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        // '& > *': {
        //     margin: theme.spacing(1),
        // },
        "& .MuiTabs-flexContainer": {
            backgroundColor: theme.palette.background.primary,
        },
        "& .MuiTab-root": {
            backgroundColor: theme.palette.background.primary,
        },
        "& .MuiOutlinedInput-notchedOutline": {
            border: "none",
            borderBottom: `solid 1px ${theme.palette.primary.main}`,
            borderRadius: 0,
        },
        "& .MuiButton-root": {
            background: theme.palette.secondary.main,
            color: theme.palette.primary.darkText,
        },
        "& .MuiInputBase-root": {
            // "& .MuiInputBase-input": {
            //     paddingBottom: 6,
            // },
            "&:hover .MuiOutlinedInput-notchedOutline": {
                borderRadius: 0,
                borderBottom: `solid 2px ${theme.palette.primary.defaultText}`,
            },
        },
        "& .MuiTabs-root": {
            [theme.breakpoints.down(960)]: {
                width: "98vw",
            }
        },
        "& .MuiTabs-scrollable": {
            width: "100%",
        },
        "& .MuiTabScrollButton-root": {
            [theme.breakpoints.down(960)]: {
                width: 50,
            },
        },
        "& .MuiAppBar-root": {
            background: theme.palette.background.primary,
        },
        "& .oa-tabs-primary1": {
            "& .MuiTab-wrapper": {
                [theme.breakpoints.down(600)]: {
                    fontSize: "10px !important",
                },
            },
            "& img": {
                [theme.breakpoints.down(600)]: {
                    height: 18.5,
                    width: 23.5,
                },
            },
        },
        "& .MuiBox-root": {
           [theme.breakpoints.down(768)]: {
            padding: "10px 15px",
           }, 
        },
    },
    cardRoot: {
        "& .oa-card-body": {
            [theme.breakpoints.down(768)]: {
                padding: 0,
            },
        },
    },
    small: {
        width: theme.spacing(3),
        height: theme.spacing(3),
    },
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
    indicator: {
        backgroundColor: 'white',
    }
}));


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}



const RechargeSection = (props) => {

    const classes = useStyles();
    const [value, setValue] = useState(0);
    const [rechargeType, setRechargeType] = useState([]);
    const [isloading, setIsloading] = useState(true);

    const [type, setType] = useState('');
    const [operators, setOperators] = useState([]);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down(600));

    const handleChange = (event, newTabIndex) => {
        setValue(newTabIndex);
        setOperators(rechargeType[newTabIndex].subCategory);
        setType(rechargeType[newTabIndex].categoryCode);
    }

    useEffect(() => {
        setIsloading(true);
        apiCall(WebApi.getRechargeType, {}, (response) => {
            if (response.data.category) {
                response.data.category.sort(function (a, b) {
                    if (a.id < b.id) return -1;
                    if (a.id > b.id) return 1;
                    return 0;
                });
                setRechargeType(response.data.category);
                setType(response.data.category[0].categoryCode);
                setOperators(response.data.category[0].subCategory);
                setIsloading(false);
            }
        });
    }, []);

    return (
        <OaCard style={{ 'minHeight': 200, 'textAlign': 'center', 'width': '100%', }} className={classes.cardRoot}>
            {isloading && (<OaLoading />)}
            {!isloading &&
                <div className={classes.root}>
                    <AppBar
                        position="static"
                        color="default"
                        className="oa-tabs-primary1">
                        <Tabs
                            value={value} onChange={handleChange}
                            classes={{
                                indicator: classes.indicator
                            }}

                            textColor="secondary"
                            variant="scrollable"
                            scrollButtons="auto"
                            aria-label="scrollable auto tabs example">
                            {rechargeType.map((type, index) => {
                                return (<Tab key={index} icon={<img className="plane-icon" src={GET_FILE_UPLOAD + "?fileName="+type.icon+"&type=EMAIL_IMAGES"}/>} 
                                label={type.categoryName} aria-label="phone"  {...a11yProps(index)} />)
                            })}
                        </Tabs>
                    </AppBar>

                    {rechargeType.map((typ, index) => {
                        return (<TabPanel value={value} index={index} key={index}>
                            <OperatorSearchForm type={type} operators={operators}></OperatorSearchForm>
                        </TabPanel>)

                    })}
                </div>
            }
        </OaCard>
    )
}

export default RechargeSection;
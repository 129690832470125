export const colors = {
  palette: {
    primary: {
      main: "#22201E",
      contrastText: "#FFF",
      defaultText: "#000",
      darkText: "#333",
      lightText: "#FFF",
      shadedLightText: "#CDCDCD",
      darkFadedText: "#665",
      sub: "#666666",
      disabled: "#D8D8D8",
      active: "#007A92",
      danger: "#BB2124",
      success: "#24d477",
      info: "#5863F8",
      warning: "#805d0d",
      reddishgray: "#464646",
      yellow: "#F1A625",
      blue: "#34404b",
      darkGray: "#666",
      golden: "#E3A133",
      blackPearl: "#060F26",
      peach: "#f76b6b",
      faddedGray: "#848383",
      sapphire: "#2174ED",
      redAlert: "#D0342C",
      french: "#2F71AF",
      honey: "#ffc037",
      violetBlue: "#5863F8",
      shadow: "#262626",
      iron: "#2D2D2D",
      fadedLight: "#EFEFEF",
      fadedRed: "f3cdcf",
      silverWhite: "#b9b6b6",
      gray: "#9c9c9c",
      parrotGreen: "#80cc4b",
      pink: "#dd3c49",
      Byzantine: "#3d61d4",
      grayishBlue: "#212529",
    },
    secondary: {
      main: "#E2BE69",
      contrastText: "#FFF",
      defaultText: "#000",
      darkText: "#333",
      lightText: "#FFF",
      darkFadedText: "#665",
      sub: "#E2BE69",
      disabled: "#D8D8D8",
      active: "#F17509",
      danger: "#BB2124",
      success: "#24d477",
      info: "#5863F8",
      warning: "#805d0d",
      mustardYellow: "#f89605",
      gray: "#666",
      lightBlue: "#1d79d5",
      darkBlue: "#007A92ee",
      black: "#0c0c0c",
      aegean: "#004C7E",
      sharkGray: "#9B9B9B",
      charcoal: "#5E5E5E",
      pebble: "#333333",
      metalGray: "#949799",
      charcoalGray: "#494949",
      dimGray: "#666666",
      grandmaGray: "#bcbcbc",
      fordGray: "#979797",
      darkShadedGray: "#444444",
      darkBlack: "#000000",
      medallion: "#E3A130",
      neon: "#2CE843",
      steel: "#808080",
      crimson: "#DF3049",
      koalaGray: "#908c8c",
      orange: "#F4783B",
      parakeet: "#43B253",
      lime: "b8d464",
      darkCharcoal: " #333",
      darkGray: "#333333",
      periglacialBlue: "#d3dbe4",
      skyBlue: "#66afe9",
      oyster: "#ccb783",
      lightShadow: "#706969",
      darkShadow: "#333",
      brightRed: "#f00",
    },
    tertiary: {
      main: "#E2BE69",
      contrastText: "#FFF",
      darkText: "#333",
      lightText: "#FFF",
    },
    quarternary: {
      main: "#9CCFDF",
      contrastText: "#FFF",
      darkText: "#333",
      lightText: "#FFF",
      darkGrayText: "#666",
    },
    background: {
      default: "#FFF",
      defaultContrast: "#333",
      lightGrayDefault: "#eeeeee",
      lightGrayDefaultContrast: "#333333",
      mobileSearch: "#f2fbd7",
      mobileSearchBorder: "#c2d87e",
      disabled: "#dddddd",
      disabledContrast: "#333333",
      light: "#FFF",
      dark: "#383838",
      darkDefault: "#000000",
      wappbg: "#25D366",
      wappbgContrastColor: "#ffffff",
      lightFadedGray: "#F5F0f0",
      darkerFadedGray: "#E8E8E8B0",
      lightGray: "#bdbbbb",
      overlay: "#eeeeee",
      red: "#d52731",
      selago: "#FAF9FA",
      carulean: "#1374B3",
      peach: "#EC6161",
      smokeWhite: "#f5f5f5",
      silverWhite: "#b8b8b8",
      cultured: "#F4F4F4",
      green: "#38B449",
      primary: "#22201E",
      primaryContrast: "#FFFFFF",
      secondary: "#E2BE69",
      secondaryContrast: "#333333",
      tertiary: "#E2BE69",
      tertiaryContrast: "#333333",
      fadedGreen: "#cae0c7",
      coral: "#d9534f",
      lightGreen: "#81cc4d",
      lightYellow: "#fec52d",
      brightRed: "#da393e",
      cyanBlue: "#286192",
      rgba_primary: "rgba(34, 32, 30, 0.2)",
      rgba_secondary: "rgba(226, 190, 105, 0.2)",
      rgba_tertiary: "rgba(226, 190, 105, 0.2)",
      rgba_quarternary: "rgba(156, 207, 223, 0.2)",
      rgba_primary_active: "rgba(34, 32, 30, 0.6)",
      rgba_secondary_active: "rgba(226, 190, 105, 0.6)",
      rgba_tertiary_active: "rgba(226, 190, 105, 0.6)",
      rgba_quarternary_active: "rgba(156, 207, 223, 0.6)",
    },
    text: {
      primary: "#22201E",
      primaryContrast: "#ffffff",
      secondary: "#E2BE69",
      secondaryContrast: "#000000",
      teritiary: "#E2BE69",
      tertiaryContrast: "#ffffff",
      quarternary: "#7E7979",
      quarternaryContrast: "#ffffff",
      default: "#000000",
      defaultContrast: "#ffffff",
      dark: "#333333",
      light: "#ffffff",
      danger: "#BB2124",
      success: "#24d477",
      info: "#5863F8",
      warning: "#805d0d",
    },
    buttons: {
      default: "#FFF",
      defaultContrast: "#000",
      primary: "#22201E",
      primaryContrastText: "#fff",
      secondary: "#E2BE69",
      secondaryContrastText: "#000",
      tertiary: "#E2BE69",
      tertiaryContrastText: "#fff",
      disabled: "#7E7979",
      disabledContrastText: "#fff",
      bookNowBtn: "#ef6614",
    },
    rgb: {
      primary: "rgb(34, 32, 30)",
      secondary: "rgb(226,190,105)",
      teritiary: "rgb(226, 190, 105)",
    },
    font: {
      primary: "'Poppins', Sans-serif",
      secondary: "'Roboto', Sans-serif",
      tertiary: "Bauhaus",
    },
  },
};

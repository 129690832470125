import React, { useState } from "react";
import { Grid, Typography, Divider, useMediaQuery } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { COLORS, FONTS } from "assets/css/CssConstants";
import { withRouter } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        "& .highlight": {
            color: theme.palette.secondary.info,
        },
    },

    Grid: {

        background: theme.palette.background.default,
        marginTop: "20px",
        boxShadow: "0px 53px 99px -81px rgba(0,0,0,0.75)",
        padding: 30,
        borderRadius: 20,

    },
    // firstCard: {
    //     "& .MuiTypography-root": {
    //         color: COLORS.PRIMARY_BG_DARK,
    //     },
    // },
    sectionHeader: {
        color: `${theme.palette.primary.darkFadedText} !important`,
        fontWeight: 500,
        margin: "5px 0 30px",
    },
    sectionSubhead: {
        color: `${theme.palette.primary.darkText} !important`,
        fontWeight: 400,
    },
    sectionText: {
        color: `${theme.palette.primary.defaultText} !important`,
        fontWeight: 400,
        lineHeight: "27px",
    },
    sectionTextGrid: {
        margin: "15px 0",
        flexDirection: "column",
    },
    listText: {
        "& li": {
            color: theme.palette.primary.darkFadedText,
            fontWeight: 400,
            lineHeight: "27px",
        }
    },
    breadcrumbLink: {
        color: `${theme.palette.secondary.main} !important`,
        cursor: "pointer",
        "&:hover": {
            color: `${theme.palette.secondary.active} !important`
        }
    }
}));

const Gap10 = () => {
    return(
        <div className="gap-10" style={{margin: 10, width: "100%"}}></div>
    );
}

const PricingPolicy = (props) => {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const classes = useStyles();

    return (
        <div className={classes.root} style={{ marginBottom: 60, paddingLeft: isMobile ? 10 : '6%', paddingRight: isMobile && 10 }}>
            <Grid container spacing={isMobile ? 0 : 3} style={{ width: "100%" }} justifyContent="center">
                <Grid item xs={12} md={10} style={{ paddingTop: "50px" }}>
                    <a onClick={() => props.history.push("")} className={classes.breadcrumbLink}>
                    {/* <a href="/auth/login-page" className={classes.breadcrumbLink}> */}
                        <i className="fa fa-home"></i> Home
                    </a>
                </Grid>

                <Grid item xs={12} md={10}>
                    <Grid
                        container
                        spacing={1}
                        className={`${classes.firstCard} ${classes.Grid}`}
                    >
                        <Grid item md={12}>
                            <Typography
                                variant="h3"
                                className={classes.sectionHeader}
                                gutterBottom
                            >
                                PRICING POLICY:
                            </Typography>
                            <hr style={{ width: "100%" }} />
                        </Grid>

                        <Grid item md={12} className={classes.sectionTextGrid}>

                            <Typography
                                variant="body2"
                                gutterBottom
                                className={classes.sectionText}
                            >
                               Airline tickets Price, Hotel Price, Bus ticket price will be dynamic, it will not be fixed, the price of the ticket is decided by the airlines, Hotel, Bus Operators, not by us.. whatever the price decided by them we will showing the same.
                            </Typography>

                            <Gap10 />
                            
                        </Grid>

                        <Grid item md={12} className={classes.sectionTextGrid} style={{ textAlign: "center" }}>
                            <a onClick={() => props.history.push("/")} className={classes.breadcrumbLink}>
                            {/* <a href="/auth/login-page" className={classes.breadcrumbLink}> */}
                                <i className="fa fa-home"></i> Home
                            </a>
                        </Grid>
                    </Grid>
                </Grid>

            </Grid>
        </div>
    );
}

export default withRouter(PricingPolicy);

import React, { useEffect } from "react";
import Seat from "./Seat";
import "./seats.css";

const Seats = (props) => {
  const passengerLimit = props.servlimit;

  const [paxDetail, setPaxDetail] = React.useState(props.paxinfo);
  const [paxs, setPaxs] = React.useState([]);
  const [selectedSeats, setSelectedSeats] = React.useState([]);
  const [selectedSeatsRt, setSelectedSeatsRt] = React.useState([]);
  const [seatsFull, setSeatsFull] = React.useState(false);
  const [seatsFullRt, setSeatsFullRt] = React.useState(false);
  const [selectedSeatsTotal, setSelectedSeatsTotal] = React.useState(0);
  const [maxLength, setMaxLength] = React.useState(0);

  console.log(props?.data, "PROPS DATA ------ ")

  useEffect(() => {
                /* eslint-disable no-unused-expressions */
    let maxLength = 0;
    props?.data?.forEach(obj => {

      // console.log(obj, "SPECIFCI OBJECT OF MAX LEWNGTH")
      // Assuming 'items' property contains the array you want to check
      if (Array.isArray(obj.seat)) {
          // Update maxLength if the current array length is greater
          maxLength = Math.max(maxLength, obj.seat.length);
      }
      
  });
  console.log(maxLength, "MAXLENGTH")
  setMaxLength(maxLength)
  },[])


  useEffect(() => {
    // console.log(selectedSeats);
    // console.log(props.data)
    console.log(selectedSeats.reduce((seat, a) => seat + a.price, 0));
    // if(selectedSeats.length < 2) {
    //   setSelectedSeatsTotal(selectedSeats[0].price)
    // } else {
    // }
  }, [selectedSeats]);

  React.useEffect(() => {
    setPaxDetail(props.paxinfo);
    const paxs = [
      ...props.paxinfo.adultPax,
      ...props.paxinfo.childPax,
      ...props.paxinfo.infantPax,
    ];
    setPaxs(paxs);
    // console.log(paxs);
  }, [props.paxinfo]);

  const onSelectedSeat = (ev, seat, isForReturn) => {
    let paxInfo = { ...paxDetail };
    var pax = [...paxInfo.adultPax, ...paxInfo.childPax];
    if (isForReturn) {
      setSelectedSeatsRt([...selectedSeatsRt, seat]);
    } else {
      setSelectedSeats([...selectedSeats, seat]);
    }
    if (ev.target.checked) {
      if (isForReturn) {
        if (paxInfo?.seatStatusRt[props.segidx]?.seatsFull) {
          removeExistingSeat(true);
        }
      } else if (paxInfo?.seatStatus[props.segidx]?.seatsFull) {
        removeExistingSeat(false);
      }
      addSeatSelection(pax, paxInfo, seat, isForReturn);
    } else {
      removeSeatSelection(pax, paxInfo, seat, isForReturn);
    }
  };

  function removeSeatSelection(entries, paxInfo, data, isReturn) {
    for (let [index, pax] of entries.entries()) {
      for (let [addIdx, val] of isReturn
        ? pax.additionalReturn.entries()
        : pax.additionalOnward.entries()) {
        if (addIdx == props.segidx && val.seat !== null) {
          val.seat = null;
          if (isReturn) {
            paxInfo.additionalFareRt.seat =
              parseFloat(paxInfo.additionalFareRt.seat) > 0
                ? parseFloat(paxInfo.additionalFareRt.seat) -
                parseFloat(data.price)
                : 0;
            paxInfo.seatStatusRt[addIdx].seatsFull = false;
            setSeatsFullRt(false);
            setSelectedSeatsRt((values) =>
              values.filter((obj) => {
                return obj.code != data.code;
              })
            );
          } else {
            paxInfo.additionalFare.seat =
              parseFloat(paxInfo.additionalFare.seat) > 0
                ? parseFloat(paxInfo.additionalFare.seat) -
                parseFloat(data.price)
                : 0;
            paxInfo.seatStatus[addIdx].seatsFull = false;
            setSeatsFull(false);
            setSelectedSeats((values) =>
              values.filter((obj) => {
                return obj.code != data.code;
              })
            );
          }
          setPaxDetail(paxInfo);
          return;
        }
      }
    }
  }

  function addSeatSelection(entries, paxInfo, seat, isReturn) {
    for (let [index, pax] of entries.entries()) {
      for (let [addIdx, val] of isReturn
        ? pax.additionalReturn.entries()
        : pax.additionalOnward.entries()) {
        if (addIdx == props.segidx && val.seat == null) {
          val.seat = seat;
          if (isReturn) {
            paxInfo.additionalFareRt.seat =
              parseFloat(paxInfo.additionalFareRt.seat) +
              parseFloat(seat.price);
          } else {
            paxInfo.additionalFare.seat =
              parseFloat(paxInfo.additionalFare.seat) + parseFloat(seat.price);
          }
          setPaxDetail(paxInfo);
          return;
        }
      }
    }
  }

  function removeExistingSeat(isForReturn) {
    let paxInfo = { ...paxDetail };
    var pax = [...paxInfo.adultPax, ...paxInfo.childPax];
    var data = null;
    if (isForReturn) {
      data = selectedSeatsRt[0];
    } else {
      data = selectedSeats[0];
    }
    removeSeatSelection(pax, paxInfo, data, isForReturn);
  }

  React.useEffect(() => {
    if (props.isreturn) {
      const selectedSeatsArray = [];
      if (props.paxinfo && props.paxinfo.seatStatusRt[props.segidx].seatsFull) {
        props.paxinfo.adultPax &&
          props.paxinfo.adultPax.map((pax) => {
            pax.additionalReturn &&
              pax.additionalReturn.map((val, addIdx) => {
                if (addIdx == props.segidx) {
                  if (!selectedSeats.includes(props.data)) {
                    // setSelectedSeats([...selectedSeats, val.seat]);
                    selectedSeatsArray.push(val.seat);
                  }
                }
              });
          });

        if (props.paxinfo.childPax && props.paxinfo.childPax.length > 0) {
          props.paxinfo.childPax &&
            props.paxinfo.childPax.map((pax) => {
              pax.additionalReturn &&
                pax.additionalReturn.map((val, addIdx) => {
                  if (addIdx == props.segidx) {
                    if (!selectedSeats.includes(props.data)) {
                      // setSelectedSeats([...selectedSeats, val.seat]);
                      selectedSeatsArray.push(val.seat);
                    }
                  }
                });
            });
        }
      }
      setSelectedSeatsRt(selectedSeatsArray);
    } else {
      const selectedSeatsArray = [];
      if (props.paxinfo && props.paxinfo.seatStatus[props.segidx].seatsFull) {
        props.paxinfo.adultPax &&
          props.paxinfo.adultPax.map((pax) => {
            pax.additionalOnward &&
              pax.additionalOnward.map((val, addIdx) => {
                if (addIdx == props.segidx) {
                  if (!props.data.includes(selectedSeats)) {
                    // setSelectedSeats([...selectedSeats, val.seat]);\
                    selectedSeatsArray.push(val.seat);
                  }
                }
              });
          });

        if (props.paxinfo.childPax && props.paxinfo.childPax.length > 0) {
          props.paxinfo.childPax &&
            props.paxinfo.childPax.map((pax) => {
              pax.additionalOnward &&
                pax.additionalOnward.map((val, addIdx) => {
                  if (addIdx == props.segidx) {
                    if (!props.data.includes(selectedSeats)) {
                      // setSelectedSeats([...selectedSeats, val.seat]);
                      selectedSeatsArray.push(val.seat);
                    }
                  }
                });
            });
        }
      }
      setSelectedSeats(selectedSeatsArray);
    }
  }, []);

  React.useEffect(() => {
    console.log(props.data, "PROPS DATA")
    let seatsSelected = 0;

    if (props.isreturn == true) {
      paxDetail &&
        paxDetail.adultPax &&
        paxDetail.adultPax.map((pax) => {
          pax.additionalReturn &&
            pax.additionalReturn.map((val, addIdx) => {
              if (addIdx == props.segidx && val.seat != null) {
                seatsSelected++;
              }
            });
        });

      if (paxDetail && paxDetail.childPax.length > 0) {
        paxDetail.childPax &&
          paxDetail.childPax.map((pax) => {
            pax.additionalReturn &&
              pax.additionalReturn.map((val, addIdx) => {
                if (addIdx == props.segidx && val.seat != null) {
                  seatsSelected++;
                }
              });
          });
      }

      if (seatsSelected == passengerLimit) {
        paxDetail.seatStatusRt[props.segidx].seatsFull = true;
        setSeatsFullRt(true);
      }

      props.setpaxinfo(paxDetail, "return");
    } else {
      paxDetail &&
        paxDetail.adultPax &&
        paxDetail.adultPax.map((pax) => {
          pax.additionalOnward &&
            pax.additionalOnward.map((val, addIdx) => {
              if (addIdx == props.segidx && val.seat != null) {
                seatsSelected++;
              }
            });
        });

      if (paxDetail && paxDetail.childPax?.length > 0) {
        paxDetail.childPax &&
          paxDetail.childPax.map((pax) => {
            pax.additionalOnward &&
              pax.additionalOnward.map((val, addIdx) => {
                if (addIdx == props.segidx && val.seat != null) {
                  seatsSelected++;
                }
              });
          });
      }
      // console.log("seats selected", seatsSelected);
      // console.log("passengerLimit", passengerLimit);
      if (seatsSelected == passengerLimit) {
        paxDetail.seatStatus[props.segidx].seatsFull = true;
        setSeatsFull(true);
      }

      props.setpaxinfo(paxDetail, "onward");
    }
  }, [paxDetail]);

  return (
    <div className="main-container">
      <div className="info-container">
        <div className="seatsSelectedContainer">
          {selectedSeats.length > 0 &&
            selectedSeats.map((seat, index) => (
              <div className="seatSelectedContainer">
                <div className="seatInfo">
                  <div className="paxName">
                    {/* {paxDetail.adultPax[index]
                      ? `ADULT ${index + 1}`
                      : `CHILD ${index + 1}`} */}
                    {paxDetail.adultPax[index] ? `ADULT PAX` : `CHILD PAX`}
                  </div>

                  <div className="seatCodeSelected">{seat.code}</div>
                </div>

                <div className="priceText">₹ {seat.price}</div>
              </div>
            ))}
          {selectedSeats.length > 0 && (
            <div className="fareContainer">
              <div>Total Fare</div>
              <div className="priceText">
                {/* ₹ {selectedSeats.reduce((seat, a) => seat.price + a.price, 0)} */}
                ₹ {selectedSeats.reduce((seat, a) => seat + a.price, 0)}
              </div>
            </div>
          )}

          {selectedSeatsRt.length > 0 &&
            selectedSeatsRt.map((seat, index) => (
              <div className="seatSelectedContainer">
                <div className="seatInfo">
                  <div className="paxName">
                    {/* {paxDetail.adultPax[index]
                      ? `ADULT ${index + 1}`
                      : `CHILD ${index + 1}`} */}
                    {paxDetail.adultPax[index] ? `ADULT PAX` : `CHILD PAX`}
                  </div>

                  <div className="seatCodeSelected">{seat.code}</div>
                </div>

                <div className="priceText">₹ {seat.price}</div>
              </div>
            ))}
          {selectedSeatsRt.length > 0 && (
            <div className="fareContainer">
              <div>Total Fare</div>
              <div className="priceText">
                {/* ₹ {selectedSeats.reduce((seat, a) => seat.price + a.price, 0)} */}
                ₹ {selectedSeatsRt.reduce((seat, a) => seat + a.price, 0)}
              </div>
            </div>
          )}
        </div>
        <div className="seat-info">
          <p>
            <span className="free-seat"></span>Free
          </p>
          <p>
            <span className="exit-sign"></span>Exit
          </p>
          <p>
            <span className="unbooked"></span>Unbooked
          </p>
          <p>
            <span className="booked-sign">X</span>Booked
          </p>
          <p>
            <span className="selected-seat"></span>selected
          </p>
        </div>
      </div>
      <div className="plane-container">
        <div className="plane">
          <div className="cockpit">
            <h1>Please select a seat</h1>
          </div>
          <div className="exit exit--front fuselage" ></div>
          <div>

          
          <ol className="cabin fuselage" style={maxLength > 7 ? {paddingLeft: 150} : {paddingLeft: 0}}>
            {props?.data &&
              props?.data.map(
                (item, index) =>
                  index !== 0 && (
                    <li className={`row seatrow row--${index}`} key={index}>
                      <ol className="seats" type={""}>
                        {item.seat.map((seat, index) => (
                          <Seat
                            seat={seat}
                            key={index}
                            selectedseat={onSelectedSeat}
                            selected={selectedSeats}
                            selectedrt={selectedSeatsRt}
                            segidx={props.segidx}
                            isreturn={props.isreturn}
                            seatsfull={seatsFull}
                            seatsfullrt={seatsFullRt}
                          />
                        ))}
                      </ol>
                    </li>
                  )
              )}
          </ol>
          </div>
          <div className="exit exit--back fuselage"></div>
        </div>
      </div>
    </div>
  );
};

export default Seats;

import React from "react";
import { makeStyles, Button, useTheme, useMediaQuery } from "@material-ui/core";
import ContactBanner from "assets/triumphTravel/images/contact-banner.jpg";
import pic1 from "assets/triumphTravel/images/pic1.png";
import pic2 from "assets/triumphTravel/images/pic2.png";
import pic3 from "assets/triumphTravel/images/pic3.png";
import review from "assets/triumphTravel/images/icons/review.svg";
import rightArrow from "assets/triumphTravel/images/rightArrow.png";
import leftArrow from "assets/triumphTravel/images/leftArrow.png";
import scroller from "assets/triumphTravel/images/scroller.png";
import perks from "assets/triumphTravel/images/icons/perks_light.svg";
import navLeft from "assets/triumphTravel/images/navLeft.png";
import navRight from "assets/triumphTravel/images/navRight.png";
import navImage from "assets/triumphTravel/images/navImage.png";
import space from "assets/triumphTravel/images/space.png";
import area from "assets/triumphTravel/images/area.png";
import view from "assets/triumphTravel/images/view.png";
import cafeteria from "assets/triumphTravel/images/cafeteria.png";
import slideImage from "assets/triumphTravel/images/slideImage.png";
import arrowLeft from "assets/triumphTravel/images/arrowLeft.png";
import arrowRight from "assets/triumphTravel/images/arrowRight.png";
import { ArrowForward } from "@material-ui/icons";
import Carousel, { consts } from "react-elastic-carousel";

const useStyles = makeStyles(theme => ({

    root: {
        fontFamily: theme.palette.font.primary,
        "& .section-container": {
            margin: 0,
            padding: 0,
            width: "100%",
        },
        "& .grid-container": {
            display: "flex",
            [theme.breakpoints.down(600)]: {
                flexWrap: "wrap",
            }
        },
        "& .justify-center": {
            justifyContent: "center",
        },
        "& .padding-tb100": {
            padding: "100px 0",
        },
        "& .md-1280": {
            maxWidth: 1280,
            width: "100%",
        },

        "& .section-title-container": {
            padding: "150px 0 0 60px",
            [theme.breakpoints.down(600)]: {
                padding: "90px 0 0 60px",
            }
        },
        "& .section-title": {
            "& h2": {
                fontWeight: 700,
                color: theme.palette.primary.lightText,
                fontSize: 42,
                margin: 0,
            },
        },
        "& .section-bread-crumbs": {
            color: theme.palette.primary.lightText,
            "& a": {
                color: theme.palette.primary.lightText,
            },
        },
        "& .banner-section": {
            padding: 20,
            height: 380,
            backgroundImage: `url(${ContactBanner})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "norepeat",
            [theme.breakpoints.down(600)]: {
                height: 250,
            },
        },
        "& .content-container": {
            "& button": {
                background: theme.palette.background.tertiary,
                color: theme.palette.primary.lightText,
                fontSize: 14,
                padding: "10px 20px",
                textTransform: "lowercase",
                "& .MuiSvgIcon-root": {
                    marginLeft: 4,
                    fontSize: 16,
                },
            },
            "&.md-50": {
                width: "50%",
                [theme.breakpoints.down(960)]: {
                  
                    width: "100%",
                },
            },
            [theme.breakpoints.down(1330)]: {
                padding: "0 20px",
            },
            [theme.breakpoints.down(960)]: {
                padding: "0 30px",
            },
            [theme.breakpoints.down(600)]: {
                textAlign: "center",
                marginBottom: 10,
            },
            [theme.breakpoints.down(390)]: {
                padding: "0 15px",
            },
        },

        "& .testimonial-container": {
            margin: "20px 0 0 0",
            "& .content-text-name": {
                fontSize: 18,
                fontWeight: 600,
                margin: 0,
                lineHeight: 1.5,
            },
            "& .content-text-occupation": {
                fontSize: 12,
                fontWeight: 600,
                margin: 0,
                lineHeight: 1.5,
            },
            "& .content-image": {
                margin: "0 15px 0 0",
            },
            "& .testimonial-text": {
                width: 342,
                margin: "20px 0 0",
                "& .content-text-caption": {
                    fontSize: 14,
                    fontWeight: 500,
                },
            },
            [theme.breakpoints.down(390)]: {
                padding: "0 15px",
            },
        },
        "& .section-header": {
            "& p": {
                color: theme.palette.tertiary.main,
                paddingTop: 20,
                size: 14,
                fontWeight: 700,
            },
        },
        "& .section-caption": {
            "& p": {
                fontSize: 42,
                fontWeight: 800,
                lineHeight: 1.2,
                color: theme.palette.primary.darkText,
                [theme.breakpoints.down(1330)]: {
                    fontSize: 32,
                },
            },
            "&.light-text": {
                color: theme.palette.primary.lightText,
                "& p": {
                    color: theme.palette.primary.lightText,
                },
            },
        },
        "& .content-text": {
            margin: "30px 0",
            "& p": {
                fontWeight: 500,
                fontSize: 16,
                lineHeight: 1.3,
            },
        },
        "& .content-Images": {
            textAlign: "center",
            "& img": {
                margin: 10,
                [theme.breakpoints.down(960)]: {
                    width: "100%",
                },
                [theme.breakpoints.down(390)]: {
                    width: "100%",
                    
                },
            },
        },
        "& .section-bg": {
            minHeight: 320,
            backgroundImage: `url(${slideImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "norepeat",
        },
        "& .inner-container": {
            maxWidth: 1280,
            width: "100%",
            "&.max-1440": {
                maxWidth: 1440,
            },
            "&.padding-tb100": {
                padding: "100px 0",
            },
        },
        "& .header-container": {
            padding: "0 0 0 70px",
            marginBottom: 30,
        },
        "& .section-carousel-wrapper": {
            width: "100%",
        },
        "& .rec-carousel": {
            [theme.breakpoints.down(600)]: {
                position: "relative",
            },
        },
        "& .arrow-crouselPackage": {
            display: "flex",
            alignItems: "center",
            [theme.breakpoints.down(600)]: {
                position: "absolute",
                top: "35%",
            },
            "& img": {
                cursor: "pointer",
            },
            "&.left": {
                left: 0,
                zIndex: 1,
            },
            "&.right": {
                right: 0,
            },
        },
        "& .service-item": {
            background: theme.palette.background.light,
            width: "100%",
            textAlign: "center",
            padding: "30px 5px",
            color: theme.palette.primary.darkText,
            height: 240,
        },
        "& .service-image": {
            width: "100%",
            margin: "0 0 20px",
            "& img": {
                width: 59,
                height: 59,
            },
        },
        "& .service-text-title": {
            fontSize: 16,
            fontWeight: 500,
            padding: "0 45px",
        },
        "& .service-text": {
            color: theme.palette.primary.darkText,
            fontSize: 13.5,
            marginTop: 15,
        },
        "& .grid-column": {
            "&.grow-1": {
                flexGrow: 1,
            },
            "&.testimonial-grid": {
                padding: "60px 0 30px",
            },
            "&.image-column": {
                position: "relative",
            },
        },
        "& .content-navImage": {
            position: "absolute",
            bottom: -10,
            left: -60,
            "& a": {
                cursor: "pointer",
            },
        },
    },
}));

const CustomArrow = ({ type, onClick, isEdge }) => {
    // const pointer =
    //     type === consts.PREV ? <img src={leftArrow} /> : <img src={rightArrow} />;
    // return (
    //     <p onClick={onClick} disabled={isEdge} className="arrow-crouselPackage">
    //         {pointer}
    //     </p>
    // );
    if (type === consts.PREV) {
        return (
            <p onClick={onClick} disabled={isEdge} className="arrow-crouselPackage left">
                <img src={leftArrow} />
            </p>
        )
    } else { 
        return (
            <p onClick={onClick} disabled={isEdge} className="arrow-crouselPackage right">
                <img src={rightArrow} />
            </p>
        )
    }
}

const AboutUs = (props) => {

    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    return (
        <div className={classes.root}>
            <div className="section-container">
                <div className="banner-section">
                    <div className="section-title-container">
                        <div className="section-title">
                            <h2>About Us</h2>
                        </div>
                        <div className="section-bread-crumbs">
                            <a href="">Home</a> | <a href="">About Us</a>
                        </div>
                    </div>
                </div>
            </div>

            <div className="section-container grid-container justify-center">
                <div className="grid-container md-1280 padding-tb100">
                    <div className="content-container md-50">
                        <div className="section-header">
                            <p>ABOUT US</p>
                        </div>
                        <div className="section-caption">
                            <p> Trust and quality</p>
                        </div>
                        <div className="content-text">
                            <ul>
                                <li>
                                    <p>A young and dynamic mid sized organization</p>
                                </li>
                                <li>
                                    <p>Team of around 20 on board professionals</p>
                                </li>
                                <li>
                                    <p>Reliable - Representatives & Partners all across the country</p>
                                </li>
                                <li>
                                    <p>Collective experience of more than 06 Decades in the domain of ‘Travel’, ‘Integrated Marketing’, ‘Sales & Marketing’, ‘Market Research’ &  ‘Representations’</p>
                                </li>
                            </ul>
                        </div>
                        {/* <Button variant="contained" className={classes.btn}>
                            read more <ArrowForward />
                        </Button> */}
                    </div>
                    <div className="content-container md-50">
                        <div className="content-Images">
                            <img src={pic1} />
                            <img src={pic2} />
                            <img src={pic3} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="section-container section-bg grid-container justify-center">
                <div className="inner-container max-1440 padding-tb100">
                    <div className="header-container">
                        <div className="section-header">
                            <p>RENDERED</p>
                        </div>
                        <div className="section-caption light-text">
                            <p>SERVICES</p>
                        </div>
                    </div>
                    <div className="content-container">
                        <div className="section-carousel-wrapper">
                            <Carousel
                                itemsToShow={isMobile ? 1 : 4}
                                itemPadding={[0, 10, 0, 0]}
                                pagination={false}
                                renderArrow={CustomArrow}
                            >
                                {aboutUsArray && aboutUsArray.map((val, ind) => (
                                    <div className="service-item" key={ind}>
                                        <div className="service-image">
                                            <img src={perks} />
                                        </div>
                                        <div className="service-text-content">
                                            <div className="service-text-title">{val.title}</div>

                                            <div className="service-text">{val.text}</div>
                                        </div>
                                    </div>
                                ))}
                            </Carousel>
                        </div>
                    </div>
                </div>
            </div>

            <div className="section-container grid-container justify-center">
                <div className="grid-container md-1280 padding-tb100">
                    <div className="content-container md-50">
                        
                        <div className="section-header">
                            <p>AUGMENTED</p>
                        </div>
                        
                        <div className="section-caption">
                            <p>Milestones</p>
                        </div>

                        <div className="content-text">
                            <ul>
                                <li>
                                    <p>Expanded our partner network to more than 200 Partners on board within 01 Year of our inception</p>
                                </li>
                                <li>
                                    <p>Active member of ADTOI (Association of Domestic Tour Operators of India)</p>
                                </li>
                                <li>
                                    <p>IATA (International Air Transport Authority) accredited agent</p>
                                </li>
                                <li>
                                    <p>Active member of TAAI (Travel Agents Association of India) - In Process</p>
                                </li>
                                <li>
                                    <p>Active member of TAFI (Travel Agents Federation of India) - In Process</p>
                                </li>
                                <li>
                                    <p>Served more than a Million happy customers who have flown Multi Million Miles</p>
                                </li>
                                <li>
                                    <p>Online operations through our portal (https://triumphhtravel.com/) - Constantly upgrading</p>
                                </li>
                                <li>
                                    <p>Access to the best GDS (Global Distribution Systems) - Amadeus & Galileo</p>
                                </li>
                                <li>
                                    <p>Reach to almost every part of the country with our partner network</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className="section-container grid-container justify-center">
                <div className="inner-container grid-container padding-tb100">
                    <div className="grid-column grow-1 testimonial-grid">
                        <div className="section-header">
                            <p>Testimonials</p>
                        </div>
                        <div className="section-caption">
                            <p>We trust our customers<br />and they trust us too</p>
                        </div>
                        <div className="content-container testimonial-container">
                            <div className="content-items grid-container">
                                <div className="content-image" >
                                    <img src={review} />
                                </div>
                                <div className="content-text-content">
                                    <p className="content-text-name">
                                        Marius Ciocirlan
                                    </p>
                                    <p className="content-text-occupation">
                                        IT Engineer
                                    </p>
                                </div>
                            </div>
                            <div className="testimonial-text">
                                <p className=" content-text-caption">
                                    I am very glad I had the opportunity to visit this hotel.The stuff is very friendly and I will definitely visit the hotel next year.

                                </p>
                            </div>
                            <div className="content-scroller">
                                <img src={scroller} />
                            </div>
                        </div>
                    </div>
                    <div className="grid-column image-column">
                        <img src={navImage} />
                        <div className="content-navImage grid-container">
                            <p>
                                <a className="left-link">
                                    <img src={navLeft} />
                                </a>
                            </p>
                            <p>
                                <a className="right-link">
                                    <img src={navRight} />
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
            </div> */}
        </div>
    );
};

export default AboutUs;

const aboutUsArray = [
    { title: "Air Ticketing", text: "Domestic & International - Electronic Ticketing" },
    { title: "Visas", text: "Help with Visas for all the countries through the respective partners of the Embassies/ Consulates & High Commissions" },
    { title: "Train Tickets", text: "On the Indian Railways Network - Electronic Ticketing" },
    { title: "Hotel Reservations", text: "Within & Outside India" },
    { title: "Transportation", text: "Within India, through various Transporters" },
    // { title: "Tours & MICE (Meetings, Incentives, Conferences & Exhibitions)", text: "Making complete arrangements for various delegates coming to India and for delegates travelling abroad for holidays, corporate meetings or any events" },
];
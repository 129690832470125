import React from "react";
import { useMediaQuery } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core";
import companyLogo from "assets/triumphTravel/images/company_logo.png";
import arrowLeft from "assets/triumphTravel/images/icons/arrowLeft.png";
import serviceIcon from "assets/triumphTravel/images/icons/serviceIcon.png";
import arrowRight from "assets/triumphTravel/images/icons/arrowRight.png";
import Carousel, { consts } from "react-elastic-carousel";

const useStyles = makeStyles(theme => ({

    root: {
        padding: 0,
        margin: 0,
        margin: "90px 0 90px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        [theme.breakpoints.down(600)]: {
            margin: "90px 0",
        },
        "& .section-container": {
            width: "100%",
            backgroundColor: theme.palette.background.darkDefault,
            display: "flex",
            justifyContent: "center",
        },
        "& .section-bg-cover": {
            width: "100%",
            padding: "60px 60px",
            maxWidth: 1280,
            [theme.breakpoints.down(960)]: {
                padding: "0 0 60px",
            },
        },
        "& .content-row": {
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-start",
            margin: 10,
            
            "&.header-row": {
                justifyContent: "space-between",
                // [theme.breakpoints.down(424)]: {
                //     margin: "70px 0 0",
                // },
                // [theme.breakpoints.down(400)]: {
                //     margin: "155px 0 0",
                // },
                // [theme.breakpoints.down(343)]: {
                //     margin: "240px 0 0",
                // },
            },
        },
        "& .content-column": {
            margin: "0 2vw",
            textAlign: "left",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            [theme.breakpoints.down(960)]: {
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                flexGrow: 2,
                "&.company-logo": {
                    display: "none",
                },
            },
            // [theme.breakpoints.down(440)]: {
            //     margin: "200px 2vw 0",
            // },
        },
        "& .company-logo": {
            "& img": {
                height: 180,
            },
        },
        "& .section-carousel-wrapper": {
            width: "100%",
            

        },
        "& .service-item": {
            background: theme.palette.background.primary,
            width: "100%",
            textAlign: "center",
            padding: "30px 5px",
            color: theme.palette.primary.lightText,
            height: 270,
        },
        "& .service-image": {
            width: "100%",
            margin: "0 0 20px",
            "& img": {
                width: 59,
                height: 59,
            },
        },
        "& .service-text-title": {
            fontSize: 12.5,
            fontWeight: 500,
            padding: "0 45px",
        },
        "& .service-text": {
            color: theme.palette.primary.shadedLightText,
            fontSize: 11.5,
            
            marginTop: 15,
        },
        "& .service-text-content": {
            textAlign: "center",
            // width: 368,
            // height: 190,
            // padding: 20,
            // position: "relative",
            "& .content-text-title": {
                textTransform: "uppercase",
                fontWeight: 600,
                fontSize: 12,
            },
        },
        "&.service-text": {
            color: theme.palette.primary.lightText,
            fontSize: 10,
        },
        "& .arrow-crouselPackage": {
            display: "flex",
            alignItems: "center",
            "& img": {
                cursor: "pointer",
            },
        },
    },
    cover: {
        background: theme.palette.background.darkDefault,
    },
    title: {
        paddingLeft: 40,
        paddingTop: 40,
        color: theme.palette.secondary.main,
        [theme.breakpoints.down(960)]: {
            paddingLeft: 0,
        },
    },
    header: {
        paddingLeft: 40,
        fontSize: "3.5em",
        lineHeight: 1,
        color: theme.palette.primary.lightText,
        fontWeight: 700,
        [theme.breakpoints.down(960)]: {
            paddingLeft: 0,
        },
        [theme.breakpoints.down(440)]: {
            fontSize: 24,
        },
    },
}));

const CustomArrow = ({ type, onClick, isEdge }) => {
    const pointer =
        type === consts.PREV ? <img src={arrowLeft} /> : <img src={arrowRight} />;
    return (
        <p onClick={onClick} disabled={isEdge} className="arrow-crouselPackage">
            {pointer}
        </p>
    );
}

const AboutUs = () => {
    
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    
    return (
        <div className={classes.root}>
            <div className="section-container">
                <div className="section-bg-cover">
                    <div className="content-row header-row">
                        <div className="content-column">
                            <p className={classes.title}>ABOUT US</p>
                            <p className={classes.header}>Why choose <br/>Triumphh Travel?</p>
                        </div>

                        <div className="content-column company-logo">
                            <img src={companyLogo} />
                        </div>
                    </div>

                    <div className="content-row">
                        <div className="section-carousel-wrapper">
                            <Carousel
                                itemsToShow={isMobile ? 1 : 4}
                                itemPadding={[0, 10, 0, 0]}
                                pagination={false}
                                renderArrow={CustomArrow}
                            >
                                {aboutUsArray && aboutUsArray.map((val, ind) => (
                                <div className="service-item" key={ind}>
                                    <div className="service-image">
                                        <img src={serviceIcon} />
                                    </div>
                                    <div className="service-text-content">
                                        <div className="service-text-title">{val.title}</div>

                                        <div className="service-text">{val.text}</div>
                                    </div>
                                </div>
                                ))}
                            </Carousel>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

const aboutUsArray = [
    {
        title: "24 x 7 Support Assurance",
        text:
          "Personalized support and services through experienced professionals",
      },
      {
        title: "Transparent Billing & Invoicing",
        text:
          "Giving multiple options to the end customer for them to choose the best suited proposition in terms of time, convenience and pricing",
      },
      {
        title: "Personalized SLA'S as agreed mutually",
        text:
          "Time To Time reviews with the clients to understand the needs and scope for improvement",
      },
      {
        title: "Training first time travelers",
        text:
          "Enrolling First Time Travelers with various Loyalty Programs from the Airlines and other stake holders on the basis of eligibility",
      },
];

export default AboutUs;

import React from "react";
import { makeStyles } from "@material-ui/core";
import review from "assets/triumphTravel/images/icons/review.svg";
import sideImage from "assets/triumphTravel/images/icons/sideImage.svg";
import rightArrow from "assets/triumphTravel/images/icons/rightArrow.png";
import leftArrow from "assets/triumphTravel/images/icons/leftArrow.png";

const useStyles = makeStyles(theme => ({
    root: {
        padding: 0,
        display: "flex",
        justifyContent: "center",
        width: "100%",
        fontFamily: theme.palette.font.primary,
        margin: "120px 0 0",
        [theme.breakpoints.down(1050)]: {
            display: "none",
        },
        "& .section-container": {
            maxWidth: 1280,
            width: "100%",
            "& .caption": {
                paddingTop: 60,
                paddingLeft: 40,
                "& p": {
                    color: theme.palette.secondary.main,
                    fontWeight: 800,
                },
            },
        },
        "& .section-heading": {
            paddingLeft: 40,
            "& p": {
                padding: "0 0 0 0",
                fontSize: 36,
                lineHeight: 1.2,
                color: theme.palette.primary.defaultText,
                fontWeight: 800,
            },
        },
        "& .contents-container": {
            display: "flex",
            flexWrap: "nowrap",
            alignItems: "center",
            width: "100%",
            background: theme.palette.background.lightFadedGray,
        },
        "& .content-left": {
            flexGrow: 1,
            padding: "0 0 0 90px",
        },
        "& .content-right": {
            position: "relative",
            "& img.main-image": {
                height: 450,
            },
        },
        "& .content-item": {
            width: "100%",
            margin: 10,
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            marginTop: 20,
        },
        "& .content-image": {
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center",
            padding: "0 0 0 30px",
            "& img": {
                marginRight: 25,
            },
        },
        "& .content-text-content": {
            textAlign: "left",
            width: 335,
            height: 70,
            paddingLeft: 30,
            marginTop: 20,
        },
        "& .testimonial-identity": {
            marginTop: 15,
        },
        "& .content-text-name": {
            color: theme.palette.secondary.black,
            fontWeight: 700,
            fontSize: 16,
            margin: "0 0 5px 0"
        },
        "& .content-text-occupation": {
            fontSize: 12,
        },
        "& .content-text-caption": {
            fontSize: 14,
            fontWeight: 600,
            color: theme.palette.primary.darkText,
        },
        "& .content-navImage": {
            position: "absolute",
            display: "flex",
            width: 315,
            height: 190,
            bottom: -130,
            left: -60,
        },
        "& .right-link": {
            width: 60,
            height: 60,
            justifyContent: "center",
            alignItems: "center",
            right: 635,
            cursor: "pointer",
        },
        "& .left-link": {
            width: 60,
            height: 60,
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
            right: 575,
        },
        "& .dots-container": {
            display: "flex",
            flexWrap: "nowrap",
            margin: "10px 0 10px 20px",
            "& .t-dot": {
                width: 10,
                height: 10,
                borderRadius: "50%",
                margin: "0 5px",
                background: theme.palette.primary.disabled,
                "&.active": {
                    background: theme.palette.secondary.main,
                },
            },
        },
    },
}));

const Testimonial = () => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <div className="section-container">
                <div className="contents-container">
                    <div className="content-left">
                        <div className="caption">
                            <p>Customer Testimonials</p>
                        </div>
                        <div className="section-heading">
                            <p>We trust our customers <br />and they trust us too</p>
                        </div>
                        <div className="content-item">
                            <div className="content-image" >
                                <img src={review}/>
                                <div className="testimonial-identity">
                                    <p className="content-text-name">
                                        Marius Ciocirlan
                                        </p>
                                    <p className="content-text-occupation">
                                        IT Engineer
                                        </p>
                                </div>
                            </div>
                            <div className="content-text-content">
                                <p className=" content-text-caption">
                                    I am very glad I had the opportunity to visit this hotel.The stuff is very friendly and I will definitely visit the hotel next year.
                                    </p>
                            </div>
                            <div className="dots-container">
                                <span className="t-dot"></span>
                                <span className="t-dot active"></span>
                                <span className="t-dot"></span>
                                <span className="t-dot"></span>
                            </div>
                        </div>
                    </div>

                    <div className="content-right">
                        <img src={sideImage} className="main-image"/>

                        <div className="content-navImage">
                            <p>
                                <a className="left-link">
                                    <img src={rightArrow} />
                                </a>
                            </p>
                            <p>
                                <a className="right-link">
                                    <img src={leftArrow} />
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    )
};

export default Testimonial;
import React from "react";
import {
  Box,
  Grid,
  makeStyles,
  Typography,
  IconButton,
} from "@material-ui/core";
import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";

const useStyles = makeStyles(theme => ({
  mainBox: {
    width: "155px",
    borderRadius: "8px",
    backgroundColor: "#ffffff66",
    marginTop: 3,
    [theme.breakpoints.down(840)]: {
      width: 100,
    },
    [theme.breakpoints.down(400)]: {
      width: "50vw",
    },
  },
  incrButton: {
    "& .MuiSvgIcon-root": {
      height: 12,
    }
  },
  countVal: {
    "& .MuiTypography-h6": {
      fontSize: "1em",
    }
  },
  decrButton: {
    "& .MuiSvgIcon-root": {
      height: 12,
    }
  },
  label: {
    fontSize: 14,
    fontWeight: 400,
    [theme.breakpoints.down(400)]: {
      fontSize: 12,
      width: "40vw",
    },
  }
}));

function PassengerCount({ title, type, val, setVal }) {
  const classes = useStyles();

  const incrCount = () => setVal(type, 1);
  const decrCount = () => setVal(type, -1);

  return (
    <>
      <span className={classes.label}>{title}</span>
      <Box className={classes.mainBox}>
        <Grid
          container
          direction="row"
          justifyContent="space-around"
          alignItems="center"
        >
          <Box className={classes.incrButton}>
            <IconButton
              aria-label="decrement"
              onClick={decrCount}
              disabled={val <= (type === "adult" ? 1 : MIN_PASS_COUNT)}
            >
              <RemoveIcon />
            </IconButton>
          </Box>
          <Box className={classes.countVal}>
            <Typography variant="h6">{val}</Typography>
          </Box>
          <Box className={classes.decrButton}>
            <IconButton
              aria-label="increament"
              onClick={incrCount}
              disabled={val >= MAX_PASS_COUNT}
            >
              <AddIcon />
            </IconButton>
          </Box>
        </Grid>
      </Box>
    </>
  );
}

export default PassengerCount;

const MIN_PASS_COUNT = 0;
const MAX_PASS_COUNT = 17;

import React, { useEffect, useState, useRef } from "react";
import {
  Box,
  Grid,
  makeStyles,
  RadioGroup,
  Radio,
  FormControlLabel,
  TextField,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  FormHelperText,
  ListItemText,
  Checkbox,
  OutlinedInput,
  Popper,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import Autocomplete from "@material-ui/lab/Autocomplete";

import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import SwapHorizIcon from "@material-ui/icons/SwapHoriz";
import PassengerCount from "./PassengerCounter";

import WebApi from "api/ApiConstants";
import { apiCall } from "oautils/oaDataUtils";
import { connect } from "react-redux";
import {
  setDomesticCarriers,
  setInternationalCarriers,
  setAirports,
} from "redux/action";
import { withRouter, useHistory } from "react-router-dom";
import { Formik, Form, ErrorMessage, FieldArray } from "formik";
import { localforageGetItem } from "oautils/oaForageUtils";

import * as Yup from "yup";
import OaFormAlerts from "pages/components/OaFormAlerts";
import _ from "lodash";
import dateFnsFormat from "date-fns/format";
import OaPopperDatepicker from "oahoc/OaPopperDatepicker";
import CustomDatePicker from "oahoc/CustomDatePicker";
import { allAirlines, gdsAirlines, lccAirlines } from "api/ApiConstants";

const FlightSection = (props) => {
  const departAutocompleteEl = useRef(null);
  const departAutocompleteElText = useRef(null);
  const arriveAutocompleteEl = useRef(null);
  const arriveAutocompleteElText = useRef(null);
  const history = useHistory();
  const classes = useStyles();

  const [departDateDialogOpen, setDepartDateDialogOpen] = useState(false);
  const [returnDateDialogOpen, setReturnDateDialogOpen] = useState(false);
  const [airlineSelectOpen, setAirlineSelectOpen] = useState(false);

  const [showFareType, setShowFareType] = React.useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [infoText, setInfoText] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [fromCountry, setFromCountry] = useState("");
  const [toCountry, setToCountry] = useState("");
  const [carrier, setCarrier] = useState(props.domCarriers);
  const [userType, setUserType] = useState(null);
  const [corporateType, setCorporateType] = useState(null);
  const [clientType, setClientType] = useState(null);
  const [partnerId, setPartnerId] = useState(null);
  const formikRef = React.useRef();
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const customPopperFrom = function (props) {
    return (
      <Popper
        {...props}
        // anchorEl={departAutocompleteEl.current.children[0]}
        placement="bottom"
        style={{}}
      />
    );
  };

  const customPopperTo = function (props) {
    // console.log("props", props);
    return (
      <Popper
        {...props}
        // anchorEl={arriveAutocompleteEl.current.children[0]}
        placement="bottom"
        style={{}}
      />
    );
  };

  const modifyPassengerCount = (type, val) => {
    if (type === "adult") {
      formikRef.current.setFieldValue(
        "adultPax",
        Number(formikRef.current.values.adultPax) + val
      );
    }
    if (type === "child") {
      formikRef.current.setFieldValue(
        "childPax",
        Number(formikRef.current.values.childPax) + val
      );
    }
    if (type === "infant") {
      formikRef.current.setFieldValue(
        "infantPax",
        Number(formikRef.current.values.infantPax) + val
      );
    }
    // console.log(data.adultCount, data.childCount, data.infantCount);
  };

  const [airportList, setAirportList] = useState(popularCities);

  const initialValues = {
    adultPax: "1",
    childPax: "0",
    clientType: null,
    infantPax: "0",
    mode: "ONE",
    fareType: "Regular",
    preferredAirline: [
      allAirlines,
    ],
    preferredclass: "E",
    returnDate: dateFnsFormat(new Date(), "yyyy-MM-dd"),
    returnDateDisplay: new Date(),
    sectors: [
      {
        departDate: dateFnsFormat(new Date(), "yyyy-MM-dd"),
        departDateDisplay: new Date(),
        destination: popularCities[1].airportCode,
        destinationObj: popularCities[1],
        origin: popularCities[0].airportCode,
        originObj: popularCities[0],
      },
    ],
    partnerId: null,
    isNonStop: false,
  };

  const validationSchema = Yup.object().shape({
    sectors: Yup.array().of(
      Yup.object().shape({
        origin: Yup.string()
          .typeError("From Airport is required")
          .required("From Airport is required"),
        destination: Yup.string()
          .typeError("To Airport is required")
          .required("To Airport is required"),
      })
    ),
    adultPax: Yup.number().required("Number of Adult is required"),
    childPax: Yup.number(),
    infantPax: Yup.number(),
    preferredclass: Yup.string().required("Class is required"),
    preferredAirline: Yup.array().min(1, "Select at least 1 airline"),
  });

  const openQuicklink = (quicklinktype) => {
    switch (quicklinktype) {
      case "flights-report": {
        props.history.push("/admin/booking-report/flight");
        break;
      }

      case "travel-calendar": {
        window.openPage("travel-calendar");
        break;
      }

      case "cancellation": {
        props.history.push("/admin/booking-report/flight");
        break;
      }

      case "group-booking": {
        props.history.push("/admin/groupbooking");
        break;
      }

      case "airlines-contact": {
        // window.openPage("airlines-contact");
        props.history.push("/flight/airlinecontacts");
        break;
      }

      case "web-checkin": {
        // window.openPage("web-checkin");
        props.history.push("/flight/webcheckin");
        break;
      }
    }
  };

  const getAirlineData = () => {
    if (
      props.domCarriers === undefined ||
      props.intlCarriers === undefined ||
      props.airports === undefined
    ) {
      apiCall(WebApi.getAirports, {}, (response) => {
        if (response.success === true) {
          props.setAirports(response.data.airports);
          props.setDomesticCarriers(response.data.domCarriers);
          props.setInternationalCarriers(response.data.intlCarriers);
          setCarrier(response.data.domCarriers);
          setIsLoading(false);
        } else {
          setIsError(true);
        }
      });
    }
  };

  const fetchAirports = (searchParams) => {
    if (searchParams.length >= 3) {
      apiCall(
        WebApi.fetchAirportList,
        {
          airportCode: searchParams,
        },
        (response) => {
          // console.log("response", response);

          if (response.success == true) {
            setAirportList(response.data.airports);
          }
        }
      );
    }
  };

  function changeHandler(fromTo, countryCode) {
    if (fromTo === "from") {
      setFromCountry(countryCode ? countryCode : "IN");
    } else if (fromTo === "to") {
      setToCountry(countryCode ? countryCode : "IN");
    }
  }

  const renderOptionsComp = (optionsData, propes) => {
    // console.log("render options", optionsData);
    // console.log("render options props", propes);
    return (
      <li selected={propes.selected} className="ac-options-list">
        <span>
          <p className="ac-option-left">
            {optionsData.cityName} ({optionsData.airportCode})
          </p>
          <p className="ac-option-right">{optionsData.countryCode}</p>
        </span>
        <p>{optionsData.airportDesc}</p>
      </li>
    );
  };

  const getUserDetails = () => {
    localforageGetItem("user-details", function (err, details) {
      if (details) {
        setUserType(details.userType);
        setCorporateType(details.corporateType);
        setPartnerId(details.partnerId);

        switch (details.userTypeAbv) {
          case "S": {
            setClientType("Corporate");
            break;
          }
          case "G": {
            setClientType("Normal");
            break;
          }
          default: {
            setClientType("Normal");
            break;
          }
        }
      } else {
        console.log("localforage error", err);
      }
    });
  };

  const addMultiStopInp = (values, arrayHelpers) => {
    let index = values.sectors.length - 1;
    if (index < 3) {
      arrayHelpers.push({
        departDate: dateFnsFormat(values.sectors[index].departDateDisplay, "yyyy-MM-dd"),
        departDateDisplay: values.sectors[index].departDateDisplay,
        destination: values.sectors[index].origin,
        destinationObj: values.sectors[index].originObj,
        origin: values.sectors[index].destination,
        originObj: values.sectors[index].destinationObj
      });
    }
  };

  const submitFlightSearch = (values) => {
    // console.log("submit values", values);
    if (partnerId && clientType) {
      window.sessionStorage.removeItem("service");
      window.sessionStorage.setItem("fetching", true);
      values.partnerId = partnerId;
      values.clientType = clientType;
      window.sessionStorage.removeItem("service");
      window.sessionStorage.setItem("fetching", true);
      history.push({
        pathname: "/flight/search-progress",
        state: values,
      });
    } else {
      new Promise((resolve) => {
        getUserDetails();
        resolve(partnerId);
      }).then((val) => {
        if (val) {
          formikRef.handleSubmit();
        }
      });
    }
  };

  useEffect(() => {
    getAirlineData();
  }, [isLoading]);

  useEffect(() => {
    if (!isLoading) {
      if (fromCountry === "IN" && toCountry === "IN") {
        setCarrier(props.domCarriers);
      } else {
        setCarrier(props.intlCarriers);
      }
    }
  }, [fromCountry, toCountry]);

  useEffect(() => {
    getUserDetails();
  }, []);

  return (
    <Box className={classes.root} m={4}>
      {/* oaFormAlert  */}
      {/* -----------  */}

      <OaFormAlerts
        isSaving={isSaving}
        isSuccess={isSuccess}
        isError={isError}
        infoText={infoText}
        setIsError={setIsError}
        setIsSuccess={setIsSuccess}
      />
      <Formik
        innerRef={formikRef}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={submitFlightSearch}
      >
        {({ values, errors, touched, handleChange, setFieldValue }) => (
          <Form>
            {/* -----------  */}
            {/* oaFormAlert  */}
            <Grid
              container
              direction="column"
              justifyContent="flex-start"
              alignItems="stretch"
            >
              <Grid
                container
                justifyContent="space-between"
                className={classes.rowUpper}
              >
                <Grid item>
                  <Box className={classes.FlightSection_flightway}>
                    <RadioGroup
                      className={classes.FlightSection_radiogroup}
                      value={values.mode}
                      name={`mode`}
                      onChange={(e) => {
                        setFieldValue(`mode`, e.target.value);
                        if (values.sectors.length > 1) {
                          values.sectors.length = 1;
                        }
                      }}
                    >
                      {flightWayList.map((a) => (
                        <FormControlLabel
                          key={a.val}
                          value={a.val}
                          control={<Radio color="secondary" />}
                          label={a.text}
                        />
                      ))}
                    </RadioGroup>
                    <ErrorMessage
                      name="mode"
                      component="div"
                      className="error"
                    />
                  </Box>
                </Grid>

                <Grid item></Grid>
              </Grid>
              <FieldArray
                name="sectors"
                render={(arrayHelpers) => (
                  <>
                    {values.sectors &&
                      values.sectors.map((sector, index) => {
                        return (
                          <Grid
                            className={classes.flightInfoGridRoot}
                            key={index}
                          >
                            <Box className={classes.FlightSection_flightdet}>
                              <Box my={2}>
                                <Grid
                                  container
                                  direction="row"
                                  justifyContent="space-between"
                                  alignItems="flex-start"
                                  spacing={2}
                                >
                                  <Grid
                                    container
                                    justifyContent="space-evenly"
                                    className="grid-upper-container"
                                  >
                                    <Grid item sm={6} className="itin-airports">
                                      <Grid
                                        container
                                        justifyContent="space-evenly"
                                      >
                                        <Grid item xs={5} sm={5}>
                                          <label>From</label>
                                          <Autocomplete
                                            fullWidth
                                            PopperComponent={customPopperFrom}
                                            name={`sectors[${index}].originObj`}
                                            options={airportList || []}
                                            getOptionLabel={(o) =>
                                              o
                                                ? `${o.cityName}, ${o.countryCode} (${o.airportCode})`
                                                : ""
                                            }
                                            value={
                                              values.sectors[index].originObj ||
                                              ""
                                            }
                                            getOptionSelected={(
                                              option,
                                              value
                                            ) =>
                                              option.airportCode ==
                                              value.airportCode
                                            }
                                            onKeyUp={(event) =>
                                              fetchAirports(event.target.value)
                                            }
                                            onChange={(
                                              event,
                                              newValue,
                                              reason
                                            ) => {
                                              // console.log(newValue);
                                              if (reason === "clear") {
                                                setAirportList(popularCities);
                                              } else {
                                                changeHandler(
                                                  "from",
                                                  newValue?.countryCode
                                                );
                                                setFieldValue(
                                                  `sectors[${index}].originObj`,
                                                  newValue
                                                );
                                                setFieldValue(
                                                  `sectors[${index}].origin`,
                                                  newValue.airportCode
                                                );
                                                arriveAutocompleteEl.current.focus();
                                              }
                                            }}
                                            ref={departAutocompleteEl}
                                            renderInput={(params) => (
                                              <TextField
                                                {...params}
                                                fullWidth
                                                color="secondary"
                                                inputProps={{
                                                  ...params.inputProps,
                                                  autocomplete: "new-password",
                                                }}
                                                placeholder="Departure Airport"
                                              />
                                            )}
                                            renderOption={(
                                              optionsData,
                                              propes
                                            ) =>
                                              renderOptionsComp(
                                                optionsData,
                                                propes
                                              )
                                            }
                                            disablePortal={true}
                                          />

                                          <ErrorMessage
                                            name={`sectors[${index}].origin`}
                                            component="div"
                                            className="error"
                                          />
                                        </Grid>

                                        <Grid
                                          item
                                          xs={2}
                                          style={{
                                            textAlign: "center",
                                            position: "relative",
                                          }}
                                        >
                                          <div className="vertical-dash-wrapper">
                                            <div className="vertical-dash"></div>
                                          </div>
                                          <IconButton
                                            onClick={() => {
                                              const originTemp = {
                                                str:
                                                  values.sectors[index].origin,
                                                obj:
                                                  values.sectors[index]
                                                    .originObj,
                                              };
                                              setFieldValue(
                                                `sectors[${index}].originObj`,
                                                values.sectors[index]
                                                  .destinationObj
                                              );
                                              setFieldValue(
                                                `sectors[${index}].origin`,
                                                values.sectors[index]
                                                  .destination
                                              );
                                              setFieldValue(
                                                `sectors[${index}].destinationObj`,
                                                originTemp.obj
                                              );
                                              setFieldValue(
                                                `sectors[${index}].destination`,
                                                originTemp.str
                                              );
                                            }}
                                            aria-label="delete"
                                            className={classes.swapIconButton}
                                          >
                                            <SwapHorizIcon />
                                          </IconButton>
                                        </Grid>

                                        <Grid item xs={5} sm={5}>
                                          <label>To</label>
                                          <Autocomplete
                                            name={`sectors[${index}].destinationObj`}
                                            PopperComponent={customPopperTo}
                                            options={airportList || []}
                                            getOptionLabel={(o) =>
                                              o
                                                ? `${o.cityName}, ${o.countryCode} (${o.airportCode})`
                                                : ""
                                            }
                                            value={
                                              values.sectors[index]
                                                .destinationObj || ""
                                            }
                                            openOnFocus
                                            getOptionSelected={(
                                              option,
                                              value
                                            ) => {
                                              return (
                                                option.airportCode ==
                                                value.airportCode
                                              );
                                            }}
                                            onKeyUp={(event) =>
                                              fetchAirports(event.target.value)
                                            }
                                            onChange={(
                                              event,
                                              newValue,
                                              reason
                                            ) => {
                                              // console.log(newValue);
                                              if (reason === "clear") {
                                                setAirportList(popularCities);
                                              } else {
                                                changeHandler(
                                                  "to",
                                                  newValue?.countryCode
                                                );
                                                arriveAutocompleteEl.current.blur();
                                                setFieldValue(
                                                  `sectors[${index}].destinationObj`,
                                                  newValue
                                                );
                                                setFieldValue(
                                                  `sectors[${index}].destination`,
                                                  newValue.airportCode
                                                );
                                              }
                                            }}
                                            renderInput={(params) => (
                                              <TextField
                                                {...params}
                                                fullWidth
                                                color="secondary"
                                                inputProps={{
                                                  ...params.inputProps,
                                                  autocomplete: "new-password",
                                                }}
                                                inputRef={arriveAutocompleteEl}
                                                placeholder="Arrival Airport"
                                              />
                                            )}
                                            renderOption={(
                                              optionsData,
                                              propes
                                            ) =>
                                              renderOptionsComp(
                                                optionsData,
                                                propes
                                              )
                                            }
                                            disablePortal={true}
                                          />

                                          <ErrorMessage
                                            name={`sectors[${index}].destination`}
                                            component="div"
                                            className="error"
                                          />
                                        </Grid>
                                      </Grid>
                                    </Grid>

                                    <Grid item sm={6} className="itin-dates">
                                      <Grid
                                        container
                                        justifyContent="space-evenly"
                                      >
                                        <Grid
                                          item
                                          xs={5}
                                          sm={5}
                                          className="left-dashed-item"
                                        >
                                          <label>Depart</label>
                                          {/* <MuiPickersUtilsProvider
                                            utils={DateFnsUtils}
                                          >
                                            <KeyboardDatePicker
                                              KeyboardButtonProps={{
                                                onFocus: (e) => {
                                                  setDepartDateDialogOpen(true);
                                                },
                                              }}
                                              PopoverProps={{
                                                disableRestoreFocus: true,
                                                onClose: () => {
                                                  setDepartDateDialogOpen(false);
                                                },
                                              }}
                                              InputProps={{
                                                onFocus: () => {
                                                  setDepartDateDialogOpen(true);
                                                },
                                              }}
                                              name={`sectors[${index}].departDateDisplay`}
                                              open={departDateDialogOpen}
                                              onClose={() =>
                                                setDepartDateDialogOpen(false)
                                              }
                                              onOpen={() =>
                                                setDepartDateDialogOpen(true)
                                              }
                                              disablePast
                                              id="departDateEl"
                                              className={classes.departDatePicker}
                                              fullWidth
                                              color="secondary"
                                              disableToolbar
                                              variant="inline"
                                              format="dd-MM-yyyy"
                                              margin="normal"
                                              // label="Depart"
                                              // value={data.depart}
                                              value={
                                                values.sectors[index]
                                                  .departDateDisplay
                                              }
                                              onChange={(e, v) => {
                                                setDepartDateDialogOpen(false);
                                                setFieldValue(
                                                  `sectors[${index}].departDateDisplay`,
                                                  e
                                                );
                                                setFieldValue(
                                                  `sectors[${index}].departDate`,
                                                  dateFnsFormat(e, "yyyy-MM-dd")
                                                );
                                                setFieldValue(`returnDateDisplay`, e);
                                                setFieldValue(`returnDate`, dateFnsFormat(e, "yyyy-MM-dd"));
                                              }}
                                            />
                                          </MuiPickersUtilsProvider> */}

                                          <CustomDatePicker
                                            showDisabledMonthNavigation
                                            className="date-picker"
                                            name={`sectors[${index}].departDateDisplay`}
                                            minDate={new Date()}
                                            //minDate={values.sectors[index].departDateDisplay}
                                            // minDate={props?.indx == 0 ? new Date():values.sectors[props?.indx - 1].departDateDisplay}
                                            customInput={
                                              <TextField
                                                id="standard-basic"
                                                variant="standard"
                                                fullWidth={isMobileScreen}
                                              />
                                            }
                                            selected={
                                              values.sectors[index]
                                                .departDateDisplay
                                            }
                                            onChange={(e, v) => {
                                              if (values.mode === "MULTICITY") {
                                                for (
                                                  let i = index;
                                                  i < values.sectors.length;
                                                  i++
                                                ) {
                                                  const t1 = new Date(
                                                    e
                                                  ).getTime();
                                                  const t2 = new Date(
                                                    values.sectors[
                                                      i
                                                    ].departDateDisplay
                                                  ).getTime();
                                                  if (t2 < t1) {
                                                    setFieldValue(
                                                      `sectors[${i}].departDateDisplay`,
                                                      e
                                                    );
                                                    setFieldValue(
                                                      `sectors[${i}].departDate`,
                                                      dateFnsFormat(
                                                        e,
                                                        "yyyy-MM-dd"
                                                      )
                                                    );
                                                  }
                                                }
                                              } else {
                                                setFieldValue(
                                                  `sectors[${index}].departDateDisplay`,
                                                  e
                                                );
                                                setFieldValue(
                                                  `sectors[${index}].departDate`,
                                                  dateFnsFormat(e, "yyyy-MM-dd")
                                                );
                                                if (Math.abs(values.returnDateDisplay) < Math.abs(e)) {
                                                  setFieldValue(
                                                    `returnDateDisplay`,
                                                    e
                                                  );
                                                  setFieldValue(
                                                    `returnDate`,
                                                    dateFnsFormat(e, "yyyy-MM-dd")
                                                  );
                                                }
                                              }
                                            }}
                                            monthsShown={isMobileScreen ? 1 : 2}
                                            dateFormat="dd MMM yyyy"
                                            CustomDatePicker
                                          />
                                          <ErrorMessage
                                            name={`sectors[${index}].departDate`}
                                            component="div"
                                            className="error"
                                          />
                                        </Grid>

                                        <Grid
                                          item
                                          xs={5}
                                          sm={5}
                                          className="left-dashed-item"
                                        >
                                          {(values.mode === "ROUND" ||
                                            values.mode === "ROUNDSP") && (
                                              <Grid item>
                                                <label>Return</label>
                                                {/* <MuiPickersUtilsProvider
                                                  utils={DateFnsUtils}
                                                >
                                                  <KeyboardDatePicker
                                                    name={`returnDateDisplay`}
                                                    open={returnDateDialogOpen}
                                                    onClose={() =>
                                                      setReturnDateDialogOpen(false)
                                                    }
                                                    onOpen={() =>
                                                      setReturnDateDialogOpen(true)
                                                    }
                                                    minDate={
                                                      values.returnDateDisplay
                                                    }
                                                    className={classes.returnDatePicker}
                                                    fullWidth
                                                    color="secondary"
                                                    disableToolbar
                                                    variant="inline"
                                                    format="dd-MM-yyyy"
                                                    margin="normal"
                                                    // label="Return"
                                                    // value={data.return}
                                                    value={values.returnDateDisplay}
                                                    onChange={(e, v) => {
                                                      setFieldValue(`returnDateDisplay`,e);
                                                      setFieldValue(`returnDate`, dateFnsFormat(e, "yyyy-MM-dd"));
                                                      setReturnDateDialogOpen(false);
                                                    }}
                                                  />
                                                </MuiPickersUtilsProvider> */}
                                                <CustomDatePicker
                                                  showDisabledMonthNavigation
                                                  className="date-picker"
                                                  name={`returnDateDisplay`}
                                                  minDate={
                                                    values.sectors[index]
                                                      .departDateDisplay
                                                  }
                                                  customInput={
                                                    <TextField
                                                      id="standard-basic"
                                                      variant="standard"
                                                      fullWidth={isMobileScreen}
                                                    />
                                                  }
                                                  selected={
                                                    values.returnDateDisplay
                                                  }
                                                  onChange={(e, v) => {
                                                    setFieldValue(
                                                      `returnDateDisplay`,
                                                      e
                                                    );
                                                    setFieldValue(
                                                      `returnDate`,
                                                      dateFnsFormat(
                                                        e,
                                                        "yyyy-MM-dd"
                                                      )
                                                    );
                                                  }}
                                                  monthsShown={
                                                    isMobileScreen ? 1 : 2
                                                  }
                                                  dateFormat="dd MMM yyyy"
                                                />
                                                <ErrorMessage
                                                  name={`sectors[${index}].returnDate`}
                                                  component="div"
                                                  className="error"
                                                />
                                              </Grid>
                                            )}
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Box>
                            </Box>
                          </Grid>
                        );
                      })}

                    {values.mode === "MULTICITY" && (
                      <Grid container className="multicity-action-container">
                        <Grid item xs={6} lg={3}>
                          <Button
                            style={{
                              backgroundColor: theme.palette.primary.main,
                              color: theme.palette.primary.lightText,
                              width: 100,
                              marginTop: 10,
                              marginRight: 10,
                              fontSize: "8px",
                              border: `solid 1px ${theme.palette.primary.main}`,
                            }}
                            position="right"
                            onClick={() =>
                              addMultiStopInp(values, arrayHelpers)
                            }
                          >
                            + Add Sector
                          </Button>

                          {values.mode === "MULTICITY" &&
                            values.sectors.length > 1 && (
                              <Button
                                style={{
                                  border: `1px solid ${theme.palette.primary.main}`,
                                  color: theme.palette.primary.main,
                                  width: 100,
                                  marginTop: 10,
                                  fontSize: "8px",
                                }}
                                position="right"
                                onClick={() => {
                                  arrayHelpers.pop();
                                }}
                              >
                                - Remove Sector
                              </Button>
                            )}
                        </Grid>
                      </Grid>
                    )}
                  </>
                )}
              />

              <Grid container className={classes.rowLower}>
                <Grid container className="row-lower-grid-container-inner">
                  <Grid item sm={8} className={classes.FlightSection_passdet}>
                    <Grid
                      className={classes.gridRespColPassDet}
                      container
                      direction={props.width === "xs" ? "column" : "row"}
                      justifyContent="space-evenly"
                      alignItems="center"
                      spacing={2}
                    >
                      <Box className="counter-box">
                        <PassengerCount
                          title="Adults (12+ years)"
                          type="adult"
                          val={values.adultPax}
                          setVal={modifyPassengerCount}
                          name="adultPax"
                          totalPax={
                            values.adultPax + values.childPax + values.infantPax
                          }
                        />
                        <ErrorMessage
                          name="adultPax"
                          component="div"
                          className="error"
                        />
                      </Box>
                      <Box className="counter-box">
                        <PassengerCount
                          title="Children (2 - 12 years)"
                          type="child"
                          val={values.childPax}
                          setVal={modifyPassengerCount}
                          name="childPax"
                          totalPax={
                            values.adultPax + values.childPax + values.infantPax
                          }
                        />
                        <ErrorMessage
                          name="childPax"
                          component="div"
                          className="error"
                        />
                      </Box>
                      <Box className="counter-box">
                        <PassengerCount
                          title="Infants (0 - 2 years)"
                          type="infant"
                          val={values.infantPax}
                          setVal={modifyPassengerCount}
                          name="infantPax"
                          totalPax={
                            values.adultPax + values.childPax + values.infantPax
                          }
                        />
                        <ErrorMessage
                          name="infantPax"
                          component="div"
                          className="error"
                        />
                      </Box>
                    </Grid>
                  </Grid>

                  <Grid item sm={4} className="airline-select-container">
                    <Box>
                      <FormControl
                        color="secondary"
                        className={classes.airlineSelect}
                        error={values.preferredAirline.length === 0}
                      >
                        <InputLabel>Airlines</InputLabel>
                        <Select
                          multiple
                          fullWidth
                          name="preferredAirline"
                          open={airlineSelectOpen}
                          // value={data.airlines}
                          value={values.preferredAirline}
                          onChange={(e) => {
                            // setData({ ...data, airlines: e.target.value });
                            // setDataError({ ...dataError, airlines: false });
                            // console.log(e.target.value);
                            setFieldValue(`preferredAirline`, e.target.value);
                            // setAirlineSelectOpen(false);
                          }}
                          renderValue={(selected) => {
                            let initialValue = "";

                            if (
                              selected.includes(allAirlines)
                            ) {
                              initialValue = initialValue + ", " + "All";
                            }
                            if (selected.includes(lccAirlines)) {
                              initialValue = initialValue + ", " + "LCC Only";
                            }
                            if (selected.includes(gdsAirlines)) {
                              initialValue = initialValue + ", " + "GDS Only";
                            }
                            initialValue =
                              carrier &&
                              carrier.reduce(
                                (previousValue, currentValue) =>
                                  selected.includes(currentValue.name)
                                    ? previousValue +
                                    ", " +
                                    currentValue.description
                                    : previousValue,
                                initialValue
                              );

                            return (
                              initialValue &&
                              initialValue.substring(1, initialValue.length - 1)
                            );
                          }}
                          onOpen={() => setAirlineSelectOpen(true)}
                          onClose={() => {
                            // setDataError({
                            //   ...dataError,
                            //   airlines: true,
                            // });

                            setAirlineSelectOpen(false);
                          }}
                        >
                          <MenuItem
                            key={allAirlines}
                            value={allAirlines}
                          >
                            <ListItemText primary={"All"} />
                            <Checkbox
                              checked={
                                values.preferredAirline.indexOf(
                                  allAirlines
                                ) > -1
                              }
                            />
                          </MenuItem>

                          <MenuItem
                            key={lccAirlines}
                            value={lccAirlines}
                          >
                            <ListItemText primary={"LCC Only"} />
                            <Checkbox
                              checked={
                                values.preferredAirline.indexOf(
                                  lccAirlines
                                ) > -1
                              }
                            />
                          </MenuItem>
                          <MenuItem key={gdsAirlines} value={gdsAirlines}>
                            <ListItemText primary={"GDS Only"} />
                            <Checkbox
                              checked={
                                values.preferredAirline.indexOf(gdsAirlines) > -1
                              }
                            />
                          </MenuItem>

                          {carrier &&
                            carrier.map((a) => (
                              <MenuItem key={a.name} value={a.name}>
                                <ListItemText primary={a.description} />
                                <Checkbox
                                  checked={
                                    values.preferredAirline.indexOf(a.name) > -1
                                  }
                                />
                              </MenuItem>
                            ))}
                        </Select>
                        {values.preferredAirline.length === 0 && (
                          <FormHelperText>
                            Please choose an airline
                          </FormHelperText>
                        )}
                      </FormControl>
                      <ErrorMessage
                        name="airline"
                        component="div"
                        className="error"
                      />
                    </Box>
                  </Grid>
                </Grid>

                <Grid container className="row-lower-grid-container-inner">
                  <Grid item sm={9} className={classes.FlightSection_actions}>
                    <Grid
                      className={classes.gridRespCol}
                      container
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      spacing={2}
                    >
                      <Grid item>
                        <Box className={classes.toggleBox}>
                          <ToggleButtonGroup
                            name="isNonStop"
                            size="small"
                            // value={data.nonStop}
                            value={values.isNonStop}
                            exclusive
                            // onChange={(e, v) => v && setData({ ...data, nonStop: v })}
                            onChange={(e, v) => setFieldValue("isNonStop", v)}
                          >
                            <ToggleButton value={false}>All</ToggleButton>
                            <ToggleButton value={true}>Non-Stop</ToggleButton>
                          </ToggleButtonGroup>
                          <ErrorMessage
                            name="isNonStop"
                            component="div"
                            className="error"
                          />
                        </Box>
                      </Grid>

                      <Grid item>
                        <Box className={classes.toggleBox}>
                          <ToggleButtonGroup
                            name="preferredclass"
                            size="small"
                            // value={data.class}
                            value={values.preferredclass}
                            exclusive
                            // onChange={(e, v) => v && setData({ ...data, class: v })}
                            onChange={(e, v) =>
                              v && setFieldValue("preferredclass", v)
                            }
                          >
                            <ToggleButton value="E">Economy</ToggleButton>
                            <ToggleButton value="ER">
                              Premium Economy
                            </ToggleButton>
                            <ToggleButton value="B">Business</ToggleButton>
                          </ToggleButtonGroup>
                          <ErrorMessage
                            name="preferredclass"
                            component="div"
                            className="error"
                          />
                        </Box>
                      </Grid>

                      <Grid item>
                        <Box>
                          <div
                            className={classes.fareTypeBox}
                            onClick={() => setShowFareType(!showFareType)}
                          >
                            <span>{values.fareType} Fare Type</span>
                            {"   "}
                            <i className="fas fa-chevron-down"></i>
                          </div>
                          {showFareType && (
                            <div className={classes.fareTypeContent}>
                              <FormControl component="fieldset">
                                <RadioGroup
                                  aria-label="method"
                                  name="method"
                                  value={values.fareType}
                                  onChange={
                                    (event) => {
                                      setFieldValue("fareType", event.target.value);
                                      setShowFareType(!showFareType);
                                    }

                                    // props.setselectedpaymentmethod(event.target.value)

                                    // console.log("first")
                                  }
                                >
                                  <FormControlLabel
                                    value="Regular"
                                    control={<Radio />}
                                    label={`Regular Fare`}
                                  />
                                  <FormControlLabel
                                    value="Student"
                                    control={<Radio />}
                                    label={`Student Fare`}
                                  />
                                  <FormControlLabel
                                    value="SeniorCitizen"
                                    control={<Radio />}
                                    label={`SeniorCitizen Fare`}
                                  />
                                </RadioGroup>
                              </FormControl>
                            </div>
                          )}
                        </Box>

                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item sm={3} className={classes.FlightSection_searchBtn}>
                    <Grid
                      className={classes.gridRespCol}
                      container
                      direction="row"
                      justifyContent="flex-end"
                      alignItems="center"
                      spacing={2}
                    >
                      <Grid item className="search-btn-col">
                        <Box className={classes.searchButtonBox}>
                          <Button
                            size="large"
                            variant="contained"
                            color="primary"
                            // onClick={submitForm}
                            type="submit"
                          >
                            Search
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              {corporateType && corporateType != "corporate" && (
                <Grid container className={classes.rowBottom}>
                  <Grid container className="quick-links-container">
                    {userType && userType == "agent" && (
                      <Grid
                        item
                        className="quick-link-item"
                        onClick={() => openQuicklink("flights-report")}
                      >
                        <div className="qlink-icon-wrapper">
                          <i className="fas fa-plane"></i>
                        </div>
                        <div className="qlink-label-wrapper">
                          FLIGHTS REPORT
                        </div>
                      </Grid>
                    )}

                    {userType && userType == "agent" && (
                      <Grid
                        item
                        className="quick-link-item"
                        onClick={() => openQuicklink("travel-calendar")}
                      >
                        <div className="qlink-icon-wrapper">
                          <i className="fas fa-calendar"></i>
                        </div>
                        <div className="qlink-label-wrapper">
                          TRAVEL CALENDER
                        </div>
                      </Grid>
                    )}

                    {userType && userType == "agent" && (
                      <Grid
                        item
                        className="quick-link-item"
                        onClick={() => openQuicklink("cancellation")}
                      >
                        <div className="qlink-icon-wrapper">
                          <i className="fas fa-ban"></i>
                        </div>
                        <div className="qlink-label-wrapper">CANCELLATION</div>
                      </Grid>
                    )}

                    <Grid
                      item
                      className="quick-link-item"
                      onClick={() => openQuicklink("web-checkin")}
                    >
                      <div className="qlink-icon-wrapper">
                        <i className="fas fa-globe"></i>
                      </div>
                      <div className="qlink-label-wrapper">WEB CHECK IN</div>
                    </Grid>

                    <Grid
                      item
                      className="quick-link-item"
                      onClick={() => openQuicklink("airlines-contact")}
                    >
                      <div className="qlink-icon-wrapper">
                        <i className="fas fa-phone"></i>
                      </div>
                      <div className="qlink-label-wrapper">
                        AIRLINES CONTACT
                      </div>
                    </Grid>

                    <Grid
                      item
                      className="quick-link-item"
                      onClick={() => openQuicklink("group-booking")}
                    >
                      <div className="qlink-icon-wrapper">
                        <i className="fas fa-users"></i>
                      </div>
                      <div className="qlink-label-wrapper">GROUP BOOKING</div>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>

            {/* {errors && _.isString(errors) && touched && _.isArray(touched) && (
              <div className="field-error">{errors}</div>
            )}
            <div
              className={"row"}
              style={{ background: "white", zIndex: "200" }}
            >
              <div className={"col-12"}>
                <code>
                  <pre>Values: {JSON.stringify(values, null, 2)}</pre>
                </code>
              </div>
              <div className={"col-12"}>
                <pre>Errors: {JSON.stringify(errors, null, 2)}</pre>
              </div>
              <div className={"col-12"}>
                <pre>Touched: {JSON.stringify(touched, null, 2)}</pre>
              </div>
            </div> */}
          </Form>
        )}
      </Formik>
    </Box>
  );
};

const mapPropsToState = (state, props) => {
  return {
    domCarriers: state.flight.domCarriers,
    intlCarriers: state.flight.intlCarriers,
    airports: state.flight.airports,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setDomesticCarriers: (domCarriers) =>
      dispatch(setDomesticCarriers(domCarriers)),
    setInternationalCarriers: (intlCarriers) =>
      dispatch(setInternationalCarriers(intlCarriers)),
    setAirports: (airports) => dispatch(setAirports(airports)),
  };
};

export default withRouter(
  connect(
    mapPropsToState,
    mapDispatchToProps
  )(FlightSection)
);

const flightWayList = [
  { val: "ONE", text: "One Way" },
  { val: "ROUND", text: "Round Trip" },
  { val: "ROUNDSP", text: "Round Trip Special" },
  { val: "MULTICITY", text: "Multi City" },
];

const popularCities = [
  {
    airportId: 5669,
    cityName: "Mumbai",
    airportCode: "BOM",
    countryCode: "IN",
    citySearch: "MUMBAI,BOMBAY",
    airportDesc: "Chhatrapati Shivaji International Airport",
  },
  {
    airportId: 5877,
    cityName: "New Delhi",
    airportCode: "DEL",
    countryCode: "IN",
    citySearch: "NEW DELHI,DELHI",
    airportDesc: "Indira Gandhi International Airport",
  },
  {
    airportId: 682,
    cityName: "Bangkok",
    airportCode: "BKK",
    countryCode: "TH",
    citySearch: "",
    airportDesc: "Bangkok",
  },
  {
    airportId: 679,
    cityName: "Bangalore",
    airportCode: "BLR",
    countryCode: "IN",
    citySearch: "BANGALORE,BANGLURU,BANGLORE,BENGALURU",
    airportDesc: "Bengaluru International Airport",
  },
  {
    airportId: 6926,
    cityName: "Pune",
    airportCode: "PNQ",
    countryCode: "IN",
    citySearch: "PUNE,PUNA",
    airportDesc: "Pune Airport",
  },
  {
    airportId: 3454,
    cityName: "Hyderabad",
    airportCode: "HYD",
    countryCode: "IN",
    citySearch: "HYDERABAD,HYDRABAD",
    airportDesc: "Rajiv Gandhi International Airport",
  },
  {
    airportId: 4229,
    cityName: "Kolkata",
    airportCode: "CCU",
    countryCode: "IN",
    citySearch: "KOLKATA,CALCUTTA,CALCUTA",
    airportDesc: "Netaji Subhash Chandra Bose International Airport",
  },
  {
    airportId: 1585,
    cityName: "Chennai",
    airportCode: "MAA",
    countryCode: "IN",
    citySearch: "CHENNAI,MADRAS",
    airportDesc: "Chennai International Airport",
  },
  {
    airportId: 2935,
    cityName: "Goa",
    airportCode: "GOI",
    countryCode: "IN",
    citySearch: "GOA",
    airportDesc: "Dabolim Goa International Airport",
  },
  {
    airportId: 2257,
    cityName: "Dubai",
    airportCode: "DXB",
    countryCode: "AE",
    citySearch: "",
    airportDesc: "Dubai International",
  },
];

const days = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "0 5px",
    "& .MuiFormLabel-root": {
      color: theme.palette.primary.darkText,
    },
    "& .MuiInputBase-input": {
      color: theme.palette.primary.defaultText,
    },
    "& .MuiInput-underline:before, .MuiInput-underline:after, .MuiInput-underline:hover, .MuiInput-underline:focus": {
      borderBottom: "none",
      [theme.breakpoints.down(440)]: {
        borderBottom: `solid 1px ${theme.palette.primary.darkText} !important`,
      },
    },
    "& .multicity-action-container": {
      marginBottom: 15,
      marginLeft: 20,
      [theme.breakpoints.down(600)]: {
        padding: "10px 15px",
      },
    },
    "& .ac-options-list": {
      overflow: "hidden",
      width: "100%",
      "& p": {
        marginBottom: 0,
        color: theme.palette.primary.darkFadedText,
        whiteSpace: "nowrap",
      },
      "& span": {
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        alignItems: "center",
        "& .ac-option-left": {
          textAlign: "left",
          color: theme.palette.primary.darkText,
          fontWeight: 600,
          flexGrow: 1,
        },
        "& .ac-option-right": {
          textAlign: "right",
          color: theme.palette.primary.darkFadedText,
        },
      },
    },

    "& .oa-form-element-card": {
      padding: "11.3 0",
    },
  },

  FlightSection_flightway: {
    "& .MuiFormGroup-root": {
      flexDirection: "row",
      justifyContent: "space-between",
      "& .MuiFormControlLabel-label": {
        color: theme.palette.primary.darkText,
        fontWeight: "400",
        fontSize: 12,
      },
    },
  },
  FlightSection_flightdet: {
    "& .grid-upper-container": {
      [theme.breakpoints.down(440)]: {
        "& .MuiGrid-root.MuiGrid-item.MuiGrid-grid-sm-5": {
          maxWidth: "100%",
          flexBasis: "100%",
          paddingRight: 20,
        },
      },
    },
    "& .itin-airports": {
      paddingLeft: 25,
      "& label": {
        fontSize: 14,
        color: theme.palette.primary.darkText,
        fontWeight: 600,
      },
      "& .vertical-dash-wrapper": {
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        "& .vertical-dash": {
          // borderLeft: `solid 2px ${theme.palette.primary.darkText}`,
          height: 65,
          [theme.breakpoints.down(440)]: {
            display: "none",
          },
        },
      },
      [theme.breakpoints.down(680)]: {
        maxWidth: "100%",
        flexBasis: "100%",
      },
    },
    "& .itin-dates": {
      "& label": {
        fontSize: 14,
        color: theme.palette.primary.darkText,
        fontWeight: 600,
        display: "block"
      },
      "& .left-dashed-item": {
        // borderLeft: `solid 2px ${theme.palette.primary.darkText}`,
        paddingLeft: 10,
        [theme.breakpoints.down(680)]: {
          border: 0,
          maxWidth: "50%",
          flexBasis: "50%",
          paddingLeft: "4vw",
        },
        [theme.breakpoints.down(440)]: {
          paddingLeft: 25,
        },
      },
      "& .MuiIconButton-label": {
        color: theme.palette.secondary.main,
      },
      [theme.breakpoints.down(680)]: {
        maxWidth: "100%",
        flexBasis: "100%",
        marginTop: 30,
      },
    },
    "& .MuiAutocomplete-popupIndicator": {
      display: "none",
    },
    "& .MuiAutocomplete-popupIndicator .MuiIconButton-label": {
      color: theme.palette.primary.darkText,
    },
  },
  FlightSection_passdet: {
    paddingTop: 15,
    "& .MuiBox-root span": {
      color: `${theme.palette.primary.darkText} !important`,
      fontWeight: 600,
    },
    "& .MuiBox-root .MuiGrid-container": {
      backgroundColor: "none",
      borderBottom: `solid 0.5px ${theme.palette.primary.disabled}`,
      paddingBottom: 5,
      [theme.breakpoints.down(400)]: {
        borderBottom: "none",
      },
    },
    "& .MuiIconButton-root": {
      padding: 0,
    },
    [theme.breakpoints.down(600)]: {
      maxWidth: "100%",
      flexBasis: "100%",
      marginTop: 5,
    },
  },
  FlightSection_actions: {
    paddingTop: 37,
    [theme.breakpoints.down(600)]: {
      maxWidth: "100%",
      flexBasis: "100%",
    },
  },

  toggleBox: {
    "& .MuiToggleButton-root": {
      color: theme.palette.primary.defaultText,
      textTransform: "none",
    },
    "& .Mui-selected": {
      color: theme.palette.primary.lightText,
      backgroundColor: theme.palette.primary.main,

      "&:hover, &:focus": {
        backgroundColor: theme.palette.primary.main,
      },
    },
    "& .MuiToggleButtonGroup-root": {
      backgroundColor: theme.palette.background.light,
    },
    "& .MuiToggleButton-sizeSmall": {
      height: 24,
      minWidth: 75,
      [theme.breakpoints.down(840)]: {
        minWidth: 0,
      },
      [theme.breakpoints.down(614)]: {
        minWidth: 75,
      },
      [theme.breakpoints.down(358)]: {
        marginBottom: 20,
      },
    },
    "& .MuiToggleButtonGroup-root": {
      [theme.breakpoints.down(358)]: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        alignItems: "center",
      },
    },
  },
  searchButtonBox: {
    width: "80px",
    height: "30px",
    "& .MuiButton-root": {
      width: "100%",
      height: "100%",
      background: theme.palette.secondary.main,
      "& .MuiButton-label": {
        color: theme.palette.primary.darkText,
      },
    },
  },
  swapIconButton: {
    "& .MuiIconButton-label": {
      color: theme.palette.primary.lightText,
      background: theme.palette.secondary.main,
      borderRadius: 50,
      padding: 2,
    },
  },
  departDatePicker: {
    "& .MuiIconButton-label": {
      color: theme.palette.primary.defaultText,
    },
  },
  returnDatePicker: {
    "& .MuiIconButton-label": {
      color: theme.palette.primary.darkText,
    },
  },
  airlineSelect: {
    width: "100%",
    borderBottom: `solid 0.5px ${theme.palette.primary.disabled}`,
    marginTop: 4,
    "& .MuiSelect-icon": {
      color: theme.palette.primary.darkText,
    },
    "& .MuiFormLabel-root": {
      fontWeight: 500,
      fontSize: 20,
    },
    [theme.breakpoints.down(840)]: {
      marginLeft: 10,
    },
  },
  FlightSection_radiogroup: {
    display: "flex",
    justifyContent: "space-between !important",
    flexWrap: "nowrap",
    flexDirection: "row",
    "& .MuiRadio-colorSecondary.Mui-checked": {
      color: theme.palette.secondary.main,
    },
    [theme.breakpoints.down(440)]: {
      display: "grid",
      gridTemplateColumns: "repeat(2, 1fr)",
    },
    // [theme.breakpoints.down(600)]: {
    //   display: "grid",
    //   gridTemplateColumns: "repeat(1, 1fr)",
    // },
  },
  gridRespCol: {
    [theme.breakpoints.down(600)]: {
      flexDirection: "column",
      "& > div": {
        margin: "4px",
      },
    },
  },
  gridRespColPassDet: {
    [theme.breakpoints.down(400)]: {
      flexDirection: "column",
      "& > div": {
        margin: "4px",
      },
      "& .counter-box": {
        display: "flex",
        alignItems: "center",
      },
    },
  },
  rowUpper: {
    borderBottom: `solid 0.5px ${theme.palette.primary.disabled}`,
    padding: "0 25px",
  },
  rowLower: {
    borderTop: `solid 0.5px ${theme.palette.primary.disabled}`,
    padding: "5px 25px 15px",
    justifyContent: "space-between",
    "& .airline-select-container": {
      paddingTop: 6,
      [theme.breakpoints.down(600)]: {
        maxWidth: "100%",
        flexBasis: "100%",
        marginTop: 20,
      },
    },
    "& .row-lower-grid-container-inner": {
      margin: 0,
      // [theme.breakpoints.down(840)]: {
      //   justifyContent: "center",
      //   alignItems: "center",
      // },
    },
  },
  rowBottom: {
    borderTop: `solid 0.5px ${theme.palette.primary.disabled}`,
    padding: "5px 25px 15px",
    marginTop: 15,
    "& .quick-links-container": {
      padding: "10px 0",
      justifyContent: "flex-start",
      [theme.breakpoints.down(600)]: {
        flexWrap: "wrap",
        justifyContent: "center",
      },
      "& .quick-link-item": {
        margin: 5,
        border: `solid 1px ${theme.palette.primary.main}`,
        padding: "3px 10px",
        color: theme.palette.primary.main,
        borderRadius: 4,
        cursor: "pointer",
        textAlign: "center",
        width: 122,
        "&:hover": {
          border: `solid 1px ${theme.palette.secondary.main}`,
          backgroundColor: theme.palette.secondary.main,
        },
        "& .qlink-icon-wrapper": {
          textAlign: "center",
        },
        "& .qlink-label-wrapper": {
          fontSize: 10,
        },
      },
    },
  },
  FlightSection_searchBtn: {
    "& .search-btn-col": {
      paddingTop: 35,
    },
    [theme.breakpoints.down(600)]: {
      maxWidth: "100%",
      flexBasis: "100%",
    },
  },
  flightInfoGridRoot: {
    // height: "auto",
    [theme.breakpoints.down(960)]: {
      height: "auto",
    },
    // [theme.breakpoints.down(680)]: {
    //   height: 168,
    // },
    [theme.breakpoints.down(440)]: {
      height: "auto",
    },
  },

  fareTypeBox: {
    // height: 60,
    width: 150,
    padding: 5,
    border: "1px solid #eee",
    borderRadius: 8,
    marginLeft: 10,
    fontSize: 12,
    fontWeight: 700,
    backgroundColor: "rgba(255,255,255,0.5)",
    cursor: "pointer",
    height: 25,
    "& .fas": {
      marginLeft: 10,
    },
  },
  fareTypeContent: {
    backgroundColor: "#fff",
    padding: 10,
    paddingTop: 0,
    paddingBottom: 0,
    width: 140,
    marginLeft: 10,
    borderRadius: 5,
    boxShadow: "0 0 20px 0 rgb(0 0 0 / 45%)",
    "& .MuiIconButton-label": {
      display: "none",
    },
    "& .MuiTypography-root": {
      fontSize: 14,
      marginLeft: 10,
      fontWeight: 500,
      marginBottom: 5,
    },
    position: "absolute",
  },
}));

import React, { useState, useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import {
  getQueryVar,
  getCurrentTab,
  getAgencyId,
} from "oautils/oaCommonUtils.js";
import { makeStyles } from "@material-ui/core/styles";
import PreLoginNavBar from "components/Navbars/PreLoginNavBar.TriumphTravels";
import styles from "assets/jss/material-dashboard-pro-react/layouts/adminStyle.js";
import PageFooter from "components/Footer/PageFooter.TriumphTravels.js";
import Container from "@material-ui/core/Container";
import AgentRegistration from "pages/agent/AgentRegistration";
import AgentReview from "pages/agent/AgentReview";

import EnquiryForm from "pages/partner/EnquiryForm";
import PartnerRegistration from 'pages/partner/PartnerRegistration';

const useStyles = makeStyles(styles);

export default function PreLoginLayout(props) {
  const { ...rest } = props;
  const classes = useStyles();
  React.useEffect(() => {
    document.body.style.overflow = "unset";
    let qAgencyId = getAgencyId(props);
    setAgencyId(qAgencyId);
    return function cleanup() {};
  }, [agencyId, props]);

  const [agencyId, setAgencyId] = useState(undefined);

  let defaultTab = "hotel";
  let queryVar = getQueryVar({ ...props, q: "priTab" });
  let currentTab = getCurrentTab(queryVar, defaultTab);

  return (
    <div
      className={classes.wrapper}
      style={{ backgroundColor: "#fff", width: `100%`, height: `100%` }}
    >
      <PreLoginNavBar {...rest} />
      <Container fixed style={{ marginBottom: 20,  marginTop: 150}}>
        <div className={classes.container}>
          <Switch>
            <Route
              path="/prelogin/registration"
              component={AgentRegistration}
            />
               <Route
              path="/prelogin/review" component={AgentReview}/>

              <Route path="/prelogin/enquiry" component={EnquiryForm}/>
              <Route path="/prelogin/partner/register" exact component={PartnerRegistration}/>
          </Switch>
        </div>
      </Container>
      <PageFooter {...props}/>
    </div>
  );
}

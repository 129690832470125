import { makeStyles, useTheme, useMediaQuery } from "@material-ui/core";
import React from "react";
import { useHistory, withRouter } from "react-router-dom";
import { AppConstant } from "appConstant";
import companyLogo from "assets/triumphTravel/images/company_logo.png";
import FacebookIcon from "assets/triumphTravel/images/icons/facebook_icon.svg";
import GoogleplusIcon from "assets/triumphTravel/images/icons/google_plus.svg";
import MailIcon from "assets/triumphTravel/images/icons/mail_icon.svg";
import PhoneIcon from "assets/triumphTravel/images/icons/phone_icon.svg";
import TwitterIcon from "assets/triumphTravel/images/icons/twitter_icon.svg";
import MapPinIcon from "assets/triumphTravel/images/icons/map-pin.svg";
import AdtoiImg from "assets/triumphTravel/images/adtoi.png";
import IataImg from "assets/triumphTravel/images/iata.png";
import Carousel, { consts } from "react-elastic-carousel";
import left from "assets/triumphTravel/images/icons/arrowLeft.png";
import right from "assets/triumphTravel/images/icons/arrowRight.png";
import church from "assets/triumphTravel/images/church.png";
import sitting_people from "assets/triumphTravel/images/sitting_people.jpg";
import people from "assets/triumphTravel/images/people.png";
import adventure from "assets/triumphTravel/images/adventure.png";
import TaaiLogo from "assets/triumphTravel/images/logo/image_taai.jpg";
import TafiLogo from "assets/triumphTravel/images/logo/image_tafi.png";
import ReligareLogo from "assets/triumphTravel/images/logo/image_religare.jpg";
import AccordgroupLogo from "assets/triumphTravel/images/logo/image_accordgroup.png";
import OyoLogo from "assets/triumphTravel/images/logo/image_oyo.png";
import travlogy_logo from "assets/travlogy/images/travlogy_logo_white.png";

const CustomArrow = ({ type, onClick, isEdge }) => {
    if (type === consts.PREV) {
        return (
            <p onClick={onClick} disabled={isEdge} className="arrow-crouselPackage left">
                <img src={left} />
            </p>
        )
    } else { 
        return (
            <p onClick={onClick} disabled={isEdge} className="arrow-crouselPackage right">
                <img src={right} />
            </p>
        )
    }
}

const PageFooter = (props) => {

    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const history = useHistory();

    return (
        <div className={classes.root}>
            <div className="section-container">
                <div className="section-title">
                    <p>PHOTOS</p>
                </div>
                <div className="section-caption">
                    <p>Our most valuable assets,<br /> memories from our customer vacations</p>
                </div>
            </div>
            <div className="latest-photo-carousel">
                <Carousel
                    itemsToShow={isMobile ? 1 : 4}
                    itemPadding={[0, 0, 0, 0]}
                    pagination={false}
                    renderArrow={CustomArrow}
                >
                    {latestPhotosArray.map((value, index) => (
                        <div className="latest-photo-item" key={index}>
                            <img src={value.image} />
                        </div>
                    ))}
                </Carousel>
            </div>
            <div className="footer-container">
                <div className="footer-column first-col">
                    <h4>{AppConstant.name}</h4>
                    <div className="brand-wrapper">
                        <div className="brand-image-wrap">
                            <img src={companyLogo} />
                        </div>
                        <div className="brand-caption-wrap">
                            <p>Getting and securing the best deals from the service providers and extending the same to our customers.</p>
                        </div>
                    </div>
                    {/* <div className="social-wrapper">
                        <ul>
                            <li>
                                <a href="">
                                    <img src={FacebookIcon} />
                                </a>
                            </li>
                            <li>
                                <a href="">
                                    <img src={TwitterIcon} />
                                </a>
                            </li>
                            <li>
                                <a href="">
                                    <img src={GoogleplusIcon} />
                                </a>
                            </li>
                        </ul>
                    </div> */}
                </div>
                <div className="footer-column">
                    <h5>Useful links</h5>
                    <div className="footer-links">
                        <ul>
                            {/* <li>
                                <a href="">Blog</a>
                            </li> */}
                            <li>
                                <a onClick={() => history.push("/flights")}>Flights</a>
                            </li>
                            <li>
                                <a onClick={() => history.push("/hotels")}>Hotels</a>
                            </li>
                            <li>
                                <a onClick={() => history.push("/buses")}>Buses</a>
                            </li>
                        </ul>
                    </div>
                    <div className="memberships-wrapper">
                        <h5>Member of</h5>
                        <div className="membership-links">
                            <a href="">
                                <img src={IataImg} />
                            </a>
                            <a href="">
                                <img src={AdtoiImg} />
                            </a>
                            <a href="">
                                <img src={TaaiLogo} />
                            </a>
                            <a href="">
                                <img src={TafiLogo} />
                            </a>
                            <a href="">
                                <img src={ReligareLogo} />
                            </a>
                            <a href="">
                                <img src={AccordgroupLogo} />
                            </a>
                            <a href="">
                                <img src={OyoLogo} />
                            </a>
                        </div>
                    </div>
                </div>
                <div className="footer-column">
                    <h5>Privacy</h5>
                    <div className="footer-links">
                        <ul>
                            <li>
                                <a onClick={() => history.push("/b2c/aboutus")}>About Us</a>
                            </li>
                            <li>
                                <a onClick={() => history.push("/b2c/contactus")}>Contact Us</a>
                            </li>
                            <li>
                                <a onClick={() => history.push("/terms_and_conditions")}>Terms & Conditions</a>
                            </li>
                            <li>
                                <a onClick={() => history.push("/privacypolicy")}>Privacy Policy</a>
                            </li>
                            {/* <li>
                                <a onClick={() => history.push("/cancellation_and_refund")}>Cancellation & Refund Policy</a>
                            </li> */}
                            <li>
                                <a onClick={() => history.push("/pricing_policy")}>Pricing Policy</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="footer-column">
                    <h5>Contact Info</h5>
                    <div className="footer-links contact-links">
                        <ul>
                            <li>
                                <a href="">
                                    <img src={MailIcon} /> {AppConstant.infoEmail}
                                </a>
                            </li>
                            <li>
                                <a href="">
                                    <img src={PhoneIcon} /> +{AppConstant.countryDialCode} {AppConstant.supportContactView}
                                </a>
                            </li>
                            <li>
                                <a href="">
                                    <img src={PhoneIcon} /> +{AppConstant.countryDialCode} {AppConstant.infoContactView}
                                </a>
                            </li>
                            <li>
                                <a href="" className="address-link">
                                    <img src={MapPinIcon} /> {AppConstant.address.office}
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="copyright-column">
                        <p className="copyright-text">
                            Handcrafted by <a href="http://travlogy.co" target="_blank"><img src={travlogy_logo}/></a>
                        </p>
                    </div>
                </div>
            </div>
            {/* <div className="footer-container">
                <div className="copyright-column">
                    <p className="copyright-text">
                        Handcrafted by <a href="http://travlogy.co" target="_blank"><img src={travlogy_logo}/></a>
                    </p>
                </div>
            </div> */}
        </div>
    );
}

export default withRouter(PageFooter);

const latestPhotosArray = [
    { image: church },
    { image: people },
    { image: adventure },
    { image: sitting_people },
];

const useStyles = makeStyles(theme => ({

    root: {
        padding: 0,
        margin: 0,
        "& .section-container": {
            maxWidth: 1280,
            width: "100%",
            [theme.breakpoints.down(960)]: {
                textAlign: "center",
            },
        },
        "& .section-title": {
            padding: "0 10px",
            marginBottom: 30,
            "& p": {
                padding: "0 0 0 56px",
                fontSize: 16,
                lineHeight: 1,
                color: theme.palette.tertiary.main,
                fontWeight: 700,
            },
            [theme.breakpoints.down(960)]: {
                "& p": {
                    padding: "0 0 0 0",
                },
            },
        },
        "& .section-caption": {
            padding: "0 10px",
            marginBottom: 30,
            "& p": {
               // padding: "0 0 0 56px",
                textAlign: "center",
                fontSize: 36,
                lineHeight: 1,
                color: theme.palette.primary.defaultText,
                fontWeight: 700,
            },
        },
        "& .latest-photo-carousel": {
            position: "relative",
        },
        "& .arrow-crouselPackage": {
            display: "flex",
            alignItems: "center",
            "& img": {
                cursor: "pointer",
            },
            "&.left": {
                position: "absolute",
                left: 100,
                zIndex: 1,
                top: 140,
            },
            "&.right": {
                position: "absolute",
                right: 100,
                zIndex: 1,
                top: 140,
            },
            [theme.breakpoints.down(688)]: {
                "&.left": {
                    left: 10,
                },
                "&.right": {
                    right: 10,
                }
            },
        },
        "& .footer-container": {
            display: "-webkit-box",
            display: "-ms-flexbox",
            display: "flex",
            justifyContent: "space-evenly",
            width: "100%",
            backgroundColor: theme.palette.primary.main,
            "& .copyright-column": {
                "& p": {
                    color: theme.palette.primary.lightText,
                    fontSize: 10,
                    "& img": {
                        height: 15,
                        margin: "0 0 2px 5px",
                    },
                },
            },
            [theme.breakpoints.down(960)]: {
                flexWrap: "wrap",
                justifyContent: "center",
            },
        },
        "& .footer-column": {
            flexGrow: 1,
            textAlign: "left",
            padding: "3vw 3vw",
            "&.first-col": {
                flexGrow: 2,
                [theme.breakpoints.down(660)]: {
                    width: "100%",
                },
            },
            "& h4": {
                color: theme.palette.primary.lightText,
                fontWeight: 600,
            },
            "& h5": {
                color: theme.palette.primary.lightText,
                fontWeight: 500,
                textTransform: "none",
            },
            "& .brand-wrapper": {
                display: "-webkit-box",
                display: "-ms-flexbox",
                display: "flex",
                justifyContent: "flex-start",
                "& img": {
                    height: 60,
                    marginRight: 20,
                },
                "& p": {
                    color: theme.palette.primary.lightText,
                    width: 200,
                    fontWeight: 300,
                    fontSize: 12,
                },
            },
            "& .social-wrapper": {
                "& ul": {
                    listStyle: "none",
                    display: "-webkit-box",
                    display: "-ms-flexbox",
                    display: "flex",
                    justifyContent: "flex-start",
                    paddingInlineStart: 0,
                },
                "& li": {
                    margin: "0 10px 0 0",
                },
                "& a": {
                    color: theme.palette.primary.lightText,
                },
                "& img": {
                    height: 30,
                },
            },
            "& .footer-links": {
                "& ul": {
                    listStyle: "none",
                    paddingInlineStart: 0,
                },
                "& li": {
                    margin: "5px 10px 5px 0",
                },
                "& a": {
                    color: theme.palette.secondary.main,
                    fontSize: 12,
                    whiteSpace: "nowrap",
                    cursor: "pointer",
                },
            },
            "& .contact-links": {
                "& ul": {
                    listStyle: "none",
                    paddingInlineStart: 0,
                },
                "& li": {
                    margin: "5px 10px 5px 0",
                },
                "& a": {
                    color: `${theme.palette.primary.lightText} !important`,
                    fontSize: 12,
                    whiteSpace: "nowrap",
                    "&.address-link": { whiteSpace: "break-spaces" },
                    "& img": {
                        margin: "0 5px 0 0",
                    },
                },
            },
            "& .memberships-wrapper": {
                "& h5": {
                    fontWeight: 500,
                    color: theme.palette.primary.lightText,
                    textTransform: "none",
                },
                "& .membership-links": {
                    display: "-webkit-box",
                    display: "-ms-flexbox",
                    display: "flex",
                    flexWrap: "wrap",
                    maxWidth: 260,
                    "& a": {
                        margin: "0 5px 5px 0",
                        cursor: "pointer",
                    },
                    "& img": {
                        height: 30,
                    },
                },
            },
            [theme.breakpoints.down(960)]: {
                width: "50%",
            },
            [theme.breakpoints.down(660)]: {
                "&.first-col": {
                    width: "100%",
                },
            },
        },
    },
}));

import React from "react";
// @material-ui/core components
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useMediaQuery, Box, Grid } from "@material-ui/core";
import styles from "assets/jss/material-dashboard-pro-react/layouts/authStyle.js";
import HomePage from "components/LandingPage/TriumphTravel/HomePage";
import ScrollToTopComp from "components/LandingPage/TriumphTravel/ScrollToTopComp";

const useStyles = makeStyles(styles);

export default function Pages(props) {
  const { ...rest } = props;
  // ref for the wrapper div

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const wrapper = React.createRef();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [miniActive, setMiniActive] = React.useState(false);
  // styles
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <HomePage {...props}/>
      <ScrollToTopComp />
    </div>
  );
}

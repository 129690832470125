import React from "react";
import WebApi from "api/ApiConstants";
import ReactTable from "react-table";
import dateFnsFormat from 'date-fns/format';
import { makeStyles } from "@material-ui/core";

const ODTrnHistory = (props) => {
    const classes = useStyles();
    const [tabledata, setTabledata] = React.useState([]);
    const [rowCount, setRowCount] = React.useState(4);
    const [noDataText, setNoDataText] = React.useState(" ");
    const [loading, setLoading] = React.useState(true);
    const [finishedLoading, setFinishedLoading] = React.useState(false);
    
    const filterMethod = (filter, row, column) => {
        const id = filter.pivotId || filter.id;
        return row[id] !== undefined
            ? String(String(row[id]).toLowerCase()).startsWith(
                filter.value.toLowerCase()
            )
            : true;
    };
    
    const tableLoader = () => {
        if(props.odId && props.agentId) {
            WebApi.odTxnHistoryGet({
                odId: props.odId,
                userId: props.agentId
            }, response => {
                // console.log("table response", response)
                setTabledata(response.data);
                setRowCount(response.data.length === 0 ? 4 : response.data.length);
                setNoDataText(response.data.length === 0 ? "No results" : response.data.length);
            }, error => {
                setNoDataText("ERROR");
                console.log("OD Details Fetch Error", error);
            });
        }
        setLoading(false);
        setFinishedLoading(true);
    };

    const columns = [
        {
            Header: "#",
            accessor: "srNo",
            filterable: false
        },
        // {
        //     Header: "UID",
        //     accessor: "uid",
        //     filterable: false
        // },
        {
            Header: "Transaction ID",
            accessor: "tid",
            filterable: false,
            width: 200,
        },
        {
            Header: "Transaction Date",
            accessor: "transactionDate",
            filterable: false,
            width: 200,
            Cell: rowProp => {
                return (
                    isValidDate(rowProp.original.transactionDate) ?
                        <span>
                            {dateFnsFormat(new Date(rowProp.original.transactionDate), "dd-MMM-yyyy h:mm:ss a")}
                        </span>
                        : rowProp.original.transactionDate
                )
            }
        },
        {
            Header: "Type",
            accessor: "transactionType",
            filterable: false,
            width: 160
        },
        {
            Header: "Opening Balance",
            accessor: "openingBalance",
            filterable: false,
            Cell: rowProp => {
                return (
                    <span className="pricealignment"> 
                        {rowProp.original.openingBalance}
                    </span>
                        
                )
            }
        },
        {
            Header: "Debit Amount (₹)",
            accessor: "debit",
            filterable: false,
            Cell: rowProp => {
                return (
                    <span className="pricealignment"> 
                        {rowProp.original.debit}
                    </span>
                        
                )
            }
        },
        {
            Header: "Credit Amount (₹)",
            accessor: "credit",
            filterable: false,
            Cell: rowProp => {
                return (
                    <span className="pricealignment"> 
                        {rowProp.original.credit}
                    </span>
                        
                )
            }
        },
        {
            Header: "Closing Balance (₹)",
            accessor: "closingBalance",
            filterable: false,
            Cell: rowProp => {
                return (
                    <span className="pricealignment"> 
                        {rowProp.original.closingBalance}
                    </span>
                        
                )
            }
        }
    ];

    const isValidDate = (dateStr) => {
        return !isNaN(Date.parse(dateStr))
    };

    React.useEffect(() => {
        tableLoader();
    }, []);

    return(
        <div className={classes.root}>
            <ReactTable
                columns={columns}
                data={tabledata}
                defaultFilterMethod={filterMethod}
                filterable={true}
                showPagination={false}
                minRows={rowCount}
                loading={loading}
                pageSize={rowCount}
                noDataText={noDataText}
                className="-striped -highlight"
            />
        </div>
    );
};

export default ODTrnHistory;

const useStyles = makeStyles(theme => ({
    root: {
        "& .table-action-btn": {
            cursor: "pointer",
            background: theme.palette.buttons.primary,
            border: `solid 0.5px ${theme.palette.buttons.primary}`,
            "& .MuiButton-label": {
                color: theme.palette.buttons.primaryContrastText,
            },
            "&:hover": {
                background: theme.palette.buttons.primaryContrastText,
                "& .MuiButton-label": {
                    color: theme.palette.buttons.primary,
                },
            }
        },
        "& .pricealignment": {
            textAlign: "right",
            display: "flow",
        }
    }
   
}));